import React from 'react'
import { ProductListLargeFontType, BodySmallFontType } from '../../Typo'
import v from '../../../../helpers/vocabulary'
import { ReactComponent as Basket } from '../../../../assets/icons/basket.svg'
import Icon from '../../Icon'
import Flexbox from '../../../containers/Flexbox'
import { CSSTransition } from 'react-transition-group'

export default function AddToCart({ id, imageSrc, title, desc, addToBasket }) {
  const [addToBasketAnimation, setAddToBasketAnimation] = React.useState(false)
  const basketItem = {
    id,
    title: v.translate(title),
    desc,
    itemQuantity: 1,
    imageSrc,
  }

  const handleAddToBasket = () => {
    addToBasket(basketItem)
    setAddToBasketAnimation(true)
  }
  return (
    <Flexbox
      spaceBetween
      alignItemsCenter
      className="et-add-to-cart-wrapper"
      onClick={handleAddToBasket}
    >
      <div className="content-spacing">
        <ProductListLargeFontType noSpace className="title">
          {basketItem.title}
        </ProductListLargeFontType>
        <BodySmallFontType noSpace>{desc}</BodySmallFontType>
      </div>
      <CSSTransition
        in={addToBasketAnimation}
        onEntered={() => {
          setAddToBasketAnimation(false)
        }}
        timeout={300}
        classNames="stretch"
      >
        <Basket className="et-add-to-card-icon" />
      </CSSTransition>
    </Flexbox>
  )
}
