import React, { useState } from 'react'
import { BodyExtraBoldFontType } from './Typo'
import { ReactComponent as Arrow } from '../../assets/icons/arrow-move-right.svg'
import classNames from 'classnames'
import { HelpCategory } from '../../analytics/categories'
import ReactGA from 'react-ga'
import v from '../../helpers/vocabulary'

export default function ShowMore({ label, isOpened, children, ...props }) {
  const [opened, setOpened] = useState(isOpened)

  return (
    <div
      className={classNames('basic-layout et-show-more', opened && '--opened')}
      onClick={() => {
        if(!opened) {
          ReactGA.event({
            category: HelpCategory,
            action: 'Question opened',
            label: label,
          })
        }

        setOpened(!opened)}}
    >
      <div className="label-container">
        <BodyExtraBoldFontType>{v.translate(label)}</BodyExtraBoldFontType>
        <Arrow className={opened ? 'down' : 'up'} />
      </div>
      <div className={classNames('content', !opened && '--hide')}>
        <p className="--content" style={{color: 'black'}}>{children}</p>
      </div>
    </div>
  )
}
