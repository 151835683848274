import React from 'react'
import Slider from 'react-input-slider'

const thumbSize = 24

const styles = {
  track: {
    width: '100%',
    backgroundColor: '#e4e6e8',
    height: 8,
    borderRadius: 0
  },
  active: {
    backgroundColor: '#1ca2e1',
    borderRadius: '0 !important'
  },
  thumb: {
    width: thumbSize,
    height: thumbSize,
    backgroundColor: '#003b81',
    boxShadow: '0 2px 3px 0 rgba(79, 87, 99, 0.4)',
    cursor: 'pointer'
  },
  constainer: {
    height: thumbSize
  }
}

function InputSlider({ value, step, min, max, onChange, ...props }) {
  return (
    <div style={styles.container}>
      <Slider
        x={value}
        xstep={parseFloat(step)}
        xmin={parseFloat(min)}
        xmax={parseFloat(max)}
        onChange={({ x }) => onChange(x)}
        styles={styles}
        {...props}
      />
    </div>
  )
}

export default InputSlider
