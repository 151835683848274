import { currencyKey } from '../constants/localStorageKeys'
import v from '../helpers/vocabulary'

export const formatPercentage = value => `${value} %`
export const formatKg = value => `${value} kg`
export const formatCurrency = value =>
  `${localStorage.getItem(currencyKey)} ${round(value, 2).toFixed(2)}`
export const formatYears = value =>
  `${round(value, 2)} ${v.translate('calculator.unitTypes.time.years')}`
export const formatMinutes = value => 
  `${round(value, 2)} ${v.translate('calculator.unitTypes.time.minutes')}`
export const formatMonths = value =>
  `${round(value, 2)} ${v.translate('calculator.unitTypes.time.months')}`


function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}
