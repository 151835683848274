import React from 'react'
import Popup from './Popup'

const LOCALSTORAGE_KEY = 'installation-message-dismissed'

const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone

const hasDismissedInstallMessage = () => {
  return !!JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY))
}

const shouldShowInstallMessage = () =>
  isIOS() && !isInStandaloneMode() && !hasDismissedInstallMessage()

function AddToHomeScreen() {
  const show = shouldShowInstallMessage()
  const [dismissed, setDismissed] = React.useState(false)

  const dismiss = () => {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(true))
    setDismissed(true)
  }

  return show && !dismissed ? <Popup dismiss={dismiss} /> : null
}

export default AddToHomeScreen
