import React from 'react'
import Box from '../containers/Box'
import ImageGallery from 'react-image-gallery'
import Headline from './../UI/Headline'
import ComponentSpacing from './../containers/ComponentSpacing'
import RenderZoomInIcon from './../UI/ZoomInIcon'

export default function CutTypeImageGallery({ discImg, images, title }) {
  const galleryImages = new Array()

  images.forEach((image, index) => {
    galleryImages.push({
      original: image.lowres.src,
      fullscreen: image.highres.src,
      key: index,
    })
  })
  return (
    <Box className="et-cut-type-gallery-wrapper">
      <div className="et-cut_type-image-gallery">
        <ComponentSpacing pt="md" pl="sm" pb="sm" className="basic-layout">
          <Headline title={title} />
        </ComponentSpacing>
        <div className="basic-layout">
          <ImageGallery
            items={galleryImages}
            showBullets
            showNav={false}
            showPlayButton={false}
            showThumbnails={false}
            renderFullscreenButton={RenderZoomInIcon}
            showNav={true}
          />
        </div>
      </div>
      <img className="disc-img" src={discImg} />
    </Box>
  )
}
