import { apiService } from './api'
import { ordersApi } from '../constants/apiRoutes'
import { basketHelper } from '../helpers/basket'

export default {
  async send(buyerInfo) {
    let result = {
      data: null,
      errors: {
        basketItems: null,
        isOfflineError: false,
      },
    }
    const basketItems = basketHelper.getItems().map((item) => {
      return {
        article_nr: item.article_nr,
        title: item.title,
        name: item.name,
        detail: item.desc,
        quantity: item.itemQuantity,
      }
    })
    result.errors.basketItems = basketItems

    await apiService
      .post(ordersApi, this.mapOfferPOST(buyerInfo, basketItems))
      .then(function (response) {
        result.data = response
      })
      .catch(function (err) {
        if (!err.config || err.message === 'Network Error')
          err.isOfflineError = true
        result.errors = { ...result.errors, ...err }
      })
    return result
  },
  async sendPending(buyerInfo, basketItems) {
    let result = {
      data: null,
      errors: {
        isOfflineError: false,
      },
    }
    await apiService
      .post(ordersApi, this.mapOfferPOST(buyerInfo, basketItems))
      .then(function (response) {
        result.data = { buyerInfo, basketItems }
      })
      .catch(function (err) {
        if (!err.config || err.message === 'Network Error')
          err.isOfflineError = true
        result.errors = { ...result.errors, ...err }
      })
    return result
  },
  mapOfferPOST(buyerInfo, basketItems) {
    const bi = buyerInfo
    return {
      data: {
        type: 'orders',
        attributes: {
          customer_gender: bi.gender,
          customer_firstname: bi.firstname,
          customer_lastname: bi.lastname,
          customer_email: bi.email,
          customer_company: bi.company,
          order: basketItems,
          comment: bi.comment,
          accept_terms: bi.acceptTerms,
        },
      },
    }
  },
}
