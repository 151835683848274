import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

export default function Snackbar({
  message,
  action,
  onClose,
  duration,
  hidden,
  onActionClick,
}) {
  const [hide, setHide] = useState(true)

  useEffect(() => {
    if (duration) {
      setTimeout(() => {
        setHide(false)
        if (onClose) onClose()
      }, duration)
    }
  }, [])

  return (
    <div className="et-snackbar-container">
      <div
        className={classNames('et-snackbar', (!hide || hidden) && '--hidden')}
      >
        <p className="message">{message}</p>
        {action && (
          <p className="action" onClick={onActionClick}>
            {action}
          </p>
        )}
      </div>
    </div>
  )
}
