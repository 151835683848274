import React from 'react'
import Flexbox from '../containers/Flexbox'
import ComponentSpacing from '../containers/ComponentSpacing'
import { BodySmallFontType, ProductListSmallFontType } from '../UI/Typo'
import v from '../../helpers/vocabulary'
import Divider from '../UI/divider'
import Icon from '../UI/Icon'
import basketImg from '../../assets/images/basket/basket.png'
import { CSSTransition } from 'react-transition-group'
import Snackbar from './../UI/Snackbar'

export function BladeSearchListItem({ blade, onDetailsClick, onAddItem }) {
  const [addToBasketAnimation, setAddToBasketAnimation] = React.useState(false)
  const attr = blade.attributes
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleDetailClick = () => {
    onDetailsClick(blade.id)
  }

  const desc = `${attr.thickness} mm, ${blade.output} kg/h, ${attr.diameter} mm`
  const basketBlade = {
    title: v.translate(attr.title),
    desc,
    itemQuantity: 1,
    id: blade.id,
    imageSrc: attr.blade_image.src,
  }

  const handleAddToBasket = () => {
    onAddItem(basketBlade)
    setAddToBasketAnimation(true)
    setOpenSnackbar(true)
  }
  return (
    <>
      <Flexbox alignItemsCenter  className={'et-blade-list-item'}>
        <ComponentSpacing
          onClick={handleDetailClick}
          pr="xs"
          pt="md"
          pb="md"
          className="img-wrapper"
        >
          <img src={attr.blade_image.src} />
        </ComponentSpacing>
        <Flexbox column className="blade-list-item-data">
          <ProductListSmallFontType onClick={handleDetailClick} noSpace>
            {v.translate(attr.title)} {v.translate(attr.blade_code)}
          </ProductListSmallFontType>
          <ComponentSpacing onClick={handleDetailClick} mt="xxs">
            <BodySmallFontType noSpace>{desc}</BodySmallFontType>
          </ComponentSpacing>
        </Flexbox>
        <ComponentSpacing mr="xs">
          <CSSTransition
            in={addToBasketAnimation}
            onEntered={() => {
              setAddToBasketAnimation(false)
            }}
            timeout={300}
            classNames="stretch"
          >
            <Icon onClick={handleAddToBasket} className="blade-list-item-icon">
              <img src={basketImg} />
            </Icon>
          </CSSTransition>
        </ComponentSpacing>
      </Flexbox>
      <Divider className="et-blade-list-item-divider" />
      {openSnackbar && (
        <Snackbar
          duration={3000}
          message={v.translate('snackbar.productAdded', {
            productName: basketBlade.title,
          })}
          onClose={() => setOpenSnackbar(false)}
        />
      )}
    </>
  )
}
