import React from 'react'
import Flexbox from '../containers/Flexbox'
import ReactGA from 'react-ga'
import {
  ProductListLargeFontType,
  BodyFontType,
  TextLinkSmallFontType
} from '../UI/Typo'
import ComponentSpacing from '../containers/ComponentSpacing'
import { Link } from 'react-router-dom'
import v from '../../helpers/vocabulary'
import { MachineNavigationCategory } from '../../analytics/categories'

function MachineTile({ machine }) {

  const goToDetails = () => {
    if(event.target.hash != '#discs')
      ReactGA.event({
      category: MachineNavigationCategory,
      action: 'Main vs. Anchor',
      label: 'Product detail button',
      })
  }

  const goToDiscs = () => {
    if(event.target.hash == '#discs')
      ReactGA.event({
        category: MachineNavigationCategory,
        action: 'Main vs. Anchor',
        label: 'Product matching discs anchorlink',
      })
  }

  return (
    <Link to={'/machines/' + machine.id} className={'et-machine-tile-container'} onClick={goToDetails}>
      <Flexbox alignItemsCenter>
        <ComponentSpacing pt="md" pl="sm" pr="sm" pb="md">
          <img src={machine.attributes.image.src} />
        </ComponentSpacing>
        <ComponentSpacing
          className="machine-text-wrapper"
          pr="md"
          pt="md"
          pb="md"
        >
          <ProductListLargeFontType className="machine-title" pb="xxxs" noSpace>
            {v.translate(machine.attributes.title)}
          </ProductListLargeFontType>
          <ComponentSpacing pb="sm" pt="xs" className="machine-desc">
            <BodyFontType noSpace>
              {v.translate('products.door.performanceLabel', {
                performanceValue: machine.attributes.technical_specs.output
              })}
            </BodyFontType>
          </ComponentSpacing>

          <TextLinkSmallFontType noSpace>
          <Link to={'/machines/' + machine.id + '#discs'} onClick={goToDiscs}>
            {v.translate('products.door.fittingDics')}
            </Link>
            </TextLinkSmallFontType>
        </ComponentSpacing>
      </Flexbox>
    </Link>
  )
}

export default MachineTile
