import React from 'react'
import Flexbox from '../containers/Flexbox'
import ComponentSpacing from '../containers/ComponentSpacing'
import { ProductListLargeFontType } from '../UI/Typo'
import v from '../../helpers/vocabulary'

export function MachineBladesStartListItem({
  machine,
  onMachineClick,
  ...props
}) {
  const handleMachineClick = () => {
    onMachineClick(machine.id, machine.attributes.title)
  }

  return (
    <>
      <Flexbox alignItemsCenter className={'et-machine-blades-start-list-item'}>
        <ComponentSpacing
          onClick={handleMachineClick}
          pt="md"
          pl="sm"
          pr="sm"
          pb="md"
          className="img-wrapper"
        >
          <img src={machine.attributes.image.src} />
        </ComponentSpacing>
        <ComponentSpacing
          onClick={handleMachineClick}
          className="machine-text-wrapper"
        >
          <ProductListLargeFontType>
            {v.translate(machine.attributes.title)}
          </ProductListLargeFontType>
        </ComponentSpacing>
      </Flexbox>
    </>
  )
}
