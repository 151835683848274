import React, { useContext, useEffect } from 'react'
import { ProductContext } from '../../providers/products'
import Layout from '../../components/machines/Layout'
import routing from './../../helpers/routing'

const Machines = props => {
  const productContext = useContext(ProductContext)

  useEffect(() => {
    productContext.getMachinesFromCache()
    localStorage.removeItem("machineDetailsScrollValue")

  }, [])

  return (
    <ProductContext.Consumer>
      {context => (
        <Layout
          onGoBackClick={() => routing.goBack(props)}
          machines={context.machines}
        />
      )}
    </ProductContext.Consumer>
  )
}

export default Machines
