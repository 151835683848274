import React from 'react'
import Radio from '../UI/Radio'
import ComponentSpacing from '../containers/ComponentSpacing'

export default function LanguageForm({
  onLangChanged,
  languages,
  currentLanguage,
}) {
  return (
    <>
      {languages.map((lang, index) => (
        <ComponentSpacing key={index} m="sm">
          <Radio
            label={lang.label}
            value={lang.value}
            fullWidth
            checked={lang.value === currentLanguage}
            onClick={() => onLangChanged(lang.value)}
          />
        </ComponentSpacing>
      ))}
    </>
  )
}
