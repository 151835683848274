import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import useAriaId from '../../services/hooks/useAriaId'
import FieldLabel from './FieldLabel'
import FieldError from './FieldError'
import { getIconComponent } from './Icon'
import { ReactComponent as clearIcon } from '../../assets/icons/clear-input.svg'

function InputField(
  {
    error,
    label,
    id,
    icon,
    hideLabel,
    narrow,
    // if clear is set the input field must be controlled!
    clear = false,
    value = undefined,
    onClear,
    ...rest
  },
  ref
) {
  const { t } = useTranslation()
  const ariaId = useAriaId(id)
  const Icon = icon ? getIconComponent(icon) : null
  const Clear = getIconComponent(clearIcon)
  const errorMessage =
    !!error && error.messages && error.messages.length > 0 && error.messages[0]

  return (
    <div
      className={classnames('et-input-field', {
        '-error': errorMessage,
        '-disabled': rest.disabled,
        '-icon': icon,
        '-clearable': !!clear,
        '-narrow': narrow,
      })}
    >
      <label>
        {label && (
          <FieldLabel type="span" hideLabel={hideLabel}>
            {label}
          </FieldLabel>
        )}
        <div className="input-wrapper">
          <input
            type="text"
            aria-invalid={!!error}
            aria-describedby={errorMessage ? ariaId : undefined}
            value={value}
            ref={ref}
            {...rest}
          />
          {Icon && (
            <div className="icon-wrapper">
              <Icon />
            </div>
          )}
          {!!clear && value && value.length !== 0 && (
            <button
              className={classnames('clear-icon-wrapper icon-wrapper', {
                'clear-icon-adjust': icon,
              })}
              onClick={onClear}
              title={t('et.general.clearInput')}
            >
              <Clear />
            </button>
          )}
        </div>
      </label>
      {errorMessage && (
        <FieldError type="span" id={ariaId}>
          {errorMessage}
        </FieldError>
      )}
      {!errorMessage && (
        <FieldError type="span" id={ariaId}></FieldError>
      )}
    </div>
  )
}

export default React.forwardRef(InputField)
