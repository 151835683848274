import React from 'react'
import Slideshow from './../UI/SlideShow'
import v from '../../helpers/vocabulary'
import OnBoardingSlide from './Slide'
import Icon from '../UI/Icon'
import { ReactComponent as Close } from '../../assets/icons/close-slide.svg'
import Flexbox from '../containers/Flexbox'

export default function OnBoardingLayout({
  onClose,
  onFinish,
  titles,
  images,
  length,
  descriptions
}) {
  let slides = []

  for (let i = 0; i < length; i++) {
    slides.push(
      <OnBoardingSlide
      onFinish={onFinish}
        title={v.translate(titles[i])}
        content={v.translate(descriptions[i])}
        imgSrc={images[i]}
        isLastStep={i + 1 === length}
      />
    )
  }
  return (
    <Flexbox column className="et-onboarding-layout">
      <div>
        <Icon onClick={onClose}>
          <Close />
        </Icon>
      </div>
      <Slideshow slideComponents={slides} loopInfinite={false} component="OnBoarding"/>
    </Flexbox>
  )
}
