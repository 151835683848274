import React, { useEffect } from 'react'
import OnBoardingLayout from './../../components/onBoarding/Layout'
import routing from './../../helpers/routing'
import { MetadataContext } from './../../providers/metadata'
import Loading from './../../components/UI/Loading'
import { OnBoardingCategory } from '../../analytics/categories'
import ReactGA from 'react-ga'
import { prevPathKey } from './../../constants/sessionStorageKeys'

export default function OnBoarding({ ...props }) {
  const context = React.useContext(MetadataContext)
  const [slidesData, setSlidesData] = React.useState()

  useEffect(() => {
    if (context.onBoardingData.length === 0) context.getOnBoardingData()
  }, [])

  useEffect(() => {
    let newData = {
      length: 0,
      titles: [],
      descriptions: [],
      images: []
    }

    if (context.onBoardingData.length)
      Object.values(context.onBoardingData).forEach(slide => {
        newData.titles.push(slide.title)
        newData.descriptions.push(slide.description)
        newData.images.push(slide.image)
      })
    newData.length = newData.titles.length
    setSlidesData(newData)
  }, [context])

  const handleClose = () => {

    ReactGA.event({
      category: OnBoardingCategory,
      action: 'Skip vs Completed',
      label: 'Skip button',
    })

    sessionStorage.setItem(prevPathKey, location.pathname)
    routing.goToPage(props, '/')
  }

  const handleFinish = () => {

    ReactGA.event({
      category: OnBoardingCategory,
      action: 'Skip vs Completed',
      label: 'Completed button',
    })

    sessionStorage.setItem(prevPathKey, location.pathname)
    routing.goToPage(props, '/')
  }

  return slidesData && slidesData.length ? (
    <OnBoardingLayout {...slidesData} onClose={handleClose} onFinish={handleFinish} />
  ) : (
    <Loading />
  )
}
