import React, { useEffect } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

function NotificationNumber({ number,className, red = true, blue }) {
  const [executeAnimation, setExecuteAnimation] = React.useState(false)
  const [lastSeenNumber, setLastSeenNumber] = React.useState(0)

  useEffect(() => {
    if(lastSeenNumber !== number && number >0 && lastSeenNumber >0) {
      setExecuteAnimation(true)
    }
    setLastSeenNumber(number)
  }, [number])
  
  return number ? (
    <CSSTransition
      in={executeAnimation}
      timeout={300}
      onEntered={() => {
        setExecuteAnimation(false)
      }}
      classNames="stretch"
    >
      <span
        className={classNames('et-notification-number',className, {
          '-red': red,
          '-blue': blue
        })}
      >
        <span className="-number">{number}</span>
      </span>
    </CSSTransition>
  ) : null
}

export default NotificationNumber
