import React from 'react'
import ContentContainer from '../containers/ContentContainer'
import Dashboard from '../dashboard/index'
import routing from '../../helpers/routing'
import Icon from '../UI/Icon'
import { ReactComponent as Icon_404 } from '../../assets/icons/404.svg'
import {
  BodyExtraBoldFontType,
  BodyFontType,
  H3HeadlineFontType
} from '../UI/Typo'
import v from '../../helpers/vocabulary'
import ComponentSpacing from '../containers/ComponentSpacing'
import Box from '../containers/Box'
import Button from '../UI/Button'
import Textlink from './../UI/Textlink'

export default function Error_404({ ...props }) {
  return (
      <>
      <Dashboard onGoBackClick={() => routing.goBack(props)} loggedIn={true} />
      <div className="et-error-container">
      <ContentContainer>
        <Box className="--responsive-margin-top" mt="4xl"></Box>
        <ComponentSpacing mb="md" pt="4xl">
          <Icon>
            <Icon_404 />
          </Icon>
        </ComponentSpacing>
        <ComponentSpacing mb="xs">
          <BodyExtraBoldFontType>Hoppla!</BodyExtraBoldFontType>
        </ComponentSpacing>
        <ComponentSpacing mb="xxl">
          <BodyFontType noSpace>
            {v.translate('Die Seite wurde nicht gefunden.')}
          </BodyFontType>
        </ComponentSpacing>
        <Textlink
          style={{marginTop: 0}}
          label={v.translate('basket.form.success.cta.linkToDashboard')}
          onClick={() => routing.goToPage(props, '/')}
        />
      </ContentContainer>
    </div>
    </>
  )
}
