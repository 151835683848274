import { bladesKey, metadataKey } from './../constants/localStorageKeys'

const bladeHelper = {
  getBladeById: id => {
    const blades = localStorage.getItem(bladesKey)
    const blade = JSON.parse(blades).data.find(b => b.id == id)
    return blade
  },
  getCutTypeById: id => {
    const metadata = localStorage.getItem(metadataKey)
    if (metadata) {
      const cutTypes = JSON.parse(metadata).data.attributes.cut_types
      return cutTypes[id]
    }
  },
  getBladeIdByCutTypeIdForMachine: (cutTypeId, machine) => {
    const blades = machine.attributes.blades
    let bladeIdFound = null
    for (let bladeId in blades) {
      if (blades[bladeId].cut_type == cutTypeId) {
        bladeIdFound = bladeId
        break
      }
    }
    return bladeIdFound
  }
}

export default bladeHelper
