import React from 'react'
import classnames from 'classnames'
import useAriaId from '../../services/hooks/useAriaId'
import FieldLabel from './FieldLabel'
import FieldError from './FieldError'

function Textarea(
  { error, label, id, hideLabel, labelClassName, value = undefined, ...rest },
  ref
) {
  const ariaId = useAriaId(id)
  return (
    <div
      className={classnames('et-textarea', {
        '-error': !!error,
        '-disabled': rest.disabled
      })}
    >
      <label>
        <FieldLabel
          className={labelClassName}
          type="span"
          hideLabel={hideLabel}
        >
          {label}
        </FieldLabel>
        <div className="textarea-wrapper">
          {' '}
          <textarea
            aria-invalid={!!error}
            aria-describedby={ariaId}
            value={value}
            ref={ref}
            {...rest}
          />
        </div>
      </label>
      {!!error && (
        <FieldError type="span" id={ariaId}>
          {error.messages[0]}
        </FieldError>
      )}
            {!error && (
        <FieldError type="span" id={ariaId}>
        </FieldError>
      )}
    </div>
  )
}

export default React.forwardRef(Textarea)
