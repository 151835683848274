import React, { useEffect } from 'react'
import ComponentSpacing from '../../../components/containers/ComponentSpacing'
import { ReactComponent as Success } from '../../../../src/assets/icons/success.svg'
import Flexbox from '../../../components/containers/Flexbox'
import { BodyFontType } from '../../../components/UI/Typo'
import v from '../../../helpers/vocabulary'
import Textlink from '../../../components/UI/Textlink'
import Dashboard from '../../dashboard'
import Snackbar from '../../UI/Snackbar'
import ContentContainer from '../../containers/ContentContainer'
import Icon from '../../UI/Icon'
import routing from '../../../helpers/routing'
import { useHistory } from 'react-router-dom'


export const OfferSuccess = ({
  onGoBackClick,
  leadTitle,
  resetSuccessState,
}) => {
  useEffect(() => {
    if(resetSuccessState)
      return () => resetSuccessState()
  }, [])
  const history = useHistory()

  return (
    <>
      <Dashboard
        onGoBackClick={onGoBackClick}
        title={v.translate('basket.form.title')}
        loggedIn={true}
      />
      <div className="et-error-container">
      <ContentContainer>
        <ComponentSpacing mb="md" pt="8xl" className="success-wrapper">
          <Icon>
            <Success />
          </Icon>
        </ComponentSpacing>
        <ComponentSpacing mb="xxl">
          <BodyFontType className="offline-body" noSpace>
          {v.translate(leadTitle)}
          </BodyFontType>
        </ComponentSpacing>
        <Flexbox centered className="success-link-container">
          <Textlink
            noSpace
            label={v.translate('basket.form.success.cta.linkToDashboard')}
            onClick={() => routing.goToPage({ history }, '/')}
          />
        </Flexbox>
        <Snackbar
            message={v.translate('snackbar.pendingSendouts.sendoutSuccessful')}
            duration={3000}
          />
      </ContentContainer>
      </div>
    </>
  )
}
