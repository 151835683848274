import React, { useEffect, useState } from 'react'
import ls from '../../helpers/localStorage'
import LanguageLayout from './../../components/language/Layout'
import routing from './../../helpers/routing'
import v from '../../helpers/vocabulary'
import Loading from '../../components/UI/Loading'
import ReactGA from 'react-ga'
import { LanguageCategory } from '../../analytics/categories'

export default function Language(props) {
  const [loading, setLoading] = useState(false)

  useEffect(() => setLoading(false), [])

  const languages = [
    { label: v.translate('settings.language.option.german'), value: 'de' },
    { label: v.translate('settings.language.option.english'), value: 'en' },
    { label: v.translate('settings.language.option.french'), value: 'fr' },
    { label: v.translate('settings.language.option.italian'), value: 'it' },
    { label: v.translate('settings.language.option.spanish'), value: 'es' }
  ]

  const handleLangChanged = lang => {
    ReactGA.event({
      category: LanguageCategory,
      action: 'Set language',
      label: lang + ' option',
    })
    setLoading(true)
    ls.setAppLang(lang)
    props.history.go(0)
  }
  return loading ? (
    <Loading />
  ) : (
    <LanguageLayout
      onGoBackClick={() => routing.goBack(props)}
      currentLanguage={ls.getAppLang()}
      onLangChanged={handleLangChanged}
      languages={languages}
    />
  )
}
