import React from 'react'
import ComponentSpacing from '../../../components/containers/ComponentSpacing'
import { ReactComponent as Basket } from '../../../../src/assets/icons/basket-outlined-big.svg'
import Flexbox from '../../../components/containers/Flexbox'
import { BodyFontType } from '../../../components/UI/Typo'
import v from '../../../helpers/vocabulary'
import { Link } from 'react-router-dom'
import Textlink from './../../UI/Textlink'

export const EmptyBasket = () => {
  return (
    <div className="grey-lighten-97-background " style={{ height: '100%' }}>
      <ComponentSpacing pt="8xl" className="basket-empty-container">
          <Flexbox column alignItemsCenter>
            <Basket />
            <ComponentSpacing pt="md" />
            <BodyFontType noSpace centered className="et-empty-basket-conent">
              {v.translate('basket.emptyStateLead')}
            </BodyFontType>
            <ComponentSpacing pt="xxl" />
            <Link to="/machines" className="backet-to-machine">
              <Textlink label={v.translate('general.cta.linkToProducts')} />
            </Link>
          </Flexbox>
      </ComponentSpacing>
    </div>
  )
}
