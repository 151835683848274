import React, { useState } from 'react'
import routing from './../../helpers/routing'
import Headline from './../UI/Headline'
import v from '../../helpers/vocabulary'
import ContentContainer from './../containers/ContentContainer'
import BasketList from './List'
import ComponentSpacing from '../containers/ComponentSpacing'
import { TextLinkFontType } from './../UI/Typo'
import TextLinkFontTypeIcon from '../UI/TextLinkFontTypeIcon'
import { ReactComponent as Delete } from '../../assets/icons/delete.svg'
import { ReactComponent as Basket } from '../../assets/icons/basket-sm-vivid-blue.svg'
import Button from '../UI/Button'
import ConfirmDialog from './../UI/ConfirmDialog'
import Flexbox from '../containers/Flexbox'
import { Link } from 'react-router-dom'

export default function BasketLayout({
  onDeleteItemClick,
  onGoBackClick,
  items,
  quantityChanged,
  onDeleteBasket,
  basketNotificationNum,
  ...props
}) {
  const [openedDeleteBasketDialog, setOpenDeleteBasketDialog] = useState(false)

  return (
    <>
      <Headline
        className="et-basket-headline basic-layout"
        title={v.translate('basket.title')}
      />
      <ComponentSpacing pt="lg" />
      <div className="et-basket-container">
          <BasketList
            className="grey-lighten-97-background"
            quantityChanged={quantityChanged}
            onDeleteItemClick={onDeleteItemClick}
            items={items}
            onDeleteItemClick={onDeleteItemClick}
          />
          <ComponentSpacing pl="sm" pr="sm" pt="lg" className="basic-layout">
            <Flexbox className="et-basket-layout-action-section">
              <Flexbox pt="sm" className="actions-container">
                <ComponentSpacing
                  onClick={() =>
                    setOpenDeleteBasketDialog(!openedDeleteBasketDialog)
                  }
                >
                  <TextLinkFontTypeIcon
                    IconComponent={Delete}
                    label={v.translate('basket.cta.clearBasket')}
                  />
                </ComponentSpacing>
                <ComponentSpacing id="continue-shopping" ml="sm">
                  <TextLinkFontTypeIcon
                    IconComponent={Basket}
                    onClick={onGoBackClick}
                    label={v.translate('basket.cta.continueShopping')}
                  />
                </ComponentSpacing>
              </Flexbox>
              <ComponentSpacing mt="lg" mb="xxl">
                <Link to="/offer">
                  <Button fullWidth centered>
                    {v.translate('basket.cta.requestOffer')}
                  </Button>
                </Link>
              </ComponentSpacing>
            </Flexbox>
          </ComponentSpacing>
        </div>
      <ConfirmDialog
        opened={openedDeleteBasketDialog}
        onCancel={() => setOpenDeleteBasketDialog(false)}
        onClose={() => setOpenDeleteBasketDialog(false)}
        onConfirm={onDeleteBasket}
        title={v.translate('basket.cta.clearBasket')}
      />
    </>
  )
}
