import React from 'react'
import Flexbox from '../containers/Flexbox'
import { TextLinkFontType } from './Typo'
import Icon from './Icon'

export default function TextLinkFontTypeIcon({
  IconComponent,
  label,
  onClick,
}) {
  return (
    <Flexbox className="et-text-link-icon" onClick={onClick}>
      <Icon>
        <IconComponent />
      </Icon>
      <TextLinkFontType>{label}</TextLinkFontType>
    </Flexbox>
  )
}
