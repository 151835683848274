import React from 'react'
import ComponentSpacing from '../components/containers/ComponentSpacing'

export const with3XLSpace = withSpace('3xl')
export const with2XLSpace = withSpace('2xl')
export const withXLSpace = withSpace('xl')
export const withLargeSpace = withSpace('lg')
export const withMediumSpace = withSpace('md')
export const withSmallSpace = withSpace('sm')
export const withXSSpace = withSpace('xs')
export const withXXSSpace = withSpace('xxs')
export const withXXXSSpace = withSpace('xxxs')

function withSpace(size) {
  return (previous, current) => (
    <>
      {previous}
      <ComponentSpacing pt={size} />
      {current}
    </>
  )
}
