import React from 'react'
import Box from '../containers/Box'
import MachineTile from './MachineTile'
import v from '../../helpers/vocabulary'
import ContentContainer from './../containers/ContentContainer'
import Dashboard from './../dashboard/index'
import Headline from '../UI/Headline'
import ComponentSpacing from '../containers/ComponentSpacing'
import Flexbox from '../containers/Flexbox'
import HierarchialTransitionContainer from '../animations/HieararchialTransition'

function Layout({ machines, onGoBackClick }) {
  return (
    <>
      <Dashboard 
      loggedIn={true}
      onGoBackClick={onGoBackClick}
      title={v.translate('dashboard.navigation.products')}
      />
      <HierarchialTransitionContainer>
          <ContentContainer className="et-machine-headline basic-layout">
            <ComponentSpacing pt="md" pb="lg">
              <Headline title={v.translate('products.door.title')} />
            </ComponentSpacing>
          </ContentContainer>
          <ContentContainer className="et-machine-start-container" mb="5xl">
            <Flexbox centered className="basic-layout">
              <Box ml="xxl" mr="xxl">
                {machines &&
                  machines.map(machine => {
                    return <MachineTile key={machine.id} machine={machine} />
                  })}
              </Box>
            </Flexbox>
          </ContentContainer>
      </HierarchialTransitionContainer>
    </>
  )
}

export default Layout
