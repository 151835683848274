import React from 'react'
import Dashboard from './../dashboard/index'
import ContentContainer from './../containers/ContentContainer'
import Headline from '../UI/Headline'
import v from '../../helpers/vocabulary'
import ComponentSpacing from '../containers/ComponentSpacing'
import { BodyFontType } from '../UI/Typo'
import HierarchialTransitionContainer from '../animations/HieararchialTransition'

export default function AboutLayout({ onGoBackClick }) {
  return (
    <>
      <Dashboard
        loggedIn={true}
        title={v.translate('about.title')}
        onGoBackClick={onGoBackClick}
      />
      <HierarchialTransitionContainer>
        <ContentContainer>
          <ComponentSpacing pt="md">
            <Headline title={v.translate('about.title')} />
          </ComponentSpacing>
          <ComponentSpacing mt="lg">
            <BodyFontType>
              {v.translate('about.content.paragraph1')}{' '}
            </BodyFontType>
          </ComponentSpacing>
          <ComponentSpacing mt="md">
            <BodyFontType>
              {v.translate('about.content.paragraph2')}{' '}
            </BodyFontType>
          </ComponentSpacing>
        </ContentContainer>
      </HierarchialTransitionContainer>
    </>
  )
}
