import React from 'react'
import classNames from 'classnames'
import { ProductListSmallFontType } from './Typo'

function Button({
  elementType = 'button',
  children,
  disabled = false,
  secondary = false,
  small = false,
  tertiary = false,
  centered = false,
  className = '',
  fullWidth,
  ...rest
}) {
  return React.createElement(elementType, {
    className: classNames(
      'et-button',
      {
        '-disabled': disabled,
        '--primary': !secondary,
        '--secondary': secondary,
        '--small': small,
        '--tertiary': tertiary,
        '--fullWidth': fullWidth
      },
      className
    ),
    disabled,
    children: (
      <React.Fragment>
        {!!children && (
          <ProductListSmallFontType type="span" {...{ centered }}>
            {children}
          </ProductListSmallFontType>
        )}
      </React.Fragment>
    ),
    ...rest
  })
}

export function IconButton({
  Icon = () => null,
  rounded = false,
  round = false,
  navigate = false,
  className,
  ...rest
}) {
  return (
    <Button
      secondary
      className={classNames(className, '--icon', {
        '--rounded': rounded,
        '--navigate': navigate,
        '--round': round
      })}
      {...rest}
    >
      <Icon />
      <span className="visually-hidden">{rest.label}</span>
    </Button>
  )
}

export default Button
