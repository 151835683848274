import React, { useRef, useEffect } from 'react'
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow-down.svg'
import Flexbox from '../../../containers/Flexbox'

export const SelectInput = ({
  className,
  imgClass,
  imageSrc,
  onChange,
  onChangeEvent,
  children,
  ...props
}) => {
  const selectRef = useRef(null)
  useEffect(() => {
    selectRef.current.onfocus = handleFocus
    selectRef.current.onblur = handleFocus
  }, [])
  const handleFocus = () => {
    const cl = selectRef.current.parentElement.classList
    const focused = '--focused'
    cl.contains(focused) ? cl.remove(focused) : cl.add(focused)
  }

  return (
    <Flexbox alignItemsCenter className={`select-input-div ${className}`}>
      {imageSrc && <img className={imgClass} src={imageSrc} />}
      <select
        ref={selectRef}
        onChange={(evt) => {
          const { value } = evt.target
          onChangeEvent && onChangeEvent(evt)
          onChange && onChange(value)
        }}
        {...props}
      >
        {children}
      </select>
      <div className="select-input-arrow-container">
        <Arrow />
      </div>
    </Flexbox>
  )
}
