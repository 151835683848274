import React from 'react'
import v from '../../../helpers/vocabulary'
import { BodyFontType, BodyExtraBoldFontType } from '../../UI/Typo'
import ComponentSpacing from '../../containers/ComponentSpacing'
import Flexbox from '../../containers/Flexbox'
import { CalculateButton } from '../../UI/buttons/calculate'
import { ReactComponent as Alert } from '../../../assets/icons/alert.svg'
import Icon from '../../UI/Icon'

export const PaybackCalculatorProcessingCostConfigurableElement = (
  { name, value, renderValue, onClick },
  index
) => (
  <ComponentSpacing pt="sm" key={index}>
    <Flexbox spaceBetween alignItemsCenter>
      <ComponentSpacing pr="xl">
        <BodyFontType noSpace>{name}</BodyFontType>
      </ComponentSpacing>
      <Flexbox alignItemsCenter>
        <ComponentSpacing pl="sm" pr="sm">
          <BodyFontType noSpace>
            {renderValue ? renderValue(value) : value}
          </BodyFontType>
        </ComponentSpacing>
        <CalculateButton {...{ onClick }} />
      </Flexbox>
    </Flexbox>
  </ComponentSpacing>
)

export const PaybackCalculatorProcessingCostElement = ({
  name,
  value,
  renderValue,
}) => (
  <Flexbox spaceBetween alignItemsEnd className="results-flexbox">
    <BodyFontType noSpace>{name}</BodyFontType>
    <ComponentSpacing pl="xl">
      <BodyFontType noSpace noWrap>
        {renderValue ? renderValue(value) : value}
      </BodyFontType>
    </ComponentSpacing>
  </Flexbox>
)

export const PaybackCalculatorResultElement = (
  { name, value, renderValue, minusValueLabel },
  index
) =>
  value > 0 ? (
    <ComponentSpacing mb="xs">
      <Flexbox
        spaceBetween
        alignItemsEnd
        className="results-flexbox"
        key={index}
      >
        <BodyExtraBoldFontType noSpace>{name}</BodyExtraBoldFontType>
        <ComponentSpacing pl="xl" pr="sm" style={{ marginRight: '40px' }}>
          <BodyExtraBoldFontType noSpace noWrap>
            {renderValue ? renderValue(value) : value}
          </BodyExtraBoldFontType>
        </ComponentSpacing>
      </Flexbox>
    </ComponentSpacing>
  ) : (
    <ComponentSpacing
      mb="xs"
      pt="sm"
      pr="sm"
      pb="sm"
      pl="sm"
      className="et-minus-value-message"
    >
      <Flexbox>
        <Icon>
          <Alert />
        </Icon>
        <BodyExtraBoldFontType noSpace noWrap>
          {v.translate(minusValueLabel)}
        </BodyExtraBoldFontType>
      </Flexbox>
    </ComponentSpacing>
  )
