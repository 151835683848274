import service from './api'
import { machines, blades, accessories } from '../constants/apiRoutes'
import { machinesKey, bladesKey, accessoriesKey } from '../constants/localStorageKeys'
import { apiService } from './api'

export default {
  async getMachines() {
    const response = await service.getDataService(machines, machinesKey)
    if (response.data) {
      for (let m in response.data) {
        const machine = response.data[m]
        const { image, images } = machine.attributes
        fetch(image.src, { mode: 'no-cors' })
        Object.values(images).forEach(imgRes => {
          fetch(imgRes.lowres.src, { mode: 'no-cors' })
          fetch(imgRes.highres.src, { mode: 'no-cors' })
        })
      }
    }
    return response
  },

  async getBlades() {
    const response = await service.getDataService(blades, bladesKey)
    if (response.data) {
      for (let b in response.data) {
        const blade = response.data[b]
        fetch(blade.attributes.blade_image.src, { mode: 'no-cors' })
      }
    }
    return response
  },

  async getAccessories() {
    const response = await service.getDataService(accessories, accessoriesKey)
    return response
  }
}
