import React, { useContext } from 'react'
import { BodyExtraBoldFontType } from '../../../../UI/Typo'
import ComponentSpacing from '../../../../containers/ComponentSpacing'
import Flexbox from '../../../../containers/Flexbox'
import { HorizontalSeparator } from '../../../../UI/separator/horizontal'
import { PaybackCalculatorResultElement } from '../../../UI'
import {
  formatKg,
  formatCurrency,
  formatPercentage
} from '../../../../../helpers/formatter'
import { withMediumSpace } from '../../../../../helpers/spacing'
import { Cost } from '../../../UI/cost'
import { CalculatorContext } from '../../../../../providers/calculator'
import v from '../../../../../helpers/vocabulary'

export function PaybackCalculatorMaterialTab() {
  const {
    selectedMachine,
    amountPerCharge,
    setAmountPerCharge,
    productMaterialRawPrice,
    setProductMaterialRawPrice,
    materialPeelLoss,
    setMaterialPeelLoss,
    productMaterialNetPricePerKg,
    productMaterialTotalCosts
  } = useContext(CalculatorContext)
  const costs = [
    {
      name: v.translate('calculator.savings.tabMaterial.batchQuantity'),
      value: amountPerCharge,
      renderValue: formatKg,
      onChange: setAmountPerCharge,
      min: selectedMachine.attributes.roi_data.amount_per_charge.range_min,
      max: selectedMachine.attributes.roi_data.amount_per_charge.range_max,
      step: selectedMachine.attributes.roi_data.amount_per_charge.steps
    },
    {
      name: v.translate('calculator.savings.tabMaterial.rawPrice'),
      value: productMaterialRawPrice,
      renderValue: formatCurrency,
      onChange: setProductMaterialRawPrice,
      min:
        selectedMachine.attributes.roi_data.product_material_raw_price
          .range_min,
      max:
        selectedMachine.attributes.roi_data.product_material_raw_price
          .range_max,
      step: selectedMachine.attributes.roi_data.product_material_raw_price.steps
    },
    {
      name: v.translate('calculator.savings.tabMaterial.peelLoss'),
      value: materialPeelLoss,
      renderValue: formatPercentage,
      onChange: setMaterialPeelLoss,
      min:
        selectedMachine.attributes.roi_data.product_material_peel_loss
          .range_min,
      max:
        selectedMachine.attributes.roi_data.product_material_peel_loss
          .range_max,
      step: selectedMachine.attributes.roi_data.product_material_peel_loss.steps
    }
  ]

  const results = [
    {
      name: v.translate('calculator.savings.tabMaterial.rawPrice'),
      value: productMaterialNetPricePerKg,
      renderValue: formatCurrency
    },
    {
      name: v.translate(
        'calculator.savings.tabMaterial.summary.totalCostMaterials',
        { batchQuantity: amountPerCharge }
      ),
      value: productMaterialTotalCosts,
      renderValue: formatCurrency
    }
  ]
  return (
    <>
      <ComponentSpacing
        pt="xs"
        pl="lg"
        pr="lg"
        pb="sm"
        className="vivid-blue-background et-payback-modal-header-end"
        id="et-configure-machine-material"
      >
        <Flexbox spaceBetween className="basic-layout">
          <BodyExtraBoldFontType noSpace>
            {v.translate('calculator.savings.tabMaterial.subtitle')}
          </BodyExtraBoldFontType>
          <BodyExtraBoldFontType noSpace>
            {formatCurrency(productMaterialTotalCosts)}
          </BodyExtraBoldFontType>
        </Flexbox>
      </ComponentSpacing>
      <ComponentSpacing pt="3xl" pb="3xl" pl="lg" pr="lg" className="basic-layout et-payback-modal-body">
        {costs.map(Cost).reduce(withMediumSpace)}
        <ComponentSpacing pt="xl" pb="xs">
          <HorizontalSeparator />
        </ComponentSpacing>
        {results.map(PaybackCalculatorResultElement)}
      </ComponentSpacing>
    </>
  )
}
