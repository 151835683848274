import React from 'react'
import Flexbox from '../containers/Flexbox'
import { BodyLargeFontType, ProductUSPFontType, ButtonSmallFontType } from '../UI/Typo'
import ComponentSpacing from './../containers/ComponentSpacing'
import Button from '../UI/Button'
import v from '../../helpers/vocabulary'

export default function OnBoardingSlide({
  imgSrc,
  title,
  content,
  isLastStep,
  onFinish
}) {
  return (
    <Flexbox column alignItemsCenter>
      <img src={imgSrc} alt={title} />
      <span className="img-underline" />
      <ComponentSpacing
        className="content-wrapper onboarding-text-container"
        pt="lg"
        pb="md"
        pl="sm"
        pr="sm"
      >
        <ProductUSPFontType noSpace>{title}</ProductUSPFontType>
        <ComponentSpacing pt="xs" pr="sm" pl="sm">
          <BodyLargeFontType noSpace>{content}</BodyLargeFontType>
        </ComponentSpacing>
        {isLastStep && (
          <Flexbox centered className="et-onboarding-start-button-wrapper">
            <Button onClick={onFinish} className="et-onboarding-start-button">
              <ButtonSmallFontType noSpace>{v.translate('onboarding.action.start')}</ButtonSmallFontType>
            </Button>
          </Flexbox>
        )}
      </ComponentSpacing>
    </Flexbox>
  )
}
