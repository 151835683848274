import React from 'react'
import SectionTile from './SectionTile'
import machineImg from '../../assets/images/home/machines-tumb-500w.png'
import bladesImg from '../../assets/images/home/discs-thumb-500w.png'
import aboutImg from '../../assets/images/home/about-thumb-500w.jpg'
import roiImg from '../../assets/images/home/calculator-thumb-500w.png'
import Box from '../containers/Box'
import v from '../../helpers/vocabulary'
import Dashboard from './../dashboard/index'
import HierarchialTransitionContainer from '../animations/HieararchialTransition'
import AddToHomeScreen from '../addToHomeScreen'

function Layout() {
  return (
    <div className="et-home-layout-container">
      <Dashboard loggedIn={true} title={v.translate('dashboard.title')} />
      <HierarchialTransitionContainer>
        <div className="et-home-layout basic-layout">
          <Box pl="sm" pr="sm">
            <SectionTile
              type="primary"
              img={machineImg}
              name={v.translate('dashboard.navigation.products')}
              link="/machines"
            />
          </Box>
          <Box pt="sm" pl="sm" pr="sm">
            <SectionTile
              type="primary"
              img={bladesImg}
              name={v.translate('dashboard.navigation.discScanner')}
              link="/blades"
            />
          </Box>
          <Box pt="sm" pl="sm" pr="sm">
            <SectionTile
              type="primary"
              img={roiImg}
              name={v.translate('dashboard.navigation.roiCalculator')}
              link="/roi"
            />
          </Box>
          <Box className="about-us-section-tile" pl="sm" pr="sm">
            <SectionTile
              type="secondary"
              img={aboutImg}
              name={v.translate('dashboard.navigation.about')}
              desc={v.translate('dashboard.navigation.about.description')}
              link="/about"
              className="p-0"
            />
          </Box>
        </div>
      </HierarchialTransitionContainer>

      <AddToHomeScreen />
    </div>
  )
}

export default Layout
