import React, { useState, useEffect } from 'react'
import animationHelper from '../../helpers/animation'

const HeaderTitle = ({ title, showTitle }) => {
  const titleRef = React.useRef(null)
  const [shouldAnimate, setShouldAnimate] = useState(false)

  useEffect(() => {
    if (showTitle) {
      setShouldAnimate(true)
      animationHelper.animateCSS(titleRef, 'fadeInUp', null)
    } else
    setShouldAnimate(false)
    
  }, [showTitle])

  return (
    <div ref={titleRef} className={'header-page-title'}>
      {shouldAnimate && title}
    </div>
  )
}

export default HeaderTitle
