import React from 'react'
import Box from './Box'

import classNames from 'classnames'

function ContentContainer({
  primary = false,
  shadow = false,
  borderTop = false,
  borderBottom = false,
  mobileNoGutter = false,
  containerClassName,
  ...rest
}) {
  return (
    <div
      className={classNames('et-content-container', containerClassName, {
        '--primary': !!primary,
        '--shadow': shadow,
        '--border-top': borderTop,
        '--border-bottom': borderBottom,
        '--mobile-no-gutter': mobileNoGutter
      })}
    >
      <Box className="wrapper" pl="page-content" pr="page-content" {...rest} />
    </div>
  )
}

export default ContentContainer
