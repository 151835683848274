import React from 'react'
import { ReactComponent as ZoomInSvg } from '../../assets/icons/zoom-in.svg'
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg'
import { IconButton } from './Button'
import ReactGA from 'react-ga'
import { AppInteractionCategory } from '../../analytics/categories'

export default function RenderZoomInIcon(onClick, isFullscreen) {

  // this is a hack, bcs app header is fixed and overlaps the full screen gallery mode
  var appHeader =  document.getElementsByClassName("et-app-header")[0];
  if (appHeader && isFullscreen) {
    document.getElementsByClassName("et-app-header")[0].style.display = 'none';
  }

  else if(appHeader && !isFullscreen) {
    document.getElementsByClassName("et-app-header")[0].style.display = 'flex';
  }

  const openFullScreen = () => {
    ReactGA.event({
      category: AppInteractionCategory,
      action: 'Open Gallery',
      label: 'Fullscreen machine/blade screen',
    })
    onClick();
  }
  if (!isFullscreen) {
    return (
      <IconButton
        className="et-gallery-zoom-image"
        onClick={openFullScreen}
        round
        Icon={ZoomInSvg}
      />);
  }
  else {
    return (
      <div className="et-gallery-fullscreen-wrapper" onClick={onClick} >
        <CloseSvg className="et-gallery-fullscreen-close" />
      </div>

    );
  }

}
