import React from 'react'
import Flexbox from '../containers/Flexbox'
import {
  H2HeadlineFontType,
  ProductListLargeFontType,
  BodySmallFontType,
  H3HeadlineFontType,
} from '../UI/Typo'
import ComponentSpacing from '../containers/ComponentSpacing'
import { Link } from 'react-router-dom'

function PrimaryContent({ title }) {
  return (
    <ComponentSpacing className="primary-name-wrapper" pr="md" pt="md" pb="md">
      <H2HeadlineFontType noSpace>{title}</H2HeadlineFontType>
    </ComponentSpacing>
  )
}

function SecondaryContent({ title, desc }) {
  return (
    <ComponentSpacing
      className="secondary-name-wrapper"
      pr="md"
      pt="md"
      pb="md"
    >
      <H3HeadlineFontType className="section-about-title" noSpace>
        {title}
      </H3HeadlineFontType>
      <BodySmallFontType className="section-about-desc" noSpace>
        {desc}
      </BodySmallFontType>
    </ComponentSpacing>
  )
}

function SectionTile({ img, name, desc, link = '', type, className }) {
  return (
    <Link to={link} className="et-section-link">
      <Flexbox
        alignItemsCenter
        className={`et-section-tile-container ${type} ${
          type === 'primary' ? 'white-background' : ''
        } ${className}`}
      >
        <ComponentSpacing
          className="sectiontile-img-spacing"
          pt="md"
          pl="md"
          pr="md"
          pb="md"
        >
          <div className="img-wrapper">
            <img src={img} />
          </div>
        </ComponentSpacing>
        {type == 'primary' ? (
          <PrimaryContent title={name} />
        ) : (
          <SecondaryContent title={name} desc={desc} />
        )}
      </Flexbox>
    </Link>
  )
}

export default SectionTile
