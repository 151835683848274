import React from 'react'
import { PaybackCalculatorProcessingCostElement } from '..'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import InputSlider from '../../../UI/InputSlider'

export const Cost = ({ name, value, renderValue, ...props }) => (
  <>
    <PaybackCalculatorProcessingCostElement {...{ name, value, renderValue }} />
    <ComponentSpacing pt="sm" />
    <InputSlider {...{ value, ...props }} />
  </>
)
