import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputField from '../UI/InputField'
import v from '../../helpers/vocabulary'
import Button from '../UI/Button'
import ComponentSpacing from '../containers/ComponentSpacing'
import { FontType3, TextLinkFontType } from '../UI/Typo'

const LoginForm = ({
  loginAction,
  handleForgotPassClick,
  values,
  errors,
  resetErrors
}) => {
  return (
    <div className="et-login-form et-auth-form">
      <Formik
        initialValues={{ email: values.email, password: values.password }}
        onSubmit={loginAction}
        validationSchema={Yup.object().shape({
          email: Yup.string().required(),
          password: Yup.string().required()
        })}
      >
        {props => {
          const {
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm
          } = props
          const onChange = event => {
            handleChange(event)
            if (isSubmitting) {
              resetErrors()
              resetForm()
            }
          }
          return (
            <form onSubmit={handleSubmit}>
              <InputField
                name="email"
                type="text"
                label={v.translate('login.form.email')}
                error={
                  errors.email &&
                  touched && {
                    messages: [errors.email]
                  }
                }
                value={values.email}
                onChange={onChange}
                onBlur={handleBlur}
              />
              <ComponentSpacing pb="sm" />
              <InputField
                name="password"
                type="password"
                label={v.translate('login.form.password')}
                value={values.password}
                onChange={onChange}
                error={
                  errors.password &&
                  touched && {
                    messages: [errors.password]
                  }
                }
                onBlur={handleBlur}
              />
              <FontType3>
                <ComponentSpacing mt="md">
                  <TextLinkFontType onClick={handleForgotPassClick}>
                    {v.translate('login.form.forgotPassword')}
                  </TextLinkFontType>
                </ComponentSpacing>
              </FontType3>
              <ComponentSpacing className="submit-btn" mt="xl" mb="xl">
                <Button type="submit" disabled={isSubmitting}>
                  {v.translate('login.form.cta')}
                </Button>
              </ComponentSpacing>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default LoginForm
