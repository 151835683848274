import React from 'react'
import api from '../services/metadata'
import { metadataKey } from '../constants/localStorageKeys'

export const MetadataContext = React.createContext()

export const MetadataProvider = ({ children, ...props }) => {
  var metadataFromLocalStorage = [];
  if(localStorage.getItem(metadataKey))
    var metadataFromLocalStorage = JSON.parse(localStorage.getItem(metadataKey)).data.attributes.onboarding_slides;

  const [onBoardingData, setOnBoardingData] = React.useState(metadataFromLocalStorage)

  const getOnBoardingData = async () => {
    if (onBoardingData.length === 0) {
      const result = await api.getOnBoardingSlides()
      setOnBoardingData(result.data.attributes.onboarding_slides)
    }
  }
  return (
    <MetadataContext.Provider
      value={{
        getOnBoardingData,
        onBoardingData
      }}
    >
      {children}
    </MetadataContext.Provider>
  )
}
