import React, { useState, useEffect } from 'react'

export const ETModal = ({ children, visible = false }) => {
  const modalClassName = 'et-modal-component'
  const displayNone = 'display-none'
  const [className, setClassName] = useState(`${modalClassName} ${displayNone}`)
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
      return
    }
    if (visible) {
      setClassName(`${modalClassName} showModally`)
      document.getElementById('root')?.setAttribute("style", "overflow: hidden;")
      document.getElementById('html')?.scrollIntoView({ behavior: 'smooth' })         
    } else {
      setClassName(`${modalClassName} dismissModally`)
      document.getElementById('root')?.setAttribute("style", "overflow: initial;")
      setTimeout(() => {
        setClassName(`${modalClassName} ${displayNone}`)
      }, 300)
    }
    return () => {}
  }, [visible])

  return <div {...{ className }}>{children}</div>
}
