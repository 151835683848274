import { machinesKey } from '../constants/localStorageKeys'

const machineHelper = {
  getMachineById: id => {
    const machines = localStorage.getItem(machinesKey)
    const machine = JSON.parse(machines).data.find(m => m.id == id)
    return machine
  }
}

export default machineHelper
