import React, { useContext, useEffect, useState, useRef } from 'react'
import { ProductContext } from '../../providers/products'
import BladesLayout from '../../components/blades/Layout'
import routing from './../../helpers/routing'
import lsHelper from '../../helpers/localStorage'
import ReactGA from 'react-ga'
import { DiscSearchCategory } from '../../analytics/categories'

export default function Blades({ ...props }) {
  const context = useContext(ProductContext)
  const [availableMachines, setAvailableMachines] = useState(context.machines)

  useEffect(() => {
    // remove values for blade search
    localStorage.removeItem("bladeSearchValue")
    localStorage.removeItem("scrollPositionValue")
    if (!context.blades?.length) context.getBlades()
    if (!context.machines?.length) context.getMachines()
  }, [])

  useEffect(() => {
    let availableMachines = context.machines
    const userGlobalRegion = lsHelper.getUserGlobalRegion()
    if (availableMachines && availableMachines.length)
      availableMachines = availableMachines.filter(machine =>
        machine.attributes.available_regions.includes(userGlobalRegion)
      )
    setAvailableMachines(availableMachines)
  }, [context.machines])
  return (
    <BladesLayout
      onMachineClick={(machineId, title) => {

        ReactGA.event({
          category: DiscSearchCategory,
          action: 'Disc Search choose machine',
          label: title
        })
        routing.goToPage(props, '/blades-search/' + machineId)

      }
      }
      onGoBackClick={() => routing.goBack(props)}
      machines={availableMachines}
    />
  )
}
