import React from 'react'
import classnames from 'classnames'
import { BodyFontType } from './Typo'

function FieldLabel({ hideLabel, ...props }) {
  return (
    <BodyFontType
      darkBlue
      className={classnames('et-field-label', {
        'visually-hidden': hideLabel
      })}
      {...props}
    />
  )
}

export default FieldLabel
