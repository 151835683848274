import React from 'react'
import classNames from 'classnames'

function Flexbox({
  children,
  className,
  wrap = false,
  column = false,
  flexEnd = false,
  centered = false,
  spaceBetween = false,
  grow = false,
  noShrink = false,
  alignItemsStart = false,
  alignItemsCenter = false,
  alignItemsEnd = false,
  swapMobileDirection = false,
  reverse = false,
  fullWidth = false,
  withGutter = false,
  ...props
}) {
  return (
    <div
      className={classNames('et-flexbox-container', className, {
        '-column': column,
        '-reverse': reverse,
        '-flex-end': flexEnd,
        '-centered': centered,
        '-space-between': spaceBetween,
        '-grow': grow,
        '-no-shrink': noShrink,
        '-wrap': wrap,
        '-align-items-start': alignItemsStart,
        '-align-items-center': alignItemsCenter,
        '-align-items-end': alignItemsEnd,
        '-swap-mobile-direction': swapMobileDirection,
        '-full-width': fullWidth,
        '-with-gutter': withGutter,
      })}
      {...props}
    >
      {children}
    </div>
  )
}

export const StopShrink = ({ children }) => {
  return <div className="et-stop-shrink">{children}</div>
}

export default Flexbox
