import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { BasketContext } from './basket'
import offerApi from '../services/offer'
import routing from './../helpers/routing'
import { useHistory } from 'react-router-dom'
import { offerHelper } from './../helpers/offer'
import { EventCategoryCheckout } from '../analytics/categories'

const initialOfferValues = {
  gender: 'm',
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  comment: '',
  acceptTerms: false,
}

export const OfferContext = React.createContext()

export const OfferProvider = (props) => {
  const [offer, setOffer] = useState(initialOfferValues)
  const [snackbarMessageLabel, setSnackbarMessageLabel] = useState(null)
  const [successfullySend, setSuccessfullySend] = useState(false)
  const [isOfflineError, setIsOfflineError] = useState(false)
  const basketContext = React.useContext(BasketContext)
  const [errors, setErrors] = useState()
  const [pendings, setPendings] = useState(offerHelper.getPendings())
  const history = useHistory()

  const handleSendOffer = async (formValues) => {
    setOffer(formValues)
    setErrors()
    const result = await offerApi.send(formValues)
    const { data, errors } = result

    // offline error
    if (errors.isOfflineError) {
      
     ReactGA.event({
      category: EventCategoryCheckout,
      action: 'Send RfP form data (offline)',
      label: 'Send Rfp Now button (form)',
      })
      routing.goToPage({ ...props, history }, '/offline')
      offerHelper.savePendingOffer(formValues, errors.basketItems)
      basketContext.updateBasket()
      setPendings(offerHelper.getPendings())
      setIsOfflineError(true)
      setOffer(initialOfferValues)
    }
    // error response
    else if (
      errors &&
      errors.response &&
      errors.response.data &&
      errors.response.data.errors
    ) {
      setErrors(errors.response.data.errors)
    } else if (errors && data === null) {
      setSnackbarMessageLabel('alert.basketFormSendError.lead')
    }
    // response success
    if (data) {
      ReactGA.event({
        category: EventCategoryCheckout,
        action: 'Request proposal',
        label: 'DRfP button',
      })
      basketContext.handleDeleteBasket()
      setSuccessfullySend(true)
      setOffer(initialOfferValues)
    } else {
      setSuccessfullySend(false)
    }
    basketContext.updateBasket()

  }

  const handleSendPendingOffer = async (buyerInfo, basketItems) => {
    const result = await offerApi.sendPending(buyerInfo, basketItems)
    const { data, errors } = result

    // offline error
    if (errors.isOfflineError) {
      setIsOfflineError(true)
      setOffer(initialOfferValues)
    }
    // error response
    else if (errors && data === null) {
      setSnackbarMessageLabel('alert.basketFormSendError.lead')
    }

    //success response
    if (data) {
      const { buyerInfo, basketItems } = data
      handleDeletePending(buyerInfo, basketItems)
      setPendings(offerHelper.getPendings())
    }
    basketContext.updateBasket()

    ReactGA.event({
      category: EventCategoryCheckout,
      action: 'Send RfP form data (offline)',
      label: 'Send Rfp Now button (view)',
    })
  }

  const handleDeletePending = (buyerInfo, basketItems) => {
    offerHelper.deletePendingOffer(buyerInfo, basketItems)
  }

  return (
    <OfferContext.Provider
      value={{
        offer,
        handleSendOffer,
        handleSendPendingOffer,
        snackbarMessageLabel,
        setSnackbarMessageLabel,
        isOfflineError,
        setIsOfflineError,
        errors,
        successfullySend,
        setSuccessfullySend,
        pendings,
      }}
    >
      {props.children}
    </OfferContext.Provider>
  )
}
