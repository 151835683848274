import React from 'react'
import Box from '../containers/Box'
import Flexbox from '../containers/Flexbox'
import { MachineBladesStartListItem } from './ListItem'

export default function MachinemachinesStartList({
  machines,
  onMachineClick,
  ...props
}) {
  return (
    <Flexbox centered className="et-machine-blades-start-list-container">
      <Box ml="md" mr="md" mb="5xl" className="basic-layout">
        {machines &&
          machines.map((machine, index) => (
            <MachineBladesStartListItem
              onMachineClick={onMachineClick}
              onClick={() => {
                console.log('dasdas')
              }}
              key={index}
              machine={machine}
            />
          ))}
      </Box>
    </Flexbox>
  )
}
