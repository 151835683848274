import React from 'react'
import { BodyLargeFontType } from '../UI/Typo'
import v from '../../helpers/vocabulary'
import Box from '../containers/Box'
import Dashboard from '../dashboard'
import ContentContainer from './../containers/ContentContainer'
import MachineBladesStartList from './List'
import Headline from './../UI/Headline'
import HierarchialTransitionContainer from '../animations/HieararchialTransition'
import ComponentSpacing from '../containers/ComponentSpacing'

export default function BladesLayout({
  machines,
  onMachineClick,
  onGoBackClick
}) {
  return (
    <>
      <Dashboard
        onGoBackClick={onGoBackClick}
        loggedIn={true}
        title={v.translate('discfinder.stepMachineSelection.title')}
      />
      <HierarchialTransitionContainer>
        <ContentContainer className="et-machine-headline basic-layout">
          <ComponentSpacing pt="md" pb="lg">
            <Headline
              title={v.translate('discfinder.stepMachineSelection.title')}
            />
            <ComponentSpacing pt="md">
              <BodyLargeFontType noSpace>
                {v.translate('discfinder.stepMachineSelection.lead')}
              </BodyLargeFontType>
            </ComponentSpacing>
          </ComponentSpacing>
        </ContentContainer>
        <ContentContainer className="grey-lighten-97-background p-sm-0">
          <MachineBladesStartList
            onMachineClick={onMachineClick}
            machines={machines}
          />
        </ContentContainer>
      </HierarchialTransitionContainer>
    </>
  )
}
