import React from 'react'
import { H1HeadlineFontType } from './Typo'
import { ReactComponent as HeadlineDecoration } from '../../assets/images/other/h-1-headline-decoration.svg'

function Headline({ title, className }) {
  return (
    <div className={className}>
      <H1HeadlineFontType noSpace>{title}</H1HeadlineFontType>
      <HeadlineDecoration />
    </div>
  )
}

export default Headline
