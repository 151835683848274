import React from 'react'
import { Formik } from 'formik'
import InputField from '../UI/InputField'
import v from '../../helpers/vocabulary'
import * as Yup from 'yup'
import Button from '../UI/Button'
import Flexbox from '../containers/Flexbox'
import ComponentSpacing from '../containers/ComponentSpacing'
import { BodySmallFontType } from '../UI/Typo'
import Checkbox from './../UI/Checkbox'
import { SelectInput } from './../UI/inputs/select/index'
import { H2HeadlineFontType } from '../UI/Typo'

export default function OfferForm({ values, onSubmit, children, errors }) {
  return (
    <Formik
      initialValues={{ ...values }}
      onSubmit={(data) => onSubmit(data)}
      validationSchema={Yup.object().shape({
        gender: Yup.string().required(),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props

        const errorForKey = (key) => {
          if (!errors) return
          const error = errors.find(({ source }) => {
            if (source) return source.pointer.includes(key)
          })

          if (!(error && error.detail)) return
          return { messages: [error.detail] }
        }

        return (
          <form className="et-offer-form" onSubmit={handleSubmit}>
            <H2HeadlineFontType noSpace className="et-checkout-form-title">
              {v.translate('basket.form.sectionClientData.title')}
            </H2HeadlineFontType>
            <Flexbox column>
              <div className="et-input-field">
              <label>
                <span className="et-typo -dark-blue --body et-field-label">{v.translate(
                  'basket.form.sectionClientData.field.salutation'
                ) + ' *'}</span>
              </label>
              <SelectInput
                value={values.gender}
                className="gender-select"
                label={v.translate(
                  'basket.form.sectionClientData.field.salutation'
                )}
                name="gender"
                type="text"
                value={values.gender}
                onChangeEvent={handleChange}
                onBlur={handleBlur}
                required
              >
                <option
                  value="m"
                  key={'m'}
                  label={v.translate('mail.general.gender.m')}
                >{v.translate('mail.general.gender.m')}</option>
                <option
                  value="f"
                  key={'f'}
                  label={v.translate('mail.general.gender.f')}
                >{v.translate('mail.general.gender.f')}</option>
                )}
              </SelectInput>
              {/* adding div of heigh 26px, because other inputs have error space and select doesn't */}
              <div style={{height: '26px'}} />
              </div>
              <Flexbox className="col-sm">
                <InputField
                  name="firstname"
                  label={
                    v.translate(
                      'basket.form.sectionClientData.field.firstname'
                    ) + ' *'
                  }
                  value={values.firstname}
                  onChange={handleChange}
                  error={errorForKey('firstname')}
                  onBlur={handleBlur}
                  required
                />
                <InputField
                  name="lastname"
                  label={
                    v.translate(
                      'basket.form.sectionClientData.field.lastname'
                    ) + ' *'
                  }
                  value={values.lastname}
                  onChange={handleChange}
                  error={errorForKey('lastname')}
                  onBlur={handleBlur}
                  required
                />
              </Flexbox>
              <Flexbox className="col-sm">
                <InputField
                  label={
                    v.translate(
                      'basket.form.sectionClientData.field.email'
                    ) + ' *'
                  }
                  name="email"
                  type="email"
                  error={errorForKey('email')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <InputField
                  name="company"
                  label={
                    v.translate('basket.form.sectionClientData.field.company') +
                    ' *'
                  }
                  value={values.company}
                  onChange={handleChange}
                  error={errorForKey('company')}
                  onBlur={handleBlur}
                  required
                />
              </Flexbox>
              {children}
              <ComponentSpacing>
                <Checkbox
                  name="acceptTerms"
                  checked={values.acceptTerms}
                  label={v.translate('basket.form.sectionComments.privacy')}
                  checked={values.acceptTerms}
                  onChange={handleChange}
                  error={errorForKey('accept_terms')}
                  onBlur={handleBlur}
                  required
                />
              </ComponentSpacing>
              <Flexbox flexEnd>
                <ComponentSpacing className="send-btn-container" mt="xl">
                  <Button type="submit">
                    {v.translate('basket.form.cta.sendOffer')}
                  </Button>
                  <BodySmallFontType>
                    {v.translate('basket.form.cta.sendOffer.information')}
                  </BodySmallFontType>
                </ComponentSpacing>
              </Flexbox>
            </Flexbox>
          </form>
        )
      }}
    </Formik>
  )
}
