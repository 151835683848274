import React from 'react'
import Dashboard from './../dashboard/index'
import ContentContainer from './../containers/ContentContainer'
import v from '../../helpers/vocabulary'
import OfferForm from './Form'
import OfferSectionOverview from './SectionOverview'
import OfferSectionComments from './SectionComments'
import Headline from './../UI/Headline'
import Snackbar from '../UI/Snackbar'
import ComponentSpacing from '../containers/ComponentSpacing'

export default function OfferLayout({
  onGoBack,
  sendOffer,
  basketItems,
  formFields,
  comment,
  commentChanged,
  snackbarMessageLabel,
  onSnackbarClose,
  errors,
  ...props
}) {
  return (
    <>
      <Dashboard
        onGoBackClick={onGoBack}
        title={v.translate('basket.form.title')}
        loggedIn={true}
      />
      <ContentContainer>
        <ComponentSpacing pt="md">
          <Headline title={v.translate('basket.form.title')} />
        </ComponentSpacing>
        <ComponentSpacing mt="lg">
          <OfferForm values={formFields} onSubmit={sendOffer} errors={errors}>
            <OfferSectionOverview basketItems={basketItems} />
            <OfferSectionComments
              value={comment}
              handleChange={commentChanged}
              errors={errors}
            />
          </OfferForm>
        </ComponentSpacing>
      </ContentContainer>
      {snackbarMessageLabel && (
        <Snackbar
          duration={4000}
          onClose={onSnackbarClose}
          message={v.translate(snackbarMessageLabel)}
        />
      )}
    </>
  )
}
