import React, { useContext } from 'react'
import { PasswordResetContext } from '../../providers/passwordReset'
import PasswordResetLayout from './../../components/passwordReset/Layout'
import routing from './../../helpers/routing'

const PasswordReset = props => {
  const context = useContext(PasswordResetContext)
  const { email, successMessage, submitError } = context

  const handlePasswordResetAction = values => {
    context.passwordResetSubmit(values)
  }
  const handleResetErrors = () => {
    context.setSubmitError(null)
  }

  const handleGoToLoginPage = () => {
    context.setSubmitError(null)
    context.setSuccessMessage(null)
    routing.goToPage(props, '/login')
  }
  return (
    <PasswordResetLayout
      value={email}
      passwordResetSubmit={handlePasswordResetAction}
      resetErrors={handleResetErrors}
      errors={submitError}
      successMessage={successMessage}
      goToHomePage={() => routing.goToPage(props, '/')}
      goToLoginPage={handleGoToLoginPage}
    />
  )
}

export default PasswordReset
