import React from 'react'
import classNames from 'classnames'
import FieldLabel from './FieldLabel'
import FieldError from './FieldError'
import useAriaId from '../../services/hooks/useAriaId'
import { BodyFontType } from './Typo'

function Checkbox({ error, id, label, disabled = false, ...props }) {
  const ariaId = useAriaId(id)
  return (
    <div
      className={classNames('et-checkbox', {
        '-error': !!error,
        '-disabled': disabled
      })}
    >
      <label
        className={classNames('et-form-control -checkbox -with-text', {
          '-disabled': disabled
        })}
      >
        <input
          type="checkbox"
          disabled={disabled}
          aria-invalid={!!error}
          aria-describedby={ariaId}
          {...props}
        />
        <span className="control-indicator" />
        <BodyFontType type="span">{label}</BodyFontType>
      </label>
      {!!error && (
        <FieldError type="div" id={ariaId}>
          {error.messages[0]}
        </FieldError>
      )}
        {!error && (
        <FieldError type="div" id={ariaId}>
        </FieldError>
      )}
    </div>
  )
}

export default Checkbox
