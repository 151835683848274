import React, { useContext } from 'react'
import { LoginContext } from '../../providers/login'
import LoginLayout from './../../components/login/Layout'
import { metadataKey } from '../../constants/localStorageKeys'
import { registrationContactEmail } from '../../constants/registrationContactEmail'
import Loading from './../../components/UI/Loading'

const Login = () => {
  const context = useContext(LoginContext)

  const handleLoginAction = values => {
    context.loginSubmit(values)
  }

  const handleForgotPassClick = () => {
    context.forgotPass()
  }

  const registrationMailInfo = registrationContactEmail

  return (
    <>
      <LoginLayout
        registrationMailInfo={registrationMailInfo}
        values={context.loginData}
        isSubmitting={context.isSubmitting}
        loginSubmit={handleLoginAction}
        handleForgotPassClick={handleForgotPassClick}
        errors={context.loginError}
        successMessage={context.successMessage}
        resetErrors={context.resetLoginErrors}
        snackbarClosed={context.snackbarClosed}
      />
      {context.isSubmitting && <Loading />}
    </>
  )
}

export default Login
