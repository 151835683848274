export const EventCategoryHighInterestProducts = 'High interest products'
export const EventCategoryCheckout = 'Checkout'
export const OnBoardingCategory = "On-Boarding" 
export const HelpCategory = "Help" 
export const MachineNavigationCategory = "Machine Navigation" 
export const LanguageCategory = "Language"
export const AppInteractionCategory = "App Interaction"
export const DiscSearchCategory = "Disc Search"
export const CalculatorCategory = "Calculator"

