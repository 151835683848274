import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/logos/brunner-anliker-blue.svg'
import { ReactComponent as BackArrow } from '../../assets/images/arrows/arrow-move-left.svg'
import v from '../../helpers/vocabulary'
import { ReactComponent as User } from '../../assets/icons/user.svg'
import { ReactComponent as Basket } from '../../assets/icons/basket.svg'
import Icon from './Icon'
import ComponentSpacing from '../containers/ComponentSpacing'
import Flexbox from '../containers/Flexbox'
import NotificationNumber from './NotificationNumber'
import { UserOptionMenu } from './HeaderOptionMenu'
import { HeaderBackToLabelFontType } from './Typo'
import classNames from 'classnames'
import HeaderTitle from './HeaderTitle'

const userOption = 'user-option'
const basketOption = 'basket-option'

function AppHeader({
  loggedIn,
  backLabel,
  onGoBackClick,
  onBasketClick,
  onHomeClick,
  scrollSpeed,
  title,
  basketNotificationNum,
  userOptionProps,
  userNotificationNumber,
  basketOptionProps,
  ...props
}) {
  const [activeOption, setActiveOption] = useState(null)
  useEffect(() => {
    const headerClickListener = window.addEventListener('click', (e) => {
      if (!document.querySelector('.et-app-header')?.contains(e.target)) {
        setActiveOption(null)
      }
    })
    return () => window.removeEventListener('click', headerClickListener)
  }, [])

  const [shadowClass, setShadowClass] = useState('')
  const handleScroll = () => {
    const position = window.pageYOffset
    if (position > 30) {
      setShadowClass('shadow')
    } else {
      setShadowClass('')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
  }, [])

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return loggedIn ? (
    <>
      <header className={'et-app-header ' + shadowClass}>
        <ComponentSpacing ml="sm" className="app-logo-wrapper">
          {backLabel || onGoBackClick ? (
            <Flexbox alignItemsCenter>
              <Icon
                className="back-link"
                onClick={onGoBackClick}
                title={v.translate('dashboard.navigation.back')}
                className="back-arrow"
              >
                <BackArrow />
              </Icon>
              <div className="back-link-content"></div>
              {backLabel && (
                <HeaderBackToLabelFontType className="back-label">
                  {backLabel}
                </HeaderBackToLabelFontType>
              )}
            </Flexbox>
          ) : (
            <a
              className="logo-link"
              onClick={onHomeClick}
              title={v.translate('et.dashboard.title')}
            >
              <img className="logo" src={logo} alt="" />
            </a>
          )}{' '}
        </ComponentSpacing>
        <HeaderTitle title={title} showTitle={Boolean(shadowClass)} />
        <Flexbox centered alignItemsCenter>
          <ComponentSpacing pr="md">
            <Icon
              title={v.translate('dashboard.navigation.settings')}
              onClick={() =>
                setActiveOption(activeOption !== userOption ? userOption : null)
              }
              className={classNames(activeOption === userOption && '--active')}
            >
              <NotificationNumber red number={userNotificationNumber} />
              <User />
            </Icon>

            <UserOptionMenu
              show={activeOption === userOption}
              userNotificationNumber={userNotificationNumber}
              {...userOptionProps}
              className="user-option-menu"
            />
          </ComponentSpacing>
          <ComponentSpacing pr="md">
            <Icon
              title={v.translate('dashboard.navigation.basket')}
              className={classNames(activeOption === userOption && '--active')}
              onClick={() => {
                onBasketClick()
                setActiveOption(
                  activeOption !== basketOption ? basketOption : null
                )
              }}
            >
              <NotificationNumber blue number={basketNotificationNum} />
              <Basket />
            </Icon>
          </ComponentSpacing>
        </Flexbox>
      </header>
      <div id="header-background"></div>
    </>
  ) : null
}

export default AppHeader
