import React from 'react'
import classNames from 'classnames'
import Flexbox from '../containers/Flexbox'
import { FontType1 } from './Typo'

function Icon({
  children,
  className,
  grey = false,
  darkGrey = false,
  ultraDarkGrey = false,
  red = false,
  blue = false,
  white = false,
  darkBlue = false,
  green = false,
  mediumBlue = false,
  vividBlue = false,
  ...props
}) {
  return (
    <span
      className={classNames(
        'et-icon',
        className,
        { '-grey': grey },
        { '-dark-grey': darkGrey },
        { '-ultra-dark-grey': ultraDarkGrey },
        { '-red': red },
        { '-blue': blue },
        { '-white': white },
        { '-dark-blue': darkBlue },
        { '-green': green },
        { '-vivid-blue': vividBlue }
      )}
      {...props}
    >
      {children}
    </span>
  )
}

export function getIconComponent(IconInstance) {
  return props => (
    <Icon {...props}>
      <IconInstance />
    </Icon>
  )
}

export function IconLabel({
  label,
  iconElement,
  iconColor,
  className = '',
  children,
  ...props
}) {
  return <Flexbox {...props} alignItemsCenter className={classNames('et-icon-label', className)}>
    <Icon  {...iconColor}>{iconElement}</Icon>
    <div className="-label">{label}</div>
    {children}
  </Flexbox >
}

export function IconLabelLink({
  onClick,
  children,
  ...props
}) {
  return <a onClick={onClick} >
    <IconLabel {...props}>
    {children}</IconLabel>
  </a>
}

export default Icon
