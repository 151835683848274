import React from 'react'
import Flexbox from '../containers/Flexbox'
import v from '../../helpers/vocabulary'
import {
  H2HeadlineFontType,
  BodySmallFontType,
  BodyExtraBoldFontType,
  BodyFontType,
} from '../UI/Typo'
import ComponentSpacing from '../containers/ComponentSpacing'

export default function OfferSectionOverview({ basketItems }) {
  return (
    <Flexbox column className="et-offer-section-overview">
      <ComponentSpacing mt="xl">
        <H2HeadlineFontType>
          {v.translate('basket.form.sectionOverview.title')}
        </H2HeadlineFontType>
      </ComponentSpacing>
      <Flexbox spaceBetween className="section-overview-header">
        <BodySmallFontType>
          {v.translate('basket.form.sectionOverview.tableItem')}
        </BodySmallFontType>
        <BodySmallFontType>
          {v.translate('basket.form.sectionOverview.tableQuantity')}
        </BodySmallFontType>
      </Flexbox>
      {basketItems.map(
        (item) =>
          console.log(item) || (
            <Flexbox spaceBetween className="section-overview-item">
              <ComponentSpacing>
                <BodyExtraBoldFontType>
                  {item.blade_code ? `${v.translate(item.title)} ${item.blade_code}` : v.translate(item.title)}
                </BodyExtraBoldFontType>
                <BodySmallFontType>{item.desc}</BodySmallFontType>
              </ComponentSpacing>
              <ComponentSpacing mt="xs">
                <BodyFontType>{item.itemQuantity}</BodyFontType>
              </ComponentSpacing>
            </Flexbox>
          )
      )}
    </Flexbox>
  )
}
