import { basketKey } from '../constants/localStorageKeys'
import v from './vocabulary'
export const initialBasketData = {
  quantity: 0,
  items: [],
}

export const basketHelper = {
  /**
   * Equal method for basket items
   * @param {*} lsItem item from local storage, string representation
   * @param {*} item Js object representing item
   */
  lsItemEqualsItemObject: (lsItem, item) => {
    return (
      JSON.parse(lsItem).id == item.id && JSON.parse(lsItem).title == item.title
    )
  },
  /**Add new item to basket
   * If item exists in basket it is updated
   * Basket is saved to local storage with key 'basket'
   */
  addItem: (item) => {
    if (item.type === 'blades')
      item = {
        ...item,
        title: item.attributes.title,
        name: item.cut_type + ' ' + item.title,
      }
    if (item.isAccessorie)
      item = {
        ...item,
        title: item.titleId,
      }
    const newStorageItem = JSON.stringify(item)
    let lsData = localStorage.getItem(basketKey)
    if (lsData) {
      lsData = JSON.parse(lsData)
      const existingBasketItem = lsData.find((x) => {
        const xParsed = JSON.parse(x)
        return xParsed.id == item.id && xParsed.title == item.title
      })
      if (existingBasketItem) {
        const basketItemJson = JSON.parse(existingBasketItem)
        const replaceIndex = lsData.findIndex((x) => {
          const xParsed = JSON.parse(x)
          return xParsed.id == item.id && xParsed.title == item.title
        })
        lsData[replaceIndex] = JSON.stringify({
          ...item,
          itemQuantity: item.itemQuantity + basketItemJson.itemQuantity,
        })
      } else lsData.push(newStorageItem)
    } else {
      lsData = []
      lsData.push(newStorageItem)
    }
    localStorage.setItem(basketKey, JSON.stringify(lsData))
  },
  /**
   * Returns JSON object representing array of
   * basket items from local storage.
   * If there are no items method returns empty array.
   *
   */
  getItems: () => {
    const lsBasketItems = localStorage.getItem(basketKey)
    if (lsBasketItems) {
      const basketArray = JSON.parse(lsBasketItems)
      return basketArray.map((basketItem) => JSON.parse(basketItem))
    } else return []
  },
  /**
   * Updates basket item quantity in local storage
   * @param {*} id
   * @param {*} title
   * @param {*} quantity
   */
  itemQuantityChanged: (id, title, quantity) => {
    let lsBasketItems = localStorage.getItem(basketKey)
    lsBasketItems = JSON.parse(lsBasketItems)
    if (lsBasketItems && lsBasketItems.length) {
      const basketItemIndex = lsBasketItems.findIndex((x) => {
        const xParsed = JSON.parse(x)
        return xParsed.id == id && xParsed.title == title
      })
      if (basketItemIndex >= 0)
        lsBasketItems[basketItemIndex] = JSON.stringify({
          ...JSON.parse(lsBasketItems[basketItemIndex]),
          itemQuantity: quantity,
        })
      localStorage.setItem(basketKey, JSON.stringify(lsBasketItems))
    }
  },
  /**
   * Removes item from local storage basket
   */
  deleteItemFromBasket: (id, title) => {
    let lsBasketItems = localStorage.getItem(basketKey)
    lsBasketItems = JSON.parse(lsBasketItems)
    if (lsBasketItems && lsBasketItems.length) {
      lsBasketItems = lsBasketItems.filter((x) => {
        const xParsed = JSON.parse(x)
        return !(xParsed.id == id && xParsed.title == title)
      })

      localStorage.setItem(basketKey, JSON.stringify(lsBasketItems))
    }
  },
  /**
   * Deletes basket from local storage
   */
  deleteBasket: () => {
    localStorage.removeItem(basketKey)
  },
  /**
   * Returns total number of items in basket
   */
  getTotalItems: () => {
    let lsData = localStorage.getItem(basketKey)
    lsData = JSON.parse(lsData)
    let totalItems = 0
    if (lsData && lsData.length)
      lsData.forEach((item) => {
        const parsedItem = JSON.parse(item)
        totalItems += parsedItem.itemQuantity
      })
    return totalItems
  },
}
