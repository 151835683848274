import React, { useState } from 'react'
import Dashboard from './../dashboard/index'
import ContentContainer from './../containers/ContentContainer'
import Headline from '../UI/Headline'
import v from '../../helpers/vocabulary'
import ComponentSpacing from '../containers/ComponentSpacing'
import HelpSection from './Section'
import Flexbox from '../containers/Flexbox'
import { BodyLargeFontType } from '../UI/Typo'

export default function HelpLayout({ onGoBackClick, ...props }) {
  const productItems = [
    {
      label: 'help.section.products.q1.question',
      content: <div> {v.translate('help.section.products.q1.answer')}</div>,
    },
    {
      label: 'help.section.products.q2.question',
      content: <div> {v.translate('help.section.products.q2.answer')}</div>,
    },
    {
      label: 'help.section.products.q3.question',
      content: <div> {v.translate('help.section.products.q3.answer')}</div>,
    },
  ]
  const rfpItems = [
    {
      label: 'help.section.rfp.q1.question',
      content: <div> {v.translate('help.section.rfp.q1.answer')}</div>,
    },
    {
      label: 'help.section.rfp.q2.question',
      content: <div> {v.translate('help.section.rfp.q2.answer')}</div>,
    },
    {
      label: 'help.section.rfp.q3.question',
      content: <div> {v.translate('help.section.rfp.q3.answer')}</div>,
    },
    {
      label: 'help.section.rfp.q4.question',
      content: <div> {v.translate('help.section.rfp.q4.answer')}</div>,
    },
    {
      label: 'help.section.rfp.q5.question',
      content: <div> {v.translate('help.section.rfp.q5.answer')}</div>,
    },
  ]
  const paybackItems = [
    {
      label: 'help.section.payback.q1.question',
      content: <div> {v.translate('help.section.payback.q1.answer')}</div>,
    },
    {
      label: 'help.section.payback.q2.question',
      content: <div> {v.translate('help.section.payback.q2.answer')}</div>,
    },
    {
      label: 'help.section.payback.q3.question',
      content: <div> {v.translate('help.section.payback.q3.answer')}</div>,
    },
    {
      label: 'help.section.payback.q4.question',
      content: <div> {v.translate('help.section.payback.q4.answer')}</div>,
    },
  ]
  const discsearchItems = [
    {
      label: 'help.section.discsearch.q1.question',
      content: <div> {v.translate('help.section.discsearch.q1.answer')}</div>,
    },
  ]
  const generalItems = [
    {
      label: 'help.section.general.q1.question',
      content: <div> {v.translate('help.section.general.q1.answer')}</div>,
    },
    {
      label: 'help.section.general.q2.question',
      content: <div> {v.translate('help.section.general.q2.answer')}</div>,
    },
  ]
  return (
    <>
      <Dashboard
        loggedIn={true}
        title={v.translate('help.title')}
        onGoBackClick={onGoBackClick}
      />
      <ContentContainer>
        <ComponentSpacing pt="md">
          <Headline title={v.translate('help.title')} />
        </ComponentSpacing>
        <ComponentSpacing mt="lg">
          <BodyLargeFontType noSpace>
            {v.translate('help.lead')}
          </BodyLargeFontType>
        </ComponentSpacing>
        </ContentContainer>
        <ComponentSpacing pb="5xl">
          <HelpSection
            title={v.translate('help.section.products.title')}
            sectionItems={productItems}
          />
          <HelpSection
            title={v.translate('help.section.rfp.title')}
            sectionItems={rfpItems}
          />
          <HelpSection
            title={v.translate('help.section.payback.title')}
            sectionItems={paybackItems}
          />
          <HelpSection
            title={v.translate('help.section.discsearch.title')}
            sectionItems={discsearchItems}
          />
          <HelpSection
            title={v.translate('help.section.general.title')}
            sectionItems={generalItems}
          />
        </ComponentSpacing>
    </>
  )
}
