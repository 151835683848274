import React, { useEffect, useRef } from 'react'
import { Slide } from 'react-slideshow-image'
import Flexbox from '../containers/Flexbox'
import { AppInteractionCategory } from '../../analytics/categories'
import ReactGA from 'react-ga'



export default function Slideshow({ slideComponents, component, loopInfinite, ...rest }) {
  const slideRef = useRef(null)
  let xEnter, deltaX

  const properties = {
    transitionDuration: 500,
    autoplay: false,
    indicators: true,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      ReactGA.event({
        category: AppInteractionCategory,
        action: 'USP-slider swiped',
        label: component + ' from ' + oldIndex + ' to ' + newIndex,
      })
    }
  }

  useEffect(() => {
    const slidingElements = document.querySelectorAll('.each-slide')
    if (slidingElements) {
      slidingElements.forEach(se => {
        se.addEventListener('touchstart', handleDragStart, false)
        se.addEventListener('dragstart', handleDragStart, false)
        se.addEventListener('touchend', handleDragEnd, false)
        se.addEventListener('dragend', handleDragEnd, false)
      })
    }
    return () => {
      if (slidingElements) {
        slidingElements.forEach(se => {
          se.removeEventListener('touchstart', handleDragStart, false)
          se.removeEventListener('dragstart', handleDragStart, false)
          se.removeEventListener('touchend', handleDragEnd, false)
          se.removeEventListener('dragend', handleDragEnd, false)
        })
      }
    }
  }, [])

  const handleDragStart = event => {
    xEnter = event.pageX ? event.pageX : event.touches[0].clientX
  }
  const handleDragEnd = event => {
    deltaX =
      (event.pageX ? event.pageX : event.changedTouches[0].clientX) - xEnter
    if (Math.abs(deltaX) > 50)
      deltaX < 0 ? slideRef.current.goNext() : slideRef.current.goBack()
  }

  return (
    <div className="slide-container">
      <Slide {...properties} infinite={loopInfinite} ref={slideRef}>
        {slideComponents.map((slide, index) => (
          <Flexbox key={index} alignItemsCenter column className="each-slide">
            <div className="et-slide-container">{slide}</div>
          </Flexbox>
        ))}
      </Slide>
    </div>
  )
}
