import React, { useState } from 'react'
import Flexbox from '../containers/Flexbox'
import {
  ProductListSmallFontType,
  ProductDescriptionFontType,
} from '../UI/Typo'
import ComponentSpacing from './../containers/ComponentSpacing'
import { ReactComponent as Delete } from '../../assets/icons/delete.svg'
import QuantityPicker from './../UI/QuantityPicker'
import ConfirmDialog from './../UI/ConfirmDialog'
import v from '../../helpers/vocabulary'

export default function BasketListItem({
  onDeleteItemClick,
  imageSrc,
  desc,
  title,
  itemQuantity,
  quantityChanged,
  id,
  displayedLast,
  blade_code,
}) {
  const [quantity, setQuantity] = useState(itemQuantity)
  const [openedDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleQuantityChanged = (val) => {
    setQuantity(val)
    quantityChanged(id, title, val)
  }
  const handleDeleteClick = () => {
    setOpenDeleteDialog(!openedDeleteDialog)
  }

  return (
    <>
      <ComponentSpacing
        pt="sm"
        pb="sm"
        className={!displayedLast && 'border-bottom'}
      >
        <Flexbox spaceBetween className={'et-basket-list-item'}>
          <ComponentSpacing pr="xs" className="img-wrapper">
            <img src={imageSrc} />
          </ComponentSpacing>
          <Flexbox column className="basket-list-item-data">
            <ProductListSmallFontType noSpace>
              {v.translate(title)} {blade_code || ''}
            </ProductListSmallFontType>
            <ComponentSpacing mt="xxs">
              <ProductDescriptionFontType noSpace>
                {v.translate(desc)}
              </ProductDescriptionFontType>
            </ComponentSpacing>
            <QuantityPicker onChange={handleQuantityChanged} value={quantity} />
          </Flexbox>
          <Delete onClick={handleDeleteClick} />
        </Flexbox>
      </ComponentSpacing>
      <ConfirmDialog
        content={v.translate('basket.cta.clearBasketSingleItem', {
          articleToBeRemoved: v.translate(title),
        })}
        opened={openedDeleteDialog}
        onCancel={() => setOpenDeleteDialog(false)}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={() => onDeleteItemClick(id, title)}
        title={v.translate('basket.cta.clearBasket')}
      />
    </>
  )
}
