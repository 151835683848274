import React from 'react'
import { ProductProvider } from './providers/products'
import { LoginProvider } from './providers/login'
import { PasswordResetProvider } from './providers/passwordReset'
import { DashboardProvider } from './providers/dashboard'
import { BasketProvider } from './providers/basket'
import { OfferProvider } from './providers/offer'
import { MetadataProvider } from './providers/metadata'

const Store = ({ children }) => {
  return (
    <MetadataProvider>
      <LoginProvider>
        <DashboardProvider>
          <BasketProvider>
            <OfferProvider>
              <PasswordResetProvider>
                <ProductProvider>{children}</ProductProvider>
              </PasswordResetProvider>
            </OfferProvider>
          </BasketProvider>
        </DashboardProvider>
      </LoginProvider>
    </MetadataProvider>
  )
}

export default Store
