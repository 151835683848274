export const tokenKey = 'token'
export const machinesKey = 'machines'
export const bladesKey = 'blades'
export const accessoriesKey = 'accessories'
export const metadataKey = 'metadata'
export const vocabularyKey = 'vocabulary'
export const basketKey = 'basket'
export const offerKey = 'offers'
export const userKey = 'user'
export const currencyKey = 'currency'
