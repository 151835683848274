import { offerKey, basketKey } from './../constants/localStorageKeys'

export const offerHelper = {
  /**
   * Saves offer to local storage.
   * Gets called if user is offline.
   */
  savePendingOffer: (buyerInfo, basketItems) => {
    let lsOffers = JSON.parse(localStorage.getItem(offerKey))
    const newOffer = {
      buyerInfo,
      basketItems,
      date: new Date()
    }
    if (lsOffers && lsOffers.length) {
      lsOffers.push(newOffer)
    } else lsOffers = [newOffer]
    localStorage.setItem(offerKey, JSON.stringify(lsOffers))
    localStorage.removeItem(basketKey)
  },
  /**
   * Returns pending offers as object array.
   * If there are no pending offers empty array is returned.
   */
  getPendings: () => {
    const lsPendings = localStorage.getItem(offerKey)
    if (lsPendings) {
      const pendingsArray = JSON.parse(lsPendings)
      return pendingsArray
    } else return []
  },
  /**
   * Removes successfully send offer from local storage
   * pending offer.
   */
  deletePendingOffer: (buyerInfo, basketItems) => {
    const lsPendings = localStorage.getItem(offerKey)
    let updatedPendings = []
    if (lsPendings) {
      const pendingsArray = JSON.parse(lsPendings)
      for (let prop in pendingsArray) {
        const pending = pendingsArray[prop]
        const bi = pending.buyerInfo
        const i = pending.basketItems

        const buyerInfoEquals =
          bi.gender == buyerInfo.gender &&
          bi.firstname == buyerInfo.firstname &&
          bi.lastname == buyerInfo.lastname &&
          bi.email == buyerInfo.email &&
          bi.company == buyerInfo.company &&
          bi.comment == buyerInfo.comment

        let basketItemsEquals = true
        for (var cnt = 0; cnt < i.length; cnt++) {
          if (i[cnt].article_nr != basketItems[cnt].article_nr) {
            basketItemsEquals = false
            break
          }
        }
        if (!(buyerInfoEquals && basketItemsEquals))
          updatedPendings.push(pending)
      }

      localStorage.setItem(offerKey, JSON.stringify(updatedPendings))
    }
  }
}
