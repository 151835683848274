import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import v from '../../../helpers/vocabulary'
import ConfirmDialog from './../../UI/ConfirmDialog'
import routing from './../../../helpers/routing'
import { offerHelper } from './../../../helpers/offer'
import { useHistory } from 'react-router-dom'
import { prevPathKey } from './../../../constants/sessionStorageKeys'
import { EventCategoryCheckout } from '../../../analytics/categories'

export default function OfferDialog({ prevPath, location, ...props }) {
  const [opened, setOpened] = useState(true)
  const history = useHistory()
  useEffect(() => {
    const loadListener = window.addEventListener('load', () => {
      if (offerHelper.getPendings().length > 0) setOpened(true)
    })
    return () => window.removeEventListener('load', loadListener)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem(prevPathKey) === '/onboarding') {
      setOpened(true)
      sessionStorage.setItem(prevPathKey, null)
    }
  }, [location])

  return (
    <ConfirmDialog
      opened={
        opened &&
        location.pathname !== '/onboarding' &&
        location.pathname !== '/pending-sendouts' &&
        offerHelper.getPendings().length &&
        window.navigator?.onLine
      }
      title={v.translate('alert.pendingSendouts.title')}
      content={v.translate('alert.pendingSendouts.lead')}
      confirmTitle={v.translate('alert.pendingSendouts.primaryCta')}
      cancelTitle={v.translate('alert.pendingSendouts.secondaryCta')}
      onConfirm={() => {
        routing.goToPage({ history, ...props }, '/pending-sendouts')

        ReactGA.event({
          category: EventCategoryCheckout,
          action: 'Send RfP form data (offline)',
          label: 'Send Rfp Now button (alert)',
        })
      }}
      onClose={() => setOpened(false)}
    />
  )
}
