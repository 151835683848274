import React, { useState, useContext } from 'react'
import { H2HeadlineFontType } from '../../UI/Typo'
import ComponentSpacing from '../../containers/ComponentSpacing'
import { HorizontalSeparator } from '../../UI/separator/horizontal'
import {
  PaybackCalculatorResultElement,
  PaybackCalculatorProcessingCostConfigurableElement,
} from '../UI'
import { ETModal } from '../../modal'
import { PaybackCalculatorConvenienceConfigureConvenience } from './configureConvenience'
import { PaybackCalculatorConfigureMachine } from '../common/configureMachine'
import { PaybackCalculatorConvenienceConfigurePayback } from './configurePayback'
import { formatCurrency, formatYears } from '../../../helpers/formatter'
import { CalculatorContext } from '../../../providers/calculator'
import v from '../../../helpers/vocabulary'
import ReactGA from 'react-ga'
import { CalculatorCategory } from '../../../analytics/categories'

export function PaybackCalculatorConvenience() {
  const {
    selectedMachineTitle,
    amountPerCharge,
    convenienceTotalProcessingCosts,
    machinePrice,
    convenienceAmortizationMachinePriceYears,
    productTotal,
    convenienceSavings,
  } = useContext(CalculatorContext)

  const processingCosts = [
    {
      name: v.translate(
        'calculator.start.sectionSavings.processingCostConvenience'
      ),
      value: convenienceTotalProcessingCosts,
      renderValue: formatCurrency,
      onClick: () => {
        ReactGA.event({
          category: CalculatorCategory,
          action: 'Change calculator setting',
          label: 'Setting Comparison button (convinience selected)',
        })
        setShowConfigureConvenienceModal(true)
      },
    },
    {
      name: selectedMachineTitle,
      value: productTotal,
      renderValue: formatCurrency,
      onClick: () => {
        ReactGA.event({
          category: CalculatorCategory,
          action: 'Change calculator setting',
          label: 'Setting Machine button (convinience selected)',
        })
        setShowConfigureMachineModal(true)
      },
    },
  ]

  const [
    showConfigureConvenienceModal,
    setShowConfigureConvenienceModal,
  ] = useState(false)

  const [showConfigureMachineModal, setShowConfigureMachineModal] = useState(
    false
  )

  const [showConfigurePaybackModal, setShowConfigurePaybackModal] = useState(
    false
  )

  return (
    <>
      <ETModal visible={showConfigureConvenienceModal}>
        <PaybackCalculatorConvenienceConfigureConvenience
          onClose={() => setShowConfigureConvenienceModal(false)}
        />
      </ETModal>
      <ETModal visible={showConfigureMachineModal}>
        <PaybackCalculatorConfigureMachine
          onClose={() => setShowConfigureMachineModal(false)}
        />
      </ETModal>
      <ETModal visible={showConfigurePaybackModal}>
        <PaybackCalculatorConvenienceConfigurePayback
          onClose={() => setShowConfigurePaybackModal(false)}
        />
      </ETModal>
      <H2HeadlineFontType noSpace>
        {v.translate('calculator.start.sectionSavings.title')}
      </H2HeadlineFontType>
      {processingCosts.map(PaybackCalculatorProcessingCostConfigurableElement)}
      <ComponentSpacing pt="sm" pb="sm">
        <HorizontalSeparator />
      </ComponentSpacing>
      <PaybackCalculatorResultElement
        name={v.translate(
          'calculator.start.sectionSavings.savingsByBatchQuantity',
          { batchQuantity: amountPerCharge }
        )}
        value={convenienceSavings}
        renderValue={formatCurrency}
        minusValueLabel={'calculator.payback.minusValueSavingsMessage'}
      />
      <ComponentSpacing pt="3xl" />
      <H2HeadlineFontType noSpace>
        {v.translate('calculator.start.sectionPayback.title')}
      </H2HeadlineFontType>
      <PaybackCalculatorProcessingCostConfigurableElement
        name={v.translate('calculator.start.sectionPayback.costOfMachine')}
        value={formatCurrency(machinePrice)}
        onClick={() => {
          ReactGA.event({
            category: CalculatorCategory,
            action: 'Change calculator setting',
            label: 'Setting Payback button (convinience selected)',
          })
          setShowConfigurePaybackModal(true)
        }}
      />
      <ComponentSpacing pt="sm" pb="sm">
        <HorizontalSeparator />
      </ComponentSpacing>
      <PaybackCalculatorResultElement
        name={v.translate('calculator.start.sectionPayback.paybackInYears')}
        value={convenienceAmortizationMachinePriceYears}
        renderValue={formatYears}
        minusValueLabel={'calculator.payback.minusValueAmortisationMessage'}
      />
    </>
  )
}
