import React from 'react'
import HelpLayout from '../../components/help/Layout'
import routing from './../../helpers/routing'

export default function Help({ ...props }) {
  const handleClickBack = () => {
    routing.goBack(props)
  }
  return <HelpLayout onGoBackClick={handleClickBack} />
}
