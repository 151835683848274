import React, { useContext, useEffect, useState } from 'react'
import AppHeader from '../UI/AppHeader'
import { DashboardContext } from '../../providers/dashboard'
import { BasketContext } from './../../providers/basket'
import checkScrollSpeed from '../../helpers/window'

export default function Dashboard({ loggedIn, onGoBackClick, ...props }) {
  const context = useContext(DashboardContext)
  const basketContext = useContext(BasketContext)
  const [scrollSpeed, setScrollSpeed] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [loggedIn])

  const handleScroll = () => {
    setScrollSpeed(checkScrollSpeed())
  }
  return (
    <AppHeader
      basketNotificationNum={basketContext.basketItemsNum}
      userNotificationNumber={context.userNotificationNumber}
      userOptionProps={context.userMenuOptionClickHandlers}
      onBasketClick={context.onBasketClick}
      scrollSpeed={scrollSpeed}
      onGoBackClick={onGoBackClick}
      loggedIn={loggedIn}
      {...props}
    />
  )
}
