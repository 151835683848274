import React, { useContext } from 'react'
import {
  HeaderBackToLabelFontType,
  BodyExtraBoldFontType
} from '../../../UI/Typo'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import { ReactComponent as CloseImg } from '../../../../assets/icons/close-calc.svg'
import Flexbox from '../../../containers/Flexbox'
import { HorizontalSeparator } from '../../../UI/separator/horizontal'
import { PaybackCalculatorResultElement } from '../../UI'
import {
  formatKg,
  formatCurrency,
  formatYears
} from '../../../../helpers/formatter'
import { withMediumSpace } from '../../../../helpers/spacing'
import { CalculatorContext } from '../../../../providers/calculator'
import { Cost } from '../../UI/cost'
import v from '../../../../helpers/vocabulary'

export function PaybackCalculatorConvenienceConfigurePayback({ onClose }) {
  const {
    selectedMachine,
    amountPerCharge,
    setAmountPerCharge,
    machinePrice,
    setMachinePrice,
    chargePerDay,
    setChargePerDay,
    calculatedYearlyTotalKg,
    convenienceAmortizationMachinePriceYears,
  } = useContext(CalculatorContext)

  const costs = [
    {
      name: v.translate('calculator.payback.costOfMachine'),
      value: machinePrice,
      renderValue: formatCurrency,
      onChange: setMachinePrice,
      min: selectedMachine.attributes.roi_data.machine_price.range_min,
      max: selectedMachine.attributes.roi_data.machine_price.range_max,
      step: selectedMachine.attributes.roi_data.machine_price.steps
    },
    {
      name: v.translate('calculator.savings.tabMaterial.batchQuantity'),
      value: amountPerCharge,
      renderValue: formatKg,
      onChange: setAmountPerCharge,
      min: selectedMachine.attributes.roi_data.amount_per_charge.range_min,
      max: selectedMachine.attributes.roi_data.amount_per_charge.range_max,
      step: selectedMachine.attributes.roi_data.amount_per_charge.steps
    },
    {
      name: v.translate('calculator.payback.numberOfBatchesPerDay'),
      value: chargePerDay,
      onChange: setChargePerDay,
      min: selectedMachine.attributes.roi_data.charge_per_day.range_min,
      max: selectedMachine.attributes.roi_data.charge_per_day.range_max,
      step: selectedMachine.attributes.roi_data.charge_per_day.steps
    }
  ]

  const results = [
    {
      name: v.translate('calculator.payback.summary.calculatedYearlyTotalKg'),
      value: calculatedYearlyTotalKg,
      renderValue: formatKg
    },
    {
      name: v.translate('calculator.payback.summary.amortisation'),
      value: convenienceAmortizationMachinePriceYears,
      renderValue: formatYears
    }
  ]
  return (
    <>
      <ComponentSpacing
        pt="sm"
        pb="sm"
        pl="md"
        pr="md"
        className="vivid-blue-background et-payback-modal-header-start"
      >
        <div className="et-payback-calculator-modal-header-title">
          <HeaderBackToLabelFontType noSpace>
            {v.translate('calculator.payback.title', {
              handcutOrConvenience: v.translate(
                'calculator.start.sectionSavings.processingCostConvenience'
              )
            })}
          </HeaderBackToLabelFontType>
          <CloseImg
            className="et-payback-calculator-modal-header-title-container-close-button"
            onClick={onClose}
          />
        </div>
      </ComponentSpacing>
      <ComponentSpacing
        pt="xs"
        pl="lg"
        pr="lg"
        pb="sm"
        className="vivid-blue-background et-payback-modal-header-middle"
      >
        <Flexbox spaceBetween className="basic-layout">
          <BodyExtraBoldFontType noSpace>
            {v.translate('calculator.payback.subtitle')}
          </BodyExtraBoldFontType>
          <BodyExtraBoldFontType noSpace>
            {formatYears(convenienceAmortizationMachinePriceYears)}
          </BodyExtraBoldFontType>
        </Flexbox>
      </ComponentSpacing>
      <ComponentSpacing pt="3xl" pb="3xl" pl="lg" pr="lg" className="basic-layout  et-payback-modal-body-small-margin">
        {costs.map(Cost).reduce(withMediumSpace)}
        <ComponentSpacing pt="xl" pb="xs">
          <HorizontalSeparator />
        </ComponentSpacing>
        {results.map(PaybackCalculatorResultElement)}
      </ComponentSpacing>
    </>
  )
}
