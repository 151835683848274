import React from 'react'
import Box from '../containers/Box'
import { BladeSearchListItem } from './ListItem'
import Flexbox from '../containers/Flexbox'

export default function BladeSearchList({
  blades,
  onAddItem,
  onDetailsClick,
  ...props
}) {
  return (
    <Flexbox centered>
      <Box ml="md" mr="md" className="et-blade-list-container">
        {blades &&
          blades.map((blade, index) => (
            <BladeSearchListItem
              onAddItem={onAddItem}
              onDetailsClick={onDetailsClick}
              key={index}
              blade={blade}
            />
          ))}
      </Box>
    </Flexbox>
  )
}
