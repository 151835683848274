import React from 'react'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'
import { ReactComponent as Minus } from '../../assets/icons/minus.svg'
import InputField from './InputField'
import Icon from './Icon'
import Flexbox from '../containers/Flexbox'

const minValue = 1

export default function QuantityPicker({ value, ref, onChange, ...props }) {
  return (
    <Flexbox {...props} alignItemsCenter className="et-quantity-picker">
      <Icon
        className={value == minValue && '-disabled'}
        onClick={() => value != minValue && onChange(value - 1)}
      >
        <Minus />
      </Icon>
      <InputField ref={ref} value={value} />
      <Icon onClick={() => onChange(value + 1)}>
        <Plus />
      </Icon>
    </Flexbox>
  )
}
