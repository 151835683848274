import React from 'react'
import CutTypeImageGallery from './CutTypeImageGallery'
import { BodyLargeFontType, H2HeadlineFontType } from '../UI/Typo'
import v from '../../helpers/vocabulary'
import ComponentSpacing from '../containers/ComponentSpacing'
import Featured from '../UI/products/featured/Featured'
import Slideshow from '../UI/SlideShow'
import Dashboard from './../dashboard/index'
import ContentContainer from '../containers/ContentContainer'
import BladeVariantList from './List'
import HierarchialTransitionContainer from './../animations/HieararchialTransition'
import SnackbarPage from '../UI/SnackbarPage'

export default function BladeDetailsLayout({
  selectedBlade,
  bladeVariants,
  onAddItem,
  cutType,
  machine,
  onGoBackClick,
  addedBladeLabel,
  onSnackbarClosed,
}) {
  let featuredSlides = []
  const usps = machine.attributes.usps
  Object.keys(usps).forEach((key) => {
    featuredSlides.push(<Featured {...usps[key]} />)
  })
  return (
    <SnackbarPage
      snackbarProps={{
        duration: 3000,
        message:
          addedBladeLabel &&
          v.translate('snackbar.productAdded', {
            productName: addedBladeLabel,
          }),
        onClose: onSnackbarClosed,
      }}
    >
      <Dashboard
        onGoBackClick={onGoBackClick}
        title={`${v.translate(cutType.name)} ( ${cutType.family_code} )`}
        loggedIn={true}
      />
      <HierarchialTransitionContainer>
        <CutTypeImageGallery
          title={`${v.translate(cutType.name)} ( ${cutType.family_code} )`}
          images={cutType.images}
          discImg={selectedBlade.attributes.blade_image.src}
        />
        <ContentContainer className="et-blades-content-container basic-layout">
          <ComponentSpacing pt="md">
            <BodyLargeFontType noSpace>
              {v.translate(selectedBlade.attributes.short_description)}
            </BodyLargeFontType>
          </ComponentSpacing>
          <ComponentSpacing mt="xxl" mb="sm">
            <H2HeadlineFontType>
              {v.translate('products.detail.sectionVariations.title')}
            </H2HeadlineFontType>
          </ComponentSpacing>
          <BladeVariantList onAddItem={onAddItem} blades={bladeVariants} />
          <Slideshow slideComponents={featuredSlides} component="Disc Detail"/>
          <ComponentSpacing pt="5xl" />
        </ContentContainer>
      </HierarchialTransitionContainer>
    </SnackbarPage>
  )
}