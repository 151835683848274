import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../../providers/products'
import BladeDetailsLayout from '../../components/bladeDetails/Layout'
import routing from './../../helpers/routing'
import { BasketContext } from './../../providers/basket'
import Loading from './../../components/UI/Loading'
import machineHelper from './../../helpers/machine'

export function BladeDetails({ ...props }) {
  const context = useContext(ProductContext)
  const basketContext = useContext(BasketContext)
  const [addedBlade, setAddedBlade] = useState(null)
  let location = window.location.href.split('/')
  const bladeId = location.pop()

  useEffect(() => {
    context.initBladeDetailsDataByBladeId(bladeId)
  }, [])
  const machine = machineHelper.getMachineById(location.pop())

  const handleAddBlade = (blade) => {
    basketContext.handleAddBlade(blade)
    setAddedBlade(blade.title)
  }

  return context.bladeDetailsData.selectedBlade.id == bladeId ? (
    <BladeDetailsLayout
      machine={machine}
      onGoBackClick={() => routing.goBack(props)}
      {...context.bladeDetailsData}
      onAddItem={handleAddBlade}
      basketNotificationNum={basketContext.basketItemsNum}
      addedBladeLabel={addedBlade}
      onSnackbarClosed={() => setAddedBlade(addedBlade)}
    />
  ) : (
    <Loading />
  )
}
