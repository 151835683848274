const api = '/api'
export const apiVersion = '/v1'
export const auth = api + '/auth'

export const loginApi = auth + '/login'
export const logoutApi = auth + '/logout'
export const passwordResetApi = auth + '/password-reset'

export const ordersApi = api + apiVersion + '/orders'

export const metadata = api + apiVersion + '/metadata'
export const vocabulary = api + apiVersion + '/vocabulary'

export const products = api + apiVersion + '/products'
export const machines = products + '/machines?sort=sort_order&'
export const blades = products + '/blades'
export const accessories = products + '/accessories'

export const order = api + apiVersion + '/order'
