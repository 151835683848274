import React from 'react'
import Box from '../containers/Box'
import Flexbox from '../containers/Flexbox'
import AddToCart from './../UI/order/addToCart/AddToCart'
import ComponentSpacing from '../containers/ComponentSpacing'

export default function BladeVariantList({ onAddItem, blades }) {
  return (
    <Flexbox centered spaceBetween>
      <Box mt="sm" mb="xxl" className="et-blade-variant-list-container">
        {blades &&
          blades.map((blade, index) => (
            <ComponentSpacing key={index} className="et-blade-variant-item">
              <AddToCart
                addToBasket={onAddItem}
                key={index}
                id={blade.id}
                title={blade.attributes.blade_code}
                desc={`${blade.attributes.thickness} mm, ${blade.output} kg/h, ${blade.attributes.diameter} mm`}
              />
            </ComponentSpacing>
          ))}
      </Box>
    </Flexbox>
  )
}
