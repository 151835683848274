import React from 'react'
import classNames from 'classnames'

export default function Textlink({ label, className, ...props }) {
  return (
    <p className={classNames(className, 'et-textlink')} {...props}>
      {label}
    </p>
  )
}
