import React, { useEffect, useState } from 'react'
import OfferPendingsLayout from './../../components/offerPendings/Layout'
import routing from './../../helpers/routing'
import { offerHelper } from './../../helpers/offer'
import { OfferContext } from './../../providers/offer'
import { OfferSuccess } from '../../components/offer/success'

export default function OfferPendings({ ...props }) {
  const offerContext = React.useContext(OfferContext)
  let { pendings, isOfflineError, snackbarMessageLabel } = offerContext
  const [internetConnection, setInternetConnection] = useState(
    window.navigator.onLine
  )

  useEffect(() => {
    const offlineListener = window.addEventListener('offline', (e) =>
      setInternetConnection(false)
    )

    const onlineListener = window.addEventListener('online', (e) =>
      setInternetConnection(true)
    )

    return () => {
      window.removeEventListener('online', onlineListener)
      window.removeEventListener('offline', offlineListener)
    }
  }, [])

  useEffect(() => {
    if (
      offerContext.isOfflineError &&
      routing.getLastElementFromURL() === 'pending-sendouts'
    ) {
      offerContext.setIsOfflineError(false)
      routing.goToPage(props, '/offline')
    }
  }, [isOfflineError])

  const handleSendAll = () => {
    const pendings = offerHelper.getPendings()
    pendings.forEach((pending) => {
      offerContext.handleSendPendingOffer(
        pending.buyerInfo,
        pending.basketItems
      )
    })
  }

  const handleSnackbarClosed = () => {
    offerContext.setSnackbarMessageLabel(null)
  }

  const handleGoBack = () => routing.goBack(props)

  const pendingsList = pendings.map((p) => {
    const bi = p.buyerInfo
    return {
      title: `${bi.gender} ${bi.firstname} ${bi.lastname}`,
      company: bi.company,
      ...p,
    }
  })

  return pendings.length ? (
    <OfferPendingsLayout
      snackbarMessageLabel={snackbarMessageLabel}
      snackbarClosed={handleSnackbarClosed}
      onGoBackClick={handleGoBack}
      pendings={pendingsList}
      onSendAllClick={handleSendAll}
      internetConnection={internetConnection}
    />
  ) : (
    <OfferSuccess
      onGoBackClick={handleGoBack}
      leadTitle={'pendingSendouts.emptyStateLead'}
    />
  )
}
