import React from 'react'
import Flexbox from '../containers/Flexbox'
import {
  BodyExtraBoldFontType,
  ProductListSmallFontType,
  BodyFontType,
} from '../UI/Typo'
import { BodySmallFontType } from './../UI/Typo'
import ComponentSpacing from '../containers/ComponentSpacing'
import moment from 'moment'
import { appLanguage } from './../../constants/authentication'
import Box from './../containers/Box'
moment.locale(localStorage.getItem(appLanguage))
export default function OfferPendingsListItem({ offer }) {
  const { title, company, date } = offer

  return (
    <Box>
      <Flexbox column spaceBetween className={'et-pendings-list-item'}>
        <Flexbox column className="title-company">
          <ProductListSmallFontType noSpace>{title}</ProductListSmallFontType>
          <ComponentSpacing mt="xxs">
            <BodyFontType noSpace>{company}</BodyFontType>
          </ComponentSpacing>
        </Flexbox>
        <ComponentSpacing column className="date-column">
          <BodySmallFontType>
            {moment(date).format('DD.MM.YYYY')}
          </BodySmallFontType>
          <ComponentSpacing className="pending-date">
            <BodySmallFontType>
              {moment(date).format('hh:mm')}
            </BodySmallFontType>
          </ComponentSpacing>
        </ComponentSpacing>
      </Flexbox>
    </Box>
  )
}
