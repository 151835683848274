import React from 'react'
import v from '../../helpers/vocabulary'
import logo from '../../assets/images/logos/brunner-anliker-blue.svg'
import PasswordResetForm from './Form'
import Snackbar from '../UI/Snackbar'
import Headline from './../UI/Headline'
import Flexbox from '../containers/Flexbox'
import ComponentSpacing from './../containers/ComponentSpacing'

const PasswordResetLayout = ({
  passwordResetSubmit,
  errors,
  successMessage,
  resetErrors,
  goToHomePage,
  goToLoginPage
}) => {
  return (
    <>
      {successMessage && (
        <Snackbar
          duration={4000}
          onClose={goToLoginPage}
          message={v.translate(successMessage)}
        />
      )}
      <div className="et-auth-layout">
        <Flexbox centered className="logo">
          <img src={logo} onClick={goToHomePage} />
        </Flexbox>
        <Headline title={v.translate('forgotpassword.title')} />
        <ComponentSpacing mt="lg">
          <PasswordResetForm
            errors={errors}
            resetErrors={resetErrors}
            submitAction={passwordResetSubmit}
          />
        </ComponentSpacing>
      </div>
    </>
  )
}

export default PasswordResetLayout
