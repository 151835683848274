import React, { useState, useEffect } from 'react'
import productApi from '../services/products'
import metadataApi from '../services/metadata'
import { machinesKey, metadataKey, bladesKey } from '../constants/localStorageKeys'
import { initBladeDetailsData } from '../sites/bladeDetails/dataHelper'
import ls from '../helpers/localStorage'
import routing from './../helpers/routing'

export const ProductContext = React.createContext()

export const ProductProvider = (props) => {
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState()
  const [machinesForRegion, setMachinesForRegion] = useState(machines)
  const [blades, setBlades] = useState([])
  const [bladeDetailsData, setBladeDetailsData] = useState(initBladeDetailsData)
  const [accessories, setAccessories] = useState([])

  const getMachines = async () => {
    const result = await productApi.getMachines()
    setMachines(result.data)
  }
  const getMachinesFromCache = async () => {
    const result = JSON.parse(localStorage.getItem(machinesKey))
    if(result && result.data.length > 0)
      setMachines(result.data)
    else
      getMachines()
  }
  

  useEffect(() => {
    if (machines.length === 0) return
    const region = ls.getUserGlobalRegion()
    setMachinesForRegion(
      machines.filter(({ attributes }) =>
        attributes.available_regions.includes(region)
      )
    )
  }, [machines])

  const getMachine = (id) => {
    const result =
      localStorage.getItem(machinesKey) &&
      JSON.parse(localStorage.getItem(machinesKey))
    let machine = result?.data.find((element) => element.id == id)
    setMachine(machine)
  }

  const getBlades = async () => {
    const result = await productApi.getBlades()
    setBlades(result.data)
  }

  const initBladeDetailsDataByBladeId = async (bladeId) => {
    let blades = await getBladesByMachineId(routing.getSecondFromLastFromURL())
    const cutTypeId = blades.find((blade) => blade.id == bladeId).attributes
      .cut_type

    const metadata = JSON.parse(localStorage.getItem(metadataKey));
    var cutTypes = [];
    // if we have metadata in localstorage use that, don't fetch it
    if(metadata) {
      cutTypes = metadata.data.attributes.cut_types
    } 
    else {
      cutTypes = (await metadataApi.getMetadata()).data.attributes.cut_types
    }

    let bladeVariants = []
    blades.forEach((blade) => {
      if (blade && blade.attributes && blade.attributes.cut_type === cutTypeId) bladeVariants.push(blade)
    })

    const selectedBlade = blades.find((blade) => blade.id == bladeId)
    let cutType
    for (let [key, value] of Object.entries(cutTypes)) {
      if (key == cutTypeId) {
        cutType = value
        break
      }
    }

    setBladeDetailsData({
      bladeVariants,
      selectedBlade,
      cutType,
    })
  }

  const getBladesByMachineId = async (machineId) => {
    let machines = JSON.parse(localStorage.getItem(machinesKey));
    let blades = JSON.parse(localStorage.getItem(bladesKey));
    if (!machines) machines = (await productApi.getMachines())
    if (!blades) blades = (await productApi.getBlades())
    const machine = machines.data.find((machine) => +machine.id === +machineId)
    const machineBlades = machine.attributes.blades
    let applicableBlades = []
    for (let [key, value] of Object.entries(machineBlades)) {
      let blade = blades.data.find((blade) => blade.id === key)
      applicableBlades.push({ ...blade, ...value })
    }
    setBlades(applicableBlades)
    return applicableBlades
  }

  const getAccessories = async () => {
    const result = await productApi.getAccessories()
    setAccessories(result.data)
  }

  return (
    <ProductContext.Provider
      value={{
        getMachines,
        machines: machinesForRegion,
        setMachines,
        getMachine,
        machine,
        setMachine,
        getBlades,
        blades,
        getAccessories,
        accessories,
        setMachines,
        getBladesByMachineId,
        initBladeDetailsDataByBladeId,
        bladeDetailsData,
        getMachinesFromCache
      }}
    >
      {props.children}
    </ProductContext.Provider>
  )
}
