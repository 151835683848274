import React from 'react'
import ContentContainer from '../containers/ContentContainer'
import Dashboard from '../dashboard/index'
import routing from '../../helpers/routing'
import Icon from '../UI/Icon'
import { ReactComponent as OfflineIcon } from '../../assets/icons/offline-mode.svg'
import { BodyFontType, BodyLargeExtraBoldFontType } from '../UI/Typo'
import v from '../../helpers/vocabulary'
import ComponentSpacing from '../containers/ComponentSpacing'
import Box from '../containers/Box'
import Textlink from './../UI/Textlink'
import Flexbox from '../containers/Flexbox'

export default function Offline({ ...props }) {
  return (
    <>
      <Dashboard onGoBackClick={() => routing.goBack(props)} loggedIn={true} />
      <div className="et-error-container">
        <ContentContainer>
          <ComponentSpacing mb="md" pt="8xl" className="offline-wrapper">
            <Icon>
              <OfflineIcon />
            </Icon>
          </ComponentSpacing>
          <ComponentSpacing mb="xs">
            <BodyLargeExtraBoldFontType className="offline-title" noSpace>
              {v.translate('basket.form.offline.title')}
            </BodyLargeExtraBoldFontType>
          </ComponentSpacing>
          <ComponentSpacing mb="xxl">
            <BodyFontType className="offline-body" noSpace>
              {v.translate('basket.form.offline.lead')}
            </BodyFontType>
          </ComponentSpacing>

          <Flexbox centered className="offline-link-container">
            <Textlink
              label={v.translate('basket.form.success.cta.linkToDashboard')}
              onClick={() => routing.goToPage(props, '/')}
            />
            <Textlink
              label={v.translate('pendingSendouts.title')}
              onClick={() => routing.goToPage(props, '/pending-sendouts')}
            />
          </Flexbox>
      </ContentContainer>
      </div>
    </>

  )
}
