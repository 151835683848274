import { Redirect, Route } from 'react-router'
import React, { Component } from 'react'
import OfferDialog from '../offer/dialog'
import localStorageHelper from '../../helpers/localStorage'

const PrivateRoute = props => {
  return !localStorageHelper.isTokenExpired() ? (
    <>
      <OfferDialog
        prevPath={location.pathname}
        location={window.location}
        {...props}
      />
      <Route {...props} render={(props) => <Component {...props} />} />
    </>
  ) : (
    <Redirect to="/login" />
  )
}

export default PrivateRoute
