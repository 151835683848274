import React from 'react'
import Flexbox from '../containers/Flexbox'
import Box from '../containers/Box'
import BasketListItem from './ListItem'
import { BodyExtraBoldFontType } from '../UI/Typo'
import v from '../../helpers/vocabulary'

export default function BasketList({
  quantityChanged,
  onDeleteItemClick,
  items,
  className
}) {
  return (
    <Flexbox centered {...{ className }}>
      <Box ml="md" mr="md" className="et-basket-list-container basic-layout">
        {items && items.length ? (
          items.map((item, index) => (
            <BasketListItem
              onDeleteItemClick={onDeleteItemClick}
              quantityChanged={quantityChanged}
              key={index}
              imageSrc={item.type == 'machines' ? item.image?.src : item.blade_image?.src}
              displayedLast={index === items.length - 1}
              {...item}
            />
          ))
        ) : (
          <BodyExtraBoldFontType>
            {v.translate('basket.emptyStateLead')}
          </BodyExtraBoldFontType>
        )}
      </Box>
    </Flexbox>
  )
}
