import React from 'react'
import { H2HeadlineFontType, BodyFontType } from '../UI/Typo'
import ComponentSpacing from '../containers/ComponentSpacing'
import ShowMore from '../UI/ShowMore'

export default function HelpSection({ title, sectionItems }) {
  return (
    <>
      <ComponentSpacing mb="sm" mt="3xl" className="et-help-titles basic-layout"> 
        <H2HeadlineFontType>{title}</H2HeadlineFontType>
      </ComponentSpacing>
      {sectionItems.map((item, index) => (
        <ShowMore key={index} label={item.label}>
          {item.content}
        </ShowMore>
      ))}
    </>
  )
}
