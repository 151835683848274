import React, { useState, useEffect } from 'react'
import Snackbar from './Snackbar'

export default function SnackbarPage({ snackbarProps, onClose, ...props }) {
  const [timer, setTimer] = useState(0)
  const [hide, setHide] = useState(false)
  useEffect(() => {
    const interval = setTimeout(() => {
      setTimer(timer + 100)
    }, 100)
    if (snackbarProps?.duration === timer) setHide(true)

    return () => {
      clearTimeout(interval)
    }
  }, [timer])

  useEffect(() => {
    setTimer(0)
    setHide(false)
  }, [snackbarProps])
  return (
    <>
      {props.children}
      {snackbarProps?.message && !hide && (
        <Snackbar
          {...snackbarProps}
          duration={false}
          hidden={hide}
          onClose={() => setHide(true)}
        />
      )}
    </>
  )
}
