
export function register() {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('easyToolsServiceWorker.js')
          .then((registration) => {
            const listenForWaitingServiceWorker = (registration, callback) => {
              const awaitStateChange = () => {
                registration.installing.addEventListener(
                  'statechange',
                  function () {
                    if (this.state === 'installed') {
                      callback(registration)
                    }
                  }
                )
              }
              if (!registration) return
              // if there is no controller registered this is the users' first visit
              // meaning there is nothing to update
              if (!navigator.serviceWorker.controller) return
              if (registration.waiting) return callback(registration)
              if (registration.installing) awaitStateChange()
              registration.addEventListener('updatefound', awaitStateChange)
            }

            let refreshing = false
            navigator.serviceWorker.addEventListener(
              'controllerchange',
              function () {
                if (refreshing) {
                  return
                }
                refreshing = true
                window.location.reload()
              }
            )
            const promptUserToRefresh = (registration) => {
              console.log("new data")
              /*const $prompt = document.getElementById('sw-prompt')
              const $confirmButton = $prompt.querySelector('[data-reload]')
              $confirmButton.addEventListener('click', (e) => {
                registration.waiting.postMessage('skipWaiting')
                e.preventDefault()
              })
              const $dismissButton = $prompt.querySelector('[data-dismiss]')
              if ($dismissButton !== null) {
                $dismissButton.addEventListener('click', (e) => {
                  $prompt.classList.add('-hidden')
                  e.preventDefault()
                })
              }
              $prompt.classList.remove('-hidden')*/
            }
            listenForWaitingServiceWorker(registration, promptUserToRefresh)
          })
          .catch((e) => {
            console.error('service worker registration failed.', e)
          })
      })
  }
}
