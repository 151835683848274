import React from 'react'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import Flexbox from '../../../containers/Flexbox'
import {
  ProductUSPFontType,
  BodySmallExtraBoldFontType,
  BodyLargeExtraBoldFontType,
  BodyLargeFontType
} from '../../Typo'
import v from '../../../../helpers/vocabulary'
import operation from '../../../../assets/icons/usps/operation.svg'
import cleaning from '../../../../assets/icons/usps/cleaning.svg'
import technology from '../../../../assets/icons/usps/innovation.svg'
import cutting from '../../../../assets/icons/usps/cut.svg'

export default function Featured({ section1, section2, category }) {
  const imgName = category.split('.').pop()
  const imgSources = { operation, cleaning, technology, cutting }

  return (
    <ComponentSpacing
      className="et-featured-wrapper"
      pt="sm"
      pr="md"
      pl="md"
      pb="md"
    >
      <Flexbox className="heading-container">
        <div className="heading-container">
          <BodySmallExtraBoldFontType className="heading" noSpace>
            {v.translate('products.detail.sectionUsp.title')}
          </BodySmallExtraBoldFontType>
          <ProductUSPFontType noSpace>
            {v.translate(category)}
          </ProductUSPFontType>
        </div>
        <img className="usps-category-img" src={imgSources[imgName]} />
      </Flexbox>
      <ComponentSpacing pt="md" />
      <Flexbox className="-section-container">
        <ComponentSpacing mb="md" className="section1 -column">
          <BodyLargeExtraBoldFontType noSpace>
            {v.translate(section1)}
          </BodyLargeExtraBoldFontType>
        </ComponentSpacing>
        <ComponentSpacing className="section2 -column">
          <BodyLargeFontType>{v.translate(section2)}</BodyLargeFontType>
        </ComponentSpacing>
      </Flexbox>
    </ComponentSpacing>
  )
}
