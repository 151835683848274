import React from 'react'
import ComponentSpacing from '../containers/ComponentSpacing'
import Flexbox from '../containers/Flexbox'
import Icon from '../UI/Icon'
import { ReactComponent as Close } from '../../assets/icons/close.svg'
import { ReactComponent as Share } from '../../assets/icons/ios-share.svg'
import Logo from '../../assets/images/logos/logo96.png'
import v from '../../helpers/vocabulary'
import { BodyFontType, BodyExtraBoldFontType } from '../UI/Typo'

function Popup({ dismiss }) {
  return (
    <ComponentSpacing
      pt="sm"
      pr="sm"
      pb="sm"
      pl="sm"
      className="et-add-to-home-popup"
    >
      <Flexbox>
        <img className="logo" src={Logo} />

        <ComponentSpacing mr="sm" ml="sm" className="text-container">
          <BodyFontType type="span">
            {v.translate('addToHome.firstPartAddToHomeMessage')}{' '}
            <Icon>
              <Share />
            </Icon>{' '}
            {v.translate('addToHome.secondPartAfterIcon')}{' '}
          </BodyFontType>
          <BodyExtraBoldFontType type="span">
            {v.translate('addToHome.thirdPartBold')}
          </BodyExtraBoldFontType>
        </ComponentSpacing>
        <a onClick={dismiss} className="dismiss-button">
          <Icon>
            <Close />
          </Icon>
        </a>
      </Flexbox>
    </ComponentSpacing>
  )
}

export default Popup
