import React from 'react'
import Box from './../containers/Box'
import OfferPendingsListItem from './ListItem'

export default function OfferPendingsList({ pendings }) {
  return (
    <Box className="pendings-list-box">
      {pendings &&
        pendings.map((offer, index) => (
          <OfferPendingsListItem key={index} offer={offer} />
        ))}
    </Box>
  )
}
