import service from './api'
import { metadata } from '../constants/apiRoutes'
import { metadataKey } from '../constants/localStorageKeys'

export default {
  async getMetadata() {
    const response = await service.getDataService(metadata, metadataKey)
    if (response.data) {
      const { cut_types, onboarding_slides } = response.data.attributes

      // GET onboarding slides
      for (let slide in onboarding_slides) {
       fetch(onboarding_slides[slide].image, { mode: 'no-cors' })
      }

      // GET cut types images
      for (let ct in cut_types) {
        const { image, blade_image, images } = cut_types[ct]
        fetch(image.src, { mode: 'no-cors' })
        fetch(blade_image.src, { mode: 'no-cors' })
        Object.values(images).forEach(imgRes => {
          fetch(imgRes.lowres.src, { mode: 'no-cors' })
          fetch(imgRes.highres.src, { mode: 'no-cors' })
        })
      }

    }
    return response
  },

  async getOnBoardingSlides() {
    const response = await service.getDataService(metadata, metadataKey)
    if (response.data) {
      const { onboarding_slides } = response.data.attributes
      // GET onboarding slides
      for (let slide in onboarding_slides) {
       fetch(onboarding_slides[slide].image, { mode: 'no-cors' })
      }

    }
    return response
  }
}
