import React from 'react'
import ContentContainer from '../containers/ContentContainer'
import Headline from '../UI/Headline'
import v from '../../helpers/vocabulary'
import LanguageForm from './Form'
import Dashboard from '../dashboard'
import Box from '../containers/Box'
import ComponentSpacing from './../containers/ComponentSpacing'

export default function LanguageLayout({
  onLangChanged,
  onGoBackClick,
  currentLanguage,
  languages,
}) {
  return (
    <>
      <Dashboard
        loggedIn={true}
        title={v.translate('settings.title')}
        onGoBackClick={onGoBackClick}
      />
      <ContentContainer className="et-settings-container basic-layout">
        <Headline
          className="headline"
          title={v.translate('settings.language.title')}
        />
        <Box pt="sm" className="settings-form">
          <LanguageForm
            languages={languages}
            currentLanguage={currentLanguage}
            onLangChanged={onLangChanged}
          />
        </Box>
      </ContentContainer>
    </>
  )
}
