import React, { useContext } from 'react'
import { BodyExtraBoldFontType, H2HeadlineFontType } from '../../../../UI/Typo'
import ComponentSpacing from '../../../../containers/ComponentSpacing'
import Flexbox from '../../../../containers/Flexbox'
import { HorizontalSeparator } from '../../../../UI/separator/horizontal'
import { PaybackCalculatorResultElement } from '../../../UI'
import { formatCurrency, formatMinutes } from '../../../../../helpers/formatter'
import { withMediumSpace, with3XLSpace } from '../../../../../helpers/spacing'
import { Cost } from '../../../UI/cost'
import { CalculatorContext } from '../../../../../providers/calculator'
import v from '../../../../../helpers/vocabulary'

export function PaybackCalculatorConfigureMachineArbeitTab() {
  const {
    selectedMachine,
    productWorkPreparation,
    setProductWorkPreparation,
    productWorkFillup,
    setProductWorkFillup,
    amountPerCharge,
    productWorkTotal,
    productWorkCleaning,
    setProductWorkCleaning,
    productWorkTotalWithCleaning,
    wagePerHour,
    setWagePerHour,
    productWageTotal,
  } = useContext(CalculatorContext)

  // PRODUCT WORK

  const productWorkCosts = [
    {
      name: v.translate('calculator.savings.tabTime.preparation'),
      value: productWorkPreparation,
      renderValue: formatMinutes,
      onChange: setProductWorkPreparation,
      min:
        selectedMachine.attributes.roi_data.product_work_preparation.range_min,
      max:
        selectedMachine.attributes.roi_data.product_work_preparation.range_max,
      step: selectedMachine.attributes.roi_data.product_work_preparation.steps,
    },
    {
      name: v.translate('calculator.savings.tabTime.fillUp'),
      value: productWorkFillup,
      renderValue: formatMinutes,
      onChange: setProductWorkFillup,
      min: selectedMachine.attributes.roi_data.product_work_fillup.range_min,
      max: selectedMachine.attributes.roi_data.product_work_fillup.range_max,
      step: selectedMachine.attributes.roi_data.product_work_fillup.steps,
    },
  ]

  const productWorkResults = [
    {
      name: v.translate(
        'calculator.savings.tabTime.summary.timeRequiredForBatchQuantity',
        { batchQuantity: amountPerCharge }
      ),
      value: productWorkTotal,
      renderValue: formatMinutes,
    },
  ]

  // CLEANING

  const cleaningCosts = [
    {
      name: v.translate('calculator.savings.tabTime.cleaning'),
      value: productWorkCleaning,
      renderValue: formatMinutes,
      onChange: setProductWorkCleaning,
      min: selectedMachine.attributes.roi_data.product_work_cleaning.range_min,
      max: selectedMachine.attributes.roi_data.product_work_cleaning.range_max,
      step: selectedMachine.attributes.roi_data.product_work_cleaning.steps,
    },
  ]

  const cleaningResults = [
    {
      name: v.translate('calculator.savings.tabTime.summary.totalTimeRequired'),
      value: productWorkTotalWithCleaning,
      renderValue: formatMinutes,
    },
  ]
  // WAGE

  const wageCosts = [
    {
      name: v.translate('calculator.savings.tabTime.wage'),
      value: wagePerHour,
      renderValue: formatCurrency,
      onChange: setWagePerHour,
      min: selectedMachine.attributes.roi_data.wage_per_hour.range_min,
      max: selectedMachine.attributes.roi_data.wage_per_hour.range_max,
      step: selectedMachine.attributes.roi_data.wage_per_hour.steps,
    },
  ]

  const wageResults = [
    {
      name: v.translate('calculator.savings.tabTime.summary.wageCosts', {
        batchQuantity: amountPerCharge,
        totalTimeRequired: productWorkTotalWithCleaning,
      }),
      value: productWageTotal,
      renderValue: formatCurrency,
    },
  ]

  return (
    <>
      <ComponentSpacing
        pt="xs"
        pl="lg"
        pr="lg"
        pb="sm"
        className="vivid-blue-background et-payback-modal-header-end"
        id="et-configure-machine-arbeit"

      >
        <Flexbox spaceBetween className="basic-layout">
          <BodyExtraBoldFontType noSpace>
            {v.translate('calculator.savings.tabTime.subtitle')}
          </BodyExtraBoldFontType>
          <BodyExtraBoldFontType noSpace>
            {formatCurrency(productWageTotal)}
          </BodyExtraBoldFontType>
        </Flexbox>
      </ComponentSpacing>
      <ComponentSpacing
        pt="3xl"
        pb="3xl"
        pl="lg"
        pr="lg"
        className="basic-layout  et-payback-modal-body"
      >
        {[
          <>
            <H2HeadlineFontType noSpace>
              {v.translate('calculator.savings.tabTime.timeSpent')}
            </H2HeadlineFontType>
            <ComponentSpacing pt="md" />
            {productWorkCosts.map(Cost).reduce(withMediumSpace)}
            <ComponentSpacing pt="xl" pb="sm">
              <HorizontalSeparator />
            </ComponentSpacing>
            {productWorkResults.map(PaybackCalculatorResultElement)}
          </>,
          <>
            <H2HeadlineFontType noSpace>
              {v.translate('calculator.savings.tabTime.cleaning')}
            </H2HeadlineFontType>
            <ComponentSpacing pt="md" />
            {cleaningCosts.map(Cost).reduce(withMediumSpace)}
            <ComponentSpacing pt="xl" pb="sm">
              <HorizontalSeparator />
            </ComponentSpacing>
            {cleaningResults.map(PaybackCalculatorResultElement)}
          </>,
          <>
            <H2HeadlineFontType noSpace>
              {v.translate('calculator.savings.tabTime.wage')}
            </H2HeadlineFontType>
            <ComponentSpacing pt="md" />
            {wageCosts.map(Cost).reduce(withMediumSpace)}
            <ComponentSpacing pt="xl" pb="sm">
              <HorizontalSeparator />
            </ComponentSpacing>
            {wageResults.map(PaybackCalculatorResultElement)}
          </>,
        ].reduce(with3XLSpace)}
      </ComponentSpacing>
    </>
  )
}
