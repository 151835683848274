import React, { useState } from 'react'
import ReactGA from 'react-ga'
import { basketHelper } from '../helpers/basket'
import machineHelper from './../helpers/machine'
import bladeHelper from './../helpers/blade'
import { DashboardContext } from './dashboard'
import { offerHelper } from '../helpers/offer'
import { translateInEnglish } from '../helpers/vocabulary'
import {
  EventCategoryHighInterestProducts,
  EventCategoryCheckout,
} from '../analytics/categories'

export const BasketContext = React.createContext()

export const BasketProvider = (props) => {
  const dashboardContext = React.useContext(DashboardContext)
  const [basketItemsNum, setBasketItemsNum] = useState(
    basketHelper.getTotalItems()
  )
  const [basketItems, setBasketItems] = useState(basketHelper.getItems())

  const handleQuantityChanged = (id, title, quantity) => {
    basketHelper.itemQuantityChanged(id, title, quantity)
    updateBasket()
  }

  const updateBasket = () => {
    setBasketItems(basketHelper.getItems())
    setBasketItemsNum(basketHelper.getTotalItems())
    dashboardContext.setUserNotificationNumber(offerHelper.getPendings().length)
  }

  const handleDeleteItem = (id, title) => {
    ReactGA.event({
      category: EventCategoryCheckout,
      action: 'Remove item from basket',
      label: 'Delete button',
    })
    basketHelper.deleteItemFromBasket(id, title)
    updateBasket()
  }

  const handleDeleteBasket = () => {
    basketHelper.deleteBasket()
    updateBasket()
  }

  const handleAddMachine = (machine) => {
    const lsMachine = machineHelper.getMachineById(machine.id)
    addItemToBasket({ ...lsMachine, ...lsMachine.attributes, ...machine })

    ReactGA.event({
      category: EventCategoryHighInterestProducts,
      action: 'Machine added to basket',
      label: translateInEnglish(lsMachine.attributes.title),
    })
  }

  const handleAddBlade = (blade) => {
    const lsBlade = bladeHelper.getBladeById(blade.id)
    addItemToBasket({
      ...lsBlade,
      ...lsBlade.attributes,
      ...blade,
    })

    ReactGA.event({
      category: EventCategoryHighInterestProducts,
      action: 'Disc added to basket',
      label: translateInEnglish(lsBlade.attributes.title),
    })
  }

  const handleAddAccessorie = (accessorie) => {
    const lsAccessorie = machineHelper.getMachineById(accessorie.id)
    addItemToBasket({
      ...lsAccessorie,
      ...lsAccessorie.attributes,
      ...accessorie,
    })

    ReactGA.event({
      category: EventCategoryHighInterestProducts,
      action: 'Machine added to basket',
      label: translateInEnglish(lsAccessorie.attributes.title),
    })
  }

  const addItemToBasket = (item) => {
    basketHelper.addItem(item)
    updateBasket()
  }

  return (
    <BasketContext.Provider
      value={{
        basketItems,
        setBasketItems,
        setBasketItemsNum,
        basketItemsNum,
        handleDeleteItem,
        handleDeleteBasket,
        handleQuantityChanged,
        handleAddBlade,
        handleAddMachine,
        handleAddAccessorie,
        updateBasket,
      }}
    >
      {props.children}
    </BasketContext.Provider>
  )
}
