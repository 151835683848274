import React from 'react'
import Store from './Store'
import { Switch, Route, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './App.css'
import './style.scss'
import Login from './sites/login/Login'
import PasswordReset from './sites/passwordReset/PasswordReset'
import { LogPath } from './analytics/LogPath'
import PrivateApp from './PrivateApp'

const history = createBrowserHistory()

function App() {

  return (
    <>
      <Router history={history}>
        <Store>
          <Switch>
            <Route exact path="/login" component={LogPath(Login)} />
            <Route exact path="/password-reset" component={LogPath(PasswordReset)} />
            <Route path="*" component={PrivateApp} />
          </Switch>
        </Store>
      </Router>
    </>
  )
}

export default App
