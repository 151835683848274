import React, { useContext } from 'react'
import { BodyExtraBoldFontType, H2HeadlineFontType } from '../../../../UI/Typo'
import ComponentSpacing from '../../../../containers/ComponentSpacing'
import Flexbox from '../../../../containers/Flexbox'
import { HorizontalSeparator } from '../../../../UI/separator/horizontal'
import { PaybackCalculatorResultElement } from '../../../UI'
import {
  formatKg,
  formatCurrency,
  formatMinutes
} from '../../../../../helpers/formatter'
import { withMediumSpace, with3XLSpace } from '../../../../../helpers/spacing'
import { Cost } from '../../../UI/cost'
import { CalculatorContext } from '../../../../../providers/calculator'
import v from '../../../../../helpers/vocabulary'

export function PaybackCalculatorConfigureHandcutArbeitTab() {
  const {
    selectedMachine,
    amountPerCharge,
    wagePerHour,
    setWagePerHour,
    handcutWorkPerKg,
    setHandcutWorkPerKg,
    handcutWorkTotal,
    handcutWageTotal
  } = useContext(CalculatorContext)

  // HANDCUT WORK

  const handcutWorkCosts = [
    {
      name: v.translate('calculator.savings.tabTime.timeSpent'),
      value: handcutWorkPerKg,
      renderValue: formatMinutes,
      onChange: setHandcutWorkPerKg,
      min: selectedMachine.attributes.roi_data.handcut_work_per_kg.range_min,
      max: selectedMachine.attributes.roi_data.handcut_work_per_kg.range_max,
      step: selectedMachine.attributes.roi_data.handcut_work_per_kg.steps
    }
  ]

  const handcutWorkResults = [
    {
      name: v.translate(
        'calculator.savings.tabTime.summary.timeRequiredForBatchQuantity',
        { batchQuantity: amountPerCharge }
      ),
      value: handcutWorkTotal,
      renderValue: formatMinutes
    }
  ]

  // WAGE

  const wageCosts = [
    {
      name: v.translate('calculator.savings.tabTime.wage'),
      value: wagePerHour,
      renderValue: formatCurrency,
      onChange: setWagePerHour,
      min: selectedMachine.attributes.roi_data.wage_per_hour.range_min,
      max: selectedMachine.attributes.roi_data.wage_per_hour.range_max,
      step: selectedMachine.attributes.roi_data.wage_per_hour.steps
    }
  ]

  const wageResults = [
    {
      name: v.translate('calculator.savings.tabTime.summary.wageCosts', {
        batchQuantity: amountPerCharge,
        totalTimeRequired: handcutWorkTotal
      }),
      value: handcutWageTotal,
      renderValue: formatCurrency
    }
  ]

  return (
    <>
      <ComponentSpacing
        pt="xs"
        pl="lg"
        pr="lg"
        pb="sm"
        className="vivid-blue-background  et-payback-modal-header-end"
      >
        <Flexbox spaceBetween className="basic-layout">
          <BodyExtraBoldFontType noSpace>
            {v.translate('calculator.savings.tabTime.subtitle')}
          </BodyExtraBoldFontType>
          <BodyExtraBoldFontType noSpace>
            {formatCurrency(handcutWageTotal)}
          </BodyExtraBoldFontType>
        </Flexbox>
      </ComponentSpacing>
      <ComponentSpacing pt="3xl" pb="3xl" pl="lg" pr="lg" className="basic-layout et-payback-modal-body">
        {[
          <>
            <H2HeadlineFontType noSpace>
              {v.translate(
                'calculator.start.sectionSavings.processingCostHandcut'
              )}
            </H2HeadlineFontType>
            <ComponentSpacing pt="md" />
            {handcutWorkCosts.map(Cost).reduce(withMediumSpace)}
            <ComponentSpacing pt="xl" pb="xs">
              <HorizontalSeparator />
            </ComponentSpacing>
            {handcutWorkResults.map(PaybackCalculatorResultElement)}
          </>,
          <>
            <H2HeadlineFontType noSpace>
              {v.translate('calculator.savings.tabTime.subtitle')}
            </H2HeadlineFontType>
            <ComponentSpacing pt="md" />
            {wageCosts.map(Cost).reduce(withMediumSpace)}
            <ComponentSpacing pt="xl" pb="xs">
              <HorizontalSeparator />
            </ComponentSpacing>
            {wageResults.map(PaybackCalculatorResultElement)}
          </>
        ].reduce(with3XLSpace)}
      </ComponentSpacing>
    </>
  )
}
