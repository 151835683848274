import React, { useState } from 'react'
import Flexbox from '../containers/Flexbox'
import classNames from 'classnames'
import { BodySmallFontType } from '../UI/Typo'
import ComponentSpacing from '../containers/ComponentSpacing'

export function TabPicker({ tabs, selectedTabIndex }) {
  const [mouseOverTabIndex, setMouseOverTabIndex] = useState()
  return (
    <Flexbox className="et-tab-picker basic-layout">
      {tabs
        .map((props, index) => ({
          onMouseOver: () => setMouseOverTabIndex(index),
          onMouseLeave: () => setMouseOverTabIndex(),
          ...props
        }))
        .map(Tab)
        .reduce((current, next, nextIndex) => {
          const currentIndex = nextIndex - 1

          return (
            <>
              {current}
              <TabSeparator
                innerFill={
                  selectedTabIndex === currentIndex ||
                  mouseOverTabIndex === currentIndex
                }
                outerFill={
                  selectedTabIndex === nextIndex ||
                  mouseOverTabIndex === nextIndex
                }
              />
              {next}
            </>
          )
        })}
    </Flexbox>
  )
}

const Tab = ({ name, Icon, selected, onClick, ...props }) => (
  <Flexbox
    className={'et-tab-picker-tab' + (selected ? '-selected' : '')}
    fullWidth
    centered
    alignItemsCenter
    {...{ onClick, ...props }}
  >
    <Flexbox column alignItemsCenter>
      <Icon />
      <ComponentSpacing pt="xxs" />
      <BodySmallFontType noSpace>{name}</BodySmallFontType>
    </Flexbox>
  </Flexbox>
)

const TabSeparator = ({ innerFill, outerFill }) => (
  <svg
    className={classNames('et-tab-picker-tab-separator-svg', {
      '-inner-fill': innerFill,
      '-outer-fill': outerFill
    })}
    width="8px"
    height="65px"
    viewBox="0 0 8 65"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="separator" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Outer" points="8 0 8 65 0 65 8 32.5 0 0"></polygon>
      <polygon
        id="Inner"
        points="-1 -4.0625 6.68702065 27.1660214 8 32.5 -1 69.0625"
      ></polygon>
    </g>
  </svg>
)
