import React, { useContext, useRef, useEffect } from 'react'
import BasketLayout from '../../components/basket/Layout'
import routing from './../../helpers/routing'
import { BasketContext } from './../../providers/basket'
import animationHelper from '../../helpers/animation'
import { EmptyBasket } from '../../components/basket/empty'
import Dashboard from './../../components/dashboard'
import ComponentSpacing from '../../components/containers/ComponentSpacing'
import v from '../../helpers/vocabulary'

export function Basket({ ...props }) {
  const layoutRef = useRef(null)
  useEffect(() => {
    animationHelper.animateCSS(layoutRef, 'fadeIn', null)
  }, [])

  const {
    handleDeleteItem,
    handleQuantityChanged,
    basketItems,
    basketItemsNum,
    handleDeleteBasket,
  } = useContext(BasketContext)

  return (
    <>
      <Dashboard 
      loggedIn
      onGoBackClick={() => routing.goToPage(props, '/')}
      title={v.translate('basket.title')} />
      <div ref={layoutRef} className="fast basket-layout">
        {basketItems.length > 0 ? (
          <BasketLayout
            onGoBackClick={() => routing.goBack(props)}
            onDeleteItemClick={handleDeleteItem}
            quantityChanged={handleQuantityChanged}
            items={basketItems}
            basketNotificationNum={basketItemsNum}
            onDeleteBasket={handleDeleteBasket}
          />
        ) : (
          <EmptyBasket />
        )}
      </div>
    </>
  )
}
