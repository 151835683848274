import React from 'react'
import InputField from '../UI/InputField'
import Box from '../containers/Box'
import { ReactComponent as Search } from '../../assets/icons/search.svg'
import Flexbox from '../containers/Flexbox'
import ComponentSpacing from '../containers/ComponentSpacing'

export default function BladeSearchSearchField({
  handleSearch,
  placeholder,
  value,
  onClear,
}) {
  return (
    <Flexbox alignItemsCenter grow className="bladesearch-search-container">
      <ComponentSpacing m="md">
        <InputField
          placeholder={placeholder}
          icon={Search}
          clear
          onClear={onClear}
          value={value}
          onChange={(inputElement) => {
            handleSearch(inputElement.target.value)
          }}
        />
      </ComponentSpacing>
    </Flexbox>
  )
}
