import React from 'react'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import { H2HeadlineFontType } from '../../Typo'
import v from '../../../../helpers/vocabulary'
import AddToCartLink from '../../order/addToCart/AddToCartLink'
import Flexbox from '../../../containers/Flexbox'
import { BodyFontType } from '../../Typo'

export default function Accessorie({ accessoire, addToBasketAccessorie }) {
  const attributes = accessoire?.attributes

  /**
   * Needed to handle basket items correctly
   * Language/translation error occured when adding items and comparing them
   */
  const additionalInfo = {
    isAccessorie: true,
    titleId: attributes.title,
  }
  return (
    <div className="accessorie-detail">
      <ComponentSpacing pt="lg" pb="lg">
        <Flexbox>
          <img src={attributes.image.src} />
          <ComponentSpacing pl="sm" pt="xs">
            <H2HeadlineFontType className="-black">
              {v.translate(attributes.title)}
            </H2HeadlineFontType>
            <BodyFontType>{v.translate(attributes.lead)}</BodyFontType>
          </ComponentSpacing>
        </Flexbox>
        <Flexbox>
          <div className="space-div"></div>
          <ComponentSpacing pl="sm">
            <AddToCartLink
              title={attributes.title}
              addToBasket={addToBasketAccessorie}
              id={accessoire.id}
              desc={attributes.short_description}
              imageSrc={attributes.image.src}
              additionalInfo={additionalInfo}
            />
          </ComponentSpacing>
        </Flexbox>
      </ComponentSpacing>
    </div>
  )
}
