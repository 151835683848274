import React, { useContext } from 'react'
import { BodyExtraBoldFontType } from '../../../../UI/Typo'
import ComponentSpacing from '../../../../containers/ComponentSpacing'
import Flexbox from '../../../../containers/Flexbox'
import { HorizontalSeparator } from '../../../../UI/separator/horizontal'
import { PaybackCalculatorResultElement } from '../../../UI'
import { formatKg, formatCurrency } from '../../../../../helpers/formatter'
import { withXSSpace } from '../../../../../helpers/spacing'
import { PaybackCalculatorProcessingCostElement } from '../../../UI'
import { CalculatorContext } from '../../../../../providers/calculator'
import v from '../../../../../helpers/vocabulary'

export function PaybackCalculatorConfigureHandcutTotalTab() {
  const {
    amountPerCharge,
    productMaterialTotalCosts,
    handcutTotal,
    handcutWageTotal
  } = useContext(CalculatorContext)

  const costs = [
    {
      name: v.translate(
        'calculator.savings.tabProcessing.summary.materialCosts',
        { batchQuantity: amountPerCharge }
      ),
      value: productMaterialTotalCosts,
      renderValue: formatCurrency
    },
    {
      name: v.translate('calculator.savings.tabProcessing.summary.wageCosts', {
        batchQuantity: amountPerCharge
      }),
      value: handcutWageTotal,
      renderValue: formatCurrency
    }
  ]

  const results = [
    {
      name: v.translate(
        'calculator.savings.tabProcessing.summary.totalCostProcessing',
        { batchQuantity: amountPerCharge }
      ),
      value: handcutTotal,
      renderValue: formatCurrency
    }
  ]
  return (
    <>
      <ComponentSpacing
        pt="xs"
        pl="lg"
        pr="lg"
        pb="sm"
        className="vivid-blue-background et-payback-modal-header-end"
      >
        <Flexbox spaceBetween className="basic-layout">
          <BodyExtraBoldFontType noSpace>
            {v.translate('calculator.savings.tabProcessing.subtitle')}
          </BodyExtraBoldFontType>
          <BodyExtraBoldFontType noSpace>
            {formatCurrency(handcutTotal)}
          </BodyExtraBoldFontType>
        </Flexbox>
      </ComponentSpacing>
      <ComponentSpacing pt="3xl" pb="3xl" pl="lg" pr="lg" className="basic-layout et-payback-modal-body">
        {costs.map(PaybackCalculatorProcessingCostElement).reduce(withXSSpace)}
        <ComponentSpacing pt="xl" pb="xs">
          <HorizontalSeparator />
        </ComponentSpacing>
        {results.map(PaybackCalculatorResultElement)}
      </ComponentSpacing>
    </>
  )
}
