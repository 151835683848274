import React from 'react'
import Dashboard from './../dashboard/index'
import v from '../../helpers/vocabulary'
import ContentContainer from './../containers/ContentContainer'
import OfferPendingsList from './List'
import Flexbox from '../containers/Flexbox'
import ComponentSpacing from '../containers/ComponentSpacing'
import { BodyLargeFontType, BodySmallFontType } from './../UI/Typo'
import Snackbar from '../UI/Snackbar'
import Button from '../UI/Button'
import Headline from '../UI/Headline'

export default function OfferPendingsLayout({
  pendings,
  onGoBackClick,
  onSendAllClick,
  snackbarClosed,
  snackbarMessageLabel,
  internetConnection,
}) {
  return (
    <>
      <Dashboard
        loggedIn={true}
        title={v.translate('pendingSendouts.title')}
        onGoBackClick={onGoBackClick}
      />
      <ContentContainer>
        <ComponentSpacing pt="md">
          <Headline title={v.translate('pendingSendouts.title')} />
        </ComponentSpacing>
        <ComponentSpacing mt="lg" mb="xxl" ml="sm">
          <BodyLargeFontType className="grey-text">
            {v.translate('pendingSendouts.lead')}
          </BodyLargeFontType>
        </ComponentSpacing>
        <Flexbox spaceBetween className="sendouts-table-items">
          <ComponentSpacing>
            <BodySmallFontType className="grey-text">
              {v.translate('pendingSendouts.tableItem')}
            </BodySmallFontType>
          </ComponentSpacing>
          <ComponentSpacing>
            <BodySmallFontType className="grey-text">
              {v.translate('pendingSendouts.tableDate')}
            </BodySmallFontType>
          </ComponentSpacing>
        </Flexbox>
        <OfferPendingsList pendings={pendings} />
        <Flexbox centered>
          <Button
            onClick={onSendAllClick}
            disabled={!internetConnection}
            className="btn-pendings-primaryCta"
          >
            {v.translate('alert.pendingSendouts.primaryCta')}
          </Button>
        </Flexbox>
      </ContentContainer>
      {snackbarMessageLabel && (
        <Snackbar
          message={v.translate(snackbarMessageLabel)}
          duration={3000}
          onClose={snackbarClosed}
        />
      )}
    </>
  )
}
