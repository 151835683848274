import React, { useEffect } from 'react'
import animationHelper from '../../helpers/animation'
import { useHistory } from 'react-router-dom'

const HierarchialTransitionContainer = props => {
  const screenRef = React.useRef(null)
  const history = useHistory()

  useEffect(() => {
    var goBack = Boolean(localStorage.getItem("goBack")); 
    if(!goBack) {
    animationHelper.animateCSS(screenRef, 'fadeInRight', () => {
      document.getElementById('screen').style.opacity = 1
      localStorage.removeItem("goBack"); 
    }
    )
  }
  else {
    animationHelper.animateCSS(screenRef, 'fadeInLeft' , () => {
      document.getElementById('screen').style.opacity = 1
      localStorage.removeItem("goBack"); 
    }
    )
  }
  }, [])
  return (
    <div ref={screenRef} id="screen">
      {props.children}
    </div>
  )
}

export default HierarchialTransitionContainer
