import React, { useLayoutEffect, createRef, useEffect } from 'react'
import ImageGallery from 'react-image-gallery'
import ReactGA from 'react-ga'
import { BodyLargeFontType, H2HeadlineFontType } from '../../UI/Typo'
import v from '../../../helpers/vocabulary'
import Dashboard from '../../dashboard/index'
import AddToCart from '../../../components/UI/order/addToCart/AddToCart'
import ComponentSpacing from '../../../components/containers/ComponentSpacing'
import Slideshow from '../../../components/UI/SlideShow'
import Featured from '../../../components/UI/products/featured/Featured'
import ProductTile from '../../../components/UI/products/productTile/ProductTile'
import Accessories from '../../../components/UI/accessories/Accessories'
import TableSpecs from '../../UI/products/tableSpecs/TableSpecs'
import roiImg from '../../../assets/images/other/calculator-image.png'
import SectionTile from '../../home/SectionTile'
import Headline from '../../UI/Headline'
import { metadataKey } from '../../../constants/localStorageKeys'
import Loading from './../../UI/Loading'
import bladeHelper from './../../../helpers/blade'
import RenderZoomInIcon from './../../UI/ZoomInIcon'
import HierarchialTransitionContainer from '../../animations/HieararchialTransition'
import SnackbarPage from './../../UI/SnackbarPage'
import routing from './../../../helpers/routing'
import { VerticalSeparator } from './../../UI/separator/vertical'
import { AppInteractionCategory } from '../../../analytics/categories'

function Layout({
  machine,
  addToBasket,
  addToBasketAccessorie,
  onGoBackClick,
  addedItemLabel,
  onSnackbarClosed,
}) {
  useEffect(() => {
    if (routing.getLastElementFromURL().includes('#discs')) {
      setTimeout(function () {
        document.getElementById('discs').scrollIntoView(true)
        window.scrollBy(0, -120)
      }, 180)
    }
  }, [])

  if (machine) {
    var images = []
    var currentImages = machine.attributes.images
    for (let i = 0; i < currentImages.length; i++) {
      images.push({
        original: currentImages[i].lowres.src,
        fullscreen: currentImages[i].highres.src,
      })
    }

    let productImgs = []
    let usps = machine.attributes.usps
    Object.keys(usps).forEach((key) => {
      productImgs.push(<Featured {...usps[key]} />)
    })

    const cutTypes = JSON.parse(localStorage.getItem(metadataKey)).data
      .attributes.cut_types

    const goToRoi = () => {
      ReactGA.event({
        category: AppInteractionCategory,
        action: 'Navigate to Payback Calculator',
        label: 'Payback Calculator teaser',
      })
    }

    return (
      <SnackbarPage
        snackbarProps={{
          duration: 3000,
          message:
            addedItemLabel &&
            v.translate('snackbar.productAdded', {
              productName: addedItemLabel,
            }),
          onClose: onSnackbarClosed,
        }}
      >
        <Dashboard
          onGoBackClick={onGoBackClick}
          loggedIn={true}
          title={v.translate(machine.attributes.title)}
        />
        <HierarchialTransitionContainer>
          <div className="et-machine-details-layout">
            <div className="product-gallery">
              <ComponentSpacing
                pt="md"
                pl="sm"
                pb="sm"
                className="basic-layout"
              >
                <Headline title={v.translate(machine.attributes.title)} />
              </ComponentSpacing>
              <div className="basic-layout">
                <ImageGallery
                  items={images}
                  showBullets
                  showPlayButton={false}
                  showThumbnails={false}
                  showNav={false}
                  renderFullscreenButton={RenderZoomInIcon}
                  showNav={true}
                />
              </div>
            </div>

            <ComponentSpacing pt="md" pl="sm" pr="sm" className="basic-layout">
              <BodyLargeFontType noSpace>
                {v.translate(machine.attributes.short_description)}
              </BodyLargeFontType>
              <ComponentSpacing pt="xxl" pb="xxl">
                <AddToCart
                  title={machine.attributes.title}
                  addToBasket={addToBasket}
                  id={machine.id}
                  desc={
                    machine.attributes.technical_specs.engine_power +
                    ' kW, ' +
                    machine.attributes.technical_specs.voltage +
                    ' V, ' +
                    machine.attributes.technical_specs.frequency +
                    ' Hz'
                  }
                />
              </ComponentSpacing>

              <Slideshow
                slideComponents={productImgs}
                component="Machine Detail"
              />
            </ComponentSpacing>

            <ComponentSpacing
              pl="sm"
              pr="sm"
              pb="sm"
              pt="xxl"
              className="basic-layout"
            >
              <H2HeadlineFontType noSpace>
                {v.translate('products.door.fittingDics')}
              </H2HeadlineFontType>
            </ComponentSpacing>
            <div className="grid-wrapper" id="discs">
              <div className="grid basic-layout">
                {machine &&
                  machine.attributes.cut_types.map((machineCutType, index) => (
                    <span key={index}>
                      {<VerticalSeparator />}
                      <ProductTile
                        name={cutTypes[machineCutType].name}
                        img={cutTypes[machineCutType].image.src}
                        cutTypeId={machineCutType}
                        machineId={machine.id}
                        family_code={cutTypes[machineCutType].family_code}
                        bladeId={bladeHelper.getBladeIdByCutTypeIdForMachine(
                          machineCutType,
                          machine
                        )}
                      />
                    </span>
                  ))}
              </div>
            </div>
            <ComponentSpacing pt="xxl" className="basic-layout" />
            <TableSpecs machine={machine} />
            <ComponentSpacing pt="xxl" pl="sm" pr="sm" pb="xxl">
              <div className="go-to-roi" onClick={goToRoi}>
                <SectionTile
                  type="secondary"
                  img={roiImg}
                  name={v.translate('dashboard.navigation.roiCalculator')}
                  desc={v.translate('products.detail.sectionRoiTeaser.lead')}
                  link={`/machines/${machine.id}/calculator`}
                />
              </div>
            </ComponentSpacing>
            {machine.attributes.accessories.length > 0 && (
              <>
                <ComponentSpacing
                  pl="md"
                  pr="md"
                  pb="md"
                  pt="xxl"
                  className="basic-layout"
                >
                  <H2HeadlineFontType noSpace>
                    {v.translate('articles.accessories.title')}
                  </H2HeadlineFontType>
                </ComponentSpacing>
                <div className="grid-wrapper" id="accessories">
                  <Accessories
                    machine={machine}
                    addToBasketAccessorie={addToBasketAccessorie}
                  />
                </div>
              </>
            )}
          </div>
          <ComponentSpacing pb="5xl" className="basic-layout" />
        </HierarchialTransitionContainer>
      </SnackbarPage>
    )
  } else return <Loading />
}

export default Layout
