import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../../../providers/products'
import { useParams, Route } from 'react-router-dom'
import Layout from '../../../components/machines/details/Layout'
import { BasketContext } from './../../../providers/basket'
import routing from './../../../helpers/routing'

const Machines = (props) => {
  const productContext = useContext(ProductContext)
  const basketContext = useContext(BasketContext)
  const [addedItem, setAddedItem] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    productContext.getMachine(id)
    // retrieve last scroll position
    var scrollPosition = localStorage.getItem('machineDetailsScrollValue')
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition)
    }
  }, [])

  const handleAddMachine = (machine) => {
    basketContext.handleAddMachine(machine)
    setAddedItem(machine.title)
  }

  const handleAddAccessorie = (accessorie) => {
    basketContext.handleAddAccessorie(accessorie)
    setAddedItem(accessorie.title)
  }

  return (
    <ProductContext.Consumer>
      {(context) => (
        <Layout
          onGoBackClick={() => routing.goBack(props)}
          basketNotificationNum={basketContext.basketItemsNum}
          addToBasket={handleAddMachine}
          addToBasketAccessorie={handleAddAccessorie}
          machine={context.machine}
          addedItemLabel={addedItem}
          onSnackbarClosed={() => setAddedItem(null)}
        />
      )}
    </ProductContext.Consumer>
  )
}

export default Machines
