import React, { useContext, useState, useEffect } from 'react'
import Headline from '../UI/Headline'
import Dashboard from '../dashboard'
import v from '../../helpers/vocabulary'
import { BodyFontType, BodyLargeFontType } from '../UI/Typo'
import ComponentSpacing from '../containers/ComponentSpacing'
import { SelectInput } from '../UI/inputs/select'
import Flexbox from '../containers/Flexbox'
import SectionTile from '../home/SectionTile'
import convenienceImg from '../../assets/icons/convenience.svg'
import handcutImg from '../../assets/icons/handcut.svg'
import { PaybackCalculatorConvenience } from './convenience'
import { PaybackCalculatorHandcut } from './handcut'
import { CalculatorContext } from '../../providers/calculator'
import HierarchialTransitionContainer from '../animations/HieararchialTransition'
import routing from './../../helpers/routing'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import { CalculatorCategory, AppInteractionCategory } from '../../analytics/categories'

export const PaybackCalculator = props => {
  const history = useHistory()
  const {
    machines,
    selectedMachine,
    setSelectedMachine,
    selectedMachineTitle,
    selectedMachineShortDescription,
    loadingLabel
  } = useContext(CalculatorContext)

  function onSelectedMachineTitle(selectedMachineTitle) {
    ReactGA.event({
      category: CalculatorCategory,
      action: 'Calculator select machine',
      label: selectedMachineTitle
    })
    const selectedMachine = machines.find(
      machine => v.translate(machine.attributes.title) === selectedMachineTitle
    )
    setSelectedMachine(selectedMachine)
  }


  const compareOptions = [
    {
      imgSrc: convenienceImg,
      title: v.translate(
        'calculator.start.sectionSavings.processingCostConvenience'
      ),
      className: "convinience-select-img"
    },
    {
      imgSrc: handcutImg,
      title: v.translate(
        'calculator.start.sectionSavings.processingCostHandcut'
      ),
      className: "handcut-select-img"
    }
  ]

  const [selectedCompareOption, setSelectedCompareOption] = useState(
    compareOptions[0]
  )

  function onSelectedCompareOption(selectedCompareOptionTitle) {
    ReactGA.event({
      category: CalculatorCategory,
      action: 'Calculator select comparison',
      label: selectedCompareOptionTitle
    })
    const selectedCompareOption = compareOptions.find(
      ({ title }) => title === selectedCompareOptionTitle
    )
    setSelectedCompareOption(selectedCompareOption)
  }

  const machineTeaserTrack = () => {
    ReactGA.event({
      category: AppInteractionCategory,
      action: 'Navigate to Machine',
      label: 'Machine teaser'
    })
  }

  return (
    <div className="et-payback-calculator-container">
      <Dashboard
        title={v.translate('dashboard.navigation.roiCalculator')}
        loggedIn={true}
        onGoBackClick={() => routing.goToPage({...props, history}, '/') }
      />
      <HierarchialTransitionContainer>
        <ComponentSpacing
          pl="sm"
          pr="sm"
          pt="md"
          pb="5xl"
          className="et-playback-calculator basic-layout"
        >
          <Headline title={v.translate('calculator.start.title')} />
          <ComponentSpacing pt="lg">
            <BodyLargeFontType noSpace>
              {v.translate('calculator.sectionIntro.lead')}
            </BodyLargeFontType>
          </ComponentSpacing>
          <ComponentSpacing pt="xxl" />
          <Flexbox
            alignItemsCenter
            spaceBetween
            swapMobileDirection
            className="et-payback-calculator-inputs-container"
          >
            <SelectInput
              disabled={machines.length === 0}
              imageSrc={selectedMachine && selectedMachine.attributes.image.src}
              value={selectedMachineTitle}
              onChange={onSelectedMachineTitle}
            >
              {machines.length > 0 ? (
                machines
                  .map(machine => v.translate(machine.attributes.title))
                  .map((title, index) => <option key={index}>{title}</option>)
              ) : (
                <option>{loadingLabel}</option>
              )}
            </SelectInput>
            <ComponentSpacing pt="sm" pb="sm" pl="sm" pr="sm">
              <Flexbox centered>
                <BodyFontType noSpace>
                  {v.translate('calculator.start.sectionIntro.versus')}
                </BodyFontType>
              </Flexbox>
            </ComponentSpacing>
            <SelectInput
              imgClass={selectedCompareOption.className}
              imageSrc={selectedCompareOption.imgSrc}
              value={selectedCompareOption.title}
              onChange={onSelectedCompareOption}
            >
              {compareOptions.map(({ title }, index) => (
                <option key={index}>{title}</option>
              ))}
            </SelectInput>
          </Flexbox>
          {selectedMachine && (
            <>
              <ComponentSpacing pt="3xl" />
              {selectedCompareOption.title === compareOptions[0].title ? (
                <PaybackCalculatorConvenience />
              ) : (
                <PaybackCalculatorHandcut />
              )}
            </>
          )}
          <ComponentSpacing pt="3xl" />
          <Flexbox centered onClick={machineTeaserTrack}>
            <SectionTile
              type="secondary"
              img={selectedMachine && selectedMachine.attributes.image.src}
              name={selectedMachineTitle}
              desc={selectedMachineShortDescription}
              link={selectedMachine && `/machines/${selectedMachine.id}`}
            />
          </Flexbox>
        </ComponentSpacing>
      </HierarchialTransitionContainer>
    </div>
  )
}
