import React from 'react'
import classNames from 'classnames'
import { ProductListSmallFontType } from './Typo'
import check from '../../assets/icons/check-white.svg'
import Icon from './Icon'

function Radio({ label, checked, fullWidth, disabled = false, onClick }) {
  return (
    <div
      onClick={() => {
        if (!disabled) onClick()
      }}
      className={classNames('et-radio -with-text', {
        '-disabled': disabled,
        '-checked': checked,
        '-full-width': fullWidth,
      })}
    >
      <ProductListSmallFontType type="span">{label}</ProductListSmallFontType>
      {checked && <img src={check} />}
    </div>
  )
}

export default Radio
