import React, { useContext } from 'react'
import {
  HeaderBackToLabelFontType,
  BodyExtraBoldFontType
} from '../../../UI/Typo'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import { ReactComponent as CloseImg } from '../../../../assets/icons/close-calc.svg'
import Flexbox from '../../../containers/Flexbox'
import { HorizontalSeparator } from '../../../UI/separator/horizontal'
import { PaybackCalculatorResultElement } from '../../UI'
import { formatKg, formatCurrency } from '../../../../helpers/formatter'
import { withMediumSpace } from '../../../../helpers/spacing'
import { CalculatorContext } from '../../../../providers/calculator'
import { Cost } from '../../UI/cost'
import v from '../../../../helpers/vocabulary'

export function PaybackCalculatorConvenienceConfigureConvenience({ onClose }) {
  const {
    selectedMachine,
    amountPerCharge,
    setAmountPerCharge,
    convenienceCostsPerKg,
    setConvenienceCostsPerKg,
    convenienceTotalProcessingCosts
  } = useContext(CalculatorContext)

  const costs = [
    {
      name: v.translate('calculator.savings.tabMaterial.batchQuantity'),
      value: amountPerCharge,
      renderValue: formatKg,
      onChange: setAmountPerCharge,
      min: selectedMachine.attributes.roi_data.amount_per_charge.range_min,
      max: selectedMachine.attributes.roi_data.amount_per_charge.range_max,
      step: selectedMachine.attributes.roi_data.amount_per_charge.steps
    },
    {
      name: v.translate('calculator.convenience.costPerKg'),
      value: convenienceCostsPerKg,
      renderValue: formatCurrency,
      onChange: setConvenienceCostsPerKg,
      min:
        selectedMachine.attributes.roi_data.convenience_costs_per_kg.range_min,
      max:
        selectedMachine.attributes.roi_data.convenience_costs_per_kg.range_max,
      step: selectedMachine.attributes.roi_data.convenience_costs_per_kg.steps
    }
  ]

  return (
    <>
      <ComponentSpacing
        pt="sm"
        pb="sm"
        pl="md"
        pr="md"
        className="vivid-blue-background et-payback-modal-header-start"
      >
        <div className="et-payback-calculator-modal-header-title">
          <HeaderBackToLabelFontType noSpace>
            {v.translate('calculator.convenience.title')}
          </HeaderBackToLabelFontType>
          <CloseImg
            className="et-payback-calculator-modal-header-title-container-close-button"
            onClick={onClose}
          />
        </div>
      </ComponentSpacing>
      <ComponentSpacing
        pt="xs"
        pl="lg"
        pr="lg"
        pb="sm"
        className="vivid-blue-background et-payback-modal-header-middle"
        id="et-configure-convinence-2"
      >
        <Flexbox spaceBetween className="basic-layout">
          <BodyExtraBoldFontType noSpace>
            {v.translate('calculator.convenience.subtitle')}
          </BodyExtraBoldFontType>
          <BodyExtraBoldFontType noSpace>
            {formatCurrency(convenienceTotalProcessingCosts)}
          </BodyExtraBoldFontType>
        </Flexbox>
      </ComponentSpacing>
      <ComponentSpacing pt="3xl" pb="3xl" pl="lg" pr="lg" className="basic-layout et-payback-modal-body-small-margin">
        {costs.map(Cost).reduce(withMediumSpace)}
        <ComponentSpacing pt="xl" pb="xs">
          <HorizontalSeparator />
        </ComponentSpacing>
        <PaybackCalculatorResultElement
          {...{
            name: v.translate(
              'calculator.convenience.summary.totalCostProcessing',
              { batchQuantity: amountPerCharge }
            ),
            value: convenienceTotalProcessingCosts,
            renderValue: formatCurrency
          }}
        />
      </ComponentSpacing>
    </>
  )
}
