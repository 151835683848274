import React from 'react'
import v from '../../../../helpers/vocabulary'
import Textlink from '../../Textlink'
import { CSSTransition } from 'react-transition-group'

export default function AddToCartLink({
  id,
  imageSrc,
  title,
  desc,
  addToBasket,
  additionalInfo,
}) {
  const [addToBasketAnimation, setAddToBasketAnimation] = React.useState(false)
  const basketItem = {
    id,
    title: v.translate(title),
    desc: desc,
    itemQuantity: 1,
    imageSrc,
    isAccessorie: additionalInfo.isAccessorie,
    titleId: additionalInfo.titleId,
  }

  const handleAddToBasket = () => {
    addToBasket(basketItem)
    setAddToBasketAnimation(true)
  }
  return (
    <>
      <CSSTransition
        in={addToBasketAnimation}
        onEntered={() => {
          setAddToBasketAnimation(false)
        }}
        timeout={300}
        classNames="stretch"
      >
        <Textlink
          onClick={handleAddToBasket}
          label={v.translate('basket.add.title')}
          className="-no-space"
        />
      </CSSTransition>
    </>
  )
}
