import React, { useRef, useState, useEffect } from 'react'
import { BodyLargeExtraBoldFontType, BodyFontType } from './Typo'
import Box from './../containers/Box'
import ComponentSpacing from '../containers/ComponentSpacing'
import Flexbox from '../containers/Flexbox'
import Button from './Button'
import v from '../../helpers/vocabulary'
import animationHelper from '../../helpers/animation'

export default function ConfirmDialog({
  opened = false,
  onConfirm,
  onCancel,
  title,
  onClose,
  content,
  confirmTitle,
  cancelTitle,
  ...rest
}) {
  const elementRef = useRef(null)
  const [hide, setHide] = useState(true)

  useEffect(() => {
    if (elementRef.current) {
      if (opened) {
        setHide(false)
        animationHelper.animateCSS(elementRef, 'fadeIn', null)
      } else {
        animationHelper.animateCSS(elementRef, 'fadeOut', () => setHide(true))
      }
    }
  }, [opened])
  const handleCancle = () => {
    if (onClose) onClose()
    if (onCancel) onCancel()
  }
  const handleConfirm = () => {
    if (onClose) onClose()
    if (onConfirm) onConfirm()
  }
  return (
    <div
      ref={elementRef}
      className="fast"
      style={{ zIndex: 10, position: 'relative' }}
    >
      {!hide ? (
        <>
          <div className="et-dialog-background"></div>
          <div className="et-dialog-confirm" {...rest}>
            <Box p="md" className="--content">
              <ComponentSpacing mb="sm">
                <BodyLargeExtraBoldFontType>{title}</BodyLargeExtraBoldFontType>
              </ComponentSpacing>
              <ComponentSpacing mb="xxl">
                <BodyFontType>{content}</BodyFontType>
              </ComponentSpacing>
              <Flexbox flexEnd>
                <ComponentSpacing mr="xs">
                  <Button small onClick={handleCancle} tertiary>
                    {cancelTitle ?? v.translate('general.actions.cancel')}
                  </Button>
                </ComponentSpacing>
                <ComponentSpacing ml="xs">
                  <Button small onClick={handleConfirm}>
                    {confirmTitle ?? v.translate('general.actions.ok')}
                  </Button>
                </ComponentSpacing>
              </Flexbox>
            </Box>
          </div>
        </>
      ) : null}
    </div>
  )
}
