import React from 'react'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import {
  H2HeadlineFontType,
  BodyFontType,
  BodyExtraBoldFontType
} from '../../Typo'
import v from '../../../../helpers/vocabulary'
import Flexbox from '../../../containers/Flexbox'

export default function TableSpecs(machine) {
  const ts = machine.machine.attributes.technical_specs
  return (
    <ComponentSpacing pr="md" pt="xs" pl="md" pb="xs" className="basic-layout">
      <ComponentSpacing pb="sm">
        <H2HeadlineFontType noSpace>
          {v.translate('products.detail.sectionTechnicalData.title')}
        </H2HeadlineFontType>
      </ComponentSpacing>

      <div className="et-table-spec">
        <Flexbox className="-container">
          <Flexbox column alignItemsCenter className="-column">
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate('products.detail.sectionTechnicalData.voltage')}
                </BodyFontType>
              </span>
              <span className="value">
                <BodyExtraBoldFontType> {ts.voltage} V</BodyExtraBoldFontType>
              </span>
            </div>
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate(
                    'products.detail.sectionTechnicalData.frequency'
                  )}
                </BodyFontType>
              </span>
              <span className="value">
                <BodyExtraBoldFontType>{ts.frequency} Hz</BodyExtraBoldFontType>
              </span>
            </div>
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate('products.detail.sectionTechnicalData.motor')}
                </BodyFontType>{' '}
              </span>
              <span className="value">
                <BodyExtraBoldFontType>
                  {ts.engine_power} kW
                </BodyExtraBoldFontType>
              </span>
            </div>
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate('products.detail.sectionTechnicalData.weight')}
                </BodyFontType>{' '}
              </span>
              <span className="value">
                <BodyExtraBoldFontType>{ts.weight} Kg</BodyExtraBoldFontType>
              </span>
            </div>
          </Flexbox>
          <Flexbox alignItemsCenter className="-column">
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate(
                    'products.detail.sectionTechnicalData.discDiameter'
                  )}
                </BodyFontType>
              </span>
              <span className="value">
                <BodyExtraBoldFontType>
                  {ts.blade_diameter} mm
                </BodyExtraBoldFontType>{' '}
              </span>
            </div>
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate(
                    'products.detail.sectionTechnicalData.protectionClass'
                  )}
                </BodyFontType>
              </span>
              <span className="value">
                <BodyExtraBoldFontType>{ts.fuse_type}</BodyExtraBoldFontType>
              </span>
            </div>
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate(
                    'products.detail.sectionTechnicalData.dimensions'
                  )}
                </BodyFontType>
              </span>
              <span className="value">
                <BodyExtraBoldFontType>
                  {`${ts.dimension_width} x ${ts.dimension_height} x ${ts.dimension_depth} mm`}
                </BodyExtraBoldFontType>
              </span>
            </div>
            <div className="item">
              <span className="key">
                <BodyFontType>
                  {v.translate(
                    'products.detail.sectionTechnicalData.certifications'
                  )}
                </BodyFontType>
              </span>
              <span className="value">
                <BodyExtraBoldFontType>
                  {ts.certificates}{' '}
                </BodyExtraBoldFontType>
              </span>
            </div>
          </Flexbox>
        </Flexbox>
      </div>
    </ComponentSpacing>
  )
}
