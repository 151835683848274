import React, { useState } from 'react'
import { HeaderBackToLabelFontType } from '../../../UI/Typo'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import { ReactComponent as CloseImg } from '../../../../assets/icons/close-calc.svg'
import { TabPicker } from '../../../tabPicker'
import { ReactComponent as MaterialTabIcon } from '../../../../assets/icons/vegetables.svg'
import { ReactComponent as ArbeitTabIcon } from '../../../../assets/icons/labour.svg'
import { ReactComponent as TotalTabIcon } from '../../../../assets/icons/roi.svg'
import { PaybackCalculatorMaterialTab } from '../../common/tabs/material'
import { PaybackCalculatorConfigureHandcutArbeitTab } from './arbeit'
import { PaybackCalculatorConfigureHandcutTotalTab } from './total'
import v from '../../../../helpers/vocabulary'

export function PaybackCalculatorHandcutConfigureHandcut({ onClose }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  var tabs = [
    {
      name: v.translate('calculator.savings.tabMaterial.tabTitle'),
      Icon: MaterialTabIcon,
      component: PaybackCalculatorMaterialTab
    },
    {
      name: v.translate('calculator.savings.tabTime.tabTitle'),
      Icon: ArbeitTabIcon,
      component: PaybackCalculatorConfigureHandcutArbeitTab
    },
    {
      name: v.translate('calculator.savings.tabProcessing.tabTitle'),
      Icon: TotalTabIcon,
      component: PaybackCalculatorConfigureHandcutTotalTab
    }
  ].map((tab, index) => ({
    ...tab,
    onClick: () => setSelectedTabIndex(index),
    selected: index === selectedTabIndex
  }))

  const SelectedTab = tabs[selectedTabIndex].component

  return (
    <>
      <ComponentSpacing
        pt="sm"
        pb="sm"
        pl="md"
        pr="md"
        className="vivid-blue-background et-payback-modal-header-start"
      >
        <div className="et-payback-calculator-modal-header-title">
          <HeaderBackToLabelFontType noSpace>
            {v.translate('calculator.savings.title', {
              selectedAnlikerMachineOrHandcut: v.translate(
                'calculator.start.sectionSavings.processingCostHandcut'
              )
            })}
          </HeaderBackToLabelFontType>
          <CloseImg
            className="et-payback-calculator-modal-header-title-container-close-button"
            onClick={onClose}
          />
        </div>
      </ComponentSpacing>
      <ComponentSpacing
        pt="xs"
        pl="sm"
        pr="sm"
        className="vivid-blue-background et-payback-modal-header-middle"
      >
        <TabPicker {...{ tabs, selectedTabIndex }} />
      </ComponentSpacing>
      <ComponentSpacing pt="md" className="vivid-blue-background  et-payback-modal-header-empty-space" />
      <SelectedTab />
    </>
  )
}

