import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputField from '../UI/InputField'
import v from '../../helpers/vocabulary'
import Button from '../UI/Button'
import ComponentSpacing from '../containers/ComponentSpacing'
import { BodyFontType } from './../UI/Typo'

const PasswordResetForm = ({ submitAction, value, resetErrors, errors }) => {
  return (
    <div className="et-reset-password-form">
      <Formik initialValues={{ email: value }} onSubmit={submitAction}>
        {(props) => {
          const {
            values,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          } = props

          const onChange = (event) => {
            handleChange(event)
            if (isSubmitting) {
              resetErrors()
              resetForm()
            }
          }

          return (
            <form onSubmit={handleSubmit}>
              <BodyFontType>{v.translate('forgotpassword.lead')}</BodyFontType>
              <ComponentSpacing mt="xl">
                <InputField
                  label={v.translate('forgotpassword.form.field.email')}
                  name="email"
                  type="text"
                  error={
                    errors &&
                    touched.email && {
                      messages: [errors],
                    }
                  }
                  value={values.email}
                  onChange={onChange}
                  onBlur={handleBlur}
                />
              </ComponentSpacing>

              <ComponentSpacing className="submit-btn" mt="lg" mb="xl">
                <Button type="submit" disabled={isSubmitting}>
                  {v.translate('forgotpassword.form.cta.sendPassword')}
                </Button>
              </ComponentSpacing>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PasswordResetForm
