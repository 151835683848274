import React from 'react'
import LoginForm from './Form'
import { BodyFontType } from '../UI/Typo'
import v, { translateParts } from '../../helpers/vocabulary'
import ComponentSpacing from './../containers/ComponentSpacing'
import logo from '../../assets/images/logos/brunner-anliker-blue.svg'
import Flexbox from '../containers/Flexbox'
import Headline from './../UI/Headline'
import Divider from '../UI/divider'
import Snackbar from '../UI/Snackbar'

const LoginLayout = ({
  loginSubmit,
  handleForgotPassClick,
  errors,
  isSubmitting,
  snackbarClosed,
  successMessage,
  registrationMailInfo,
  resetErrors,
  ...props
}) => {
  const [contactEmailFirstPart, contactEmailSecondPart] = translateParts(
    'login.registrationInfo',
    'registrationContactEmail'
  )

  const ContactEmail = () => (
    <ComponentSpacing mt="sm" mb="xl">
      <BodyFontType noSpace>
        {contactEmailFirstPart}
        <a
          className="et-login-form-contact-email"
          href={`mailto:${registrationMailInfo}`}
        >
          {registrationMailInfo}
        </a>
        {contactEmailSecondPart}
      </BodyFontType>
    </ComponentSpacing>
  )

  return (
    <>
      <div className="et-auth-layout">
        <Flexbox centered className="logo">
          <img src={logo} />
        </Flexbox>
        <Headline title={v.translate('login.title')} />
        <ComponentSpacing mt="lg">
          <LoginForm
          resetErrors={resetErrors}
            isSubmitting={isSubmitting}
            values={props.values}
            loginAction={loginSubmit}
            handleForgotPassClick={handleForgotPassClick}
            errors={errors}
            />
        </ComponentSpacing>
        <Divider />
        <ContactEmail />
        {successMessage && (
          <Snackbar
            onClose={snackbarClosed}
            duration={3000}
            message={v.translate(successMessage)}
          />
        )}
      </div>
    </>
  )
}

export default LoginLayout
