import React, { useState, useEffect } from 'react'
import api from '../services/authentication'
import { useHistory } from 'react-router-dom'
import vocabularyApi from '../services/vocabulary'
import metadataApi from '../services/metadata'
import productApi from '../services/products'

export const LoginContext = React.createContext()
const initialLoginData = { email: '', password: '' }
export const LoginProvider = props => {
  const [loginData, setLoginData] = useState(initialLoginData)
  const [loginError, setLoginError] = useState({
    email:null,
    password:null,
    form:null
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState()

  const history = useHistory()

  useEffect(() => {
    vocabularyApi.getVocabulary().then(() => setLoginData({ ...loginData }))
  }, [])

  const loginSubmit = async values => {
    setIsSubmitting(true)
    const result = await api.loginSubmit(values)
    const { data, errors } = result
    if (errors.form || errors.password ||errors.email) {
      setLoginError(errors)
      setLoginData(values)
    } else {
      await Promise.all([vocabularyApi.getVocabulary(), metadataApi.getMetadata()])
      setLoginData(initialLoginData)
      setLoginError(false)
      goToOnboarding()
      productApi.getBlades()
      productApi.getAccessories()
      productApi.getMachines()
    }
    setIsSubmitting(false)
  }

  const forgotPass = () => {
    history.push('/password-reset')
  }

  const goToOnboarding = () => {
    history.push('/onboarding')
  }

  const resetLoginErrors = ()=>{
    setLoginError({
      email:null,
      password:null,
      form:null
    })
  }
  return (
    <LoginContext.Provider
      value={{
        loginSubmit,
        loginData,
        setLoginData,
        loginError,
        forgotPass,
        isSubmitting,
        successMessage,
        snackbarClosed: goToOnboarding,
        resetLoginErrors
      }}
    >
      {props.children}
    </LoginContext.Provider>
  )
}
