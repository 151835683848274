const routing = {
  goBack: props => {
    localStorage.setItem("goBack", true);
    props.history.goBack()
  },
  goToPage: (props, url) => {
    props.history.push(url)
  },
  getLastElementFromURL: () => window.location.href.split('/').pop(),
  getSecondFromLastFromURL: () => {
    let parts = window.location.href.split('/')
    parts.pop()
    return parts.pop()
  }
}

export default routing
