import React, { useState } from 'react'
import api from '../services/authentication'

export const PasswordResetContext = React.createContext()

export const PasswordResetProvider = props => {
  const [email, setEmail] = useState('')
  const [submitError, setSubmitError] = useState(null)
  const [successMessage, setSuccessMessage] = useState()

  const passwordResetSubmit = async values => {
    const result = await api.passwordResetSubmit(values)
    const { data, errors } = result
    if (errors) {
      setSubmitError(errors)
      setEmail(values)
    } else if (data) {
      setSuccessMessage(data)
    }
  }

  return (
    <PasswordResetContext.Provider
      value={{
        passwordResetSubmit,
        email,
        setEmail,
        submitError,
        successMessage,
        setSubmitError,
        setSuccessMessage
      }}
    >
      {props.children}
    </PasswordResetContext.Provider>
  )
}
