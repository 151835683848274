import React from 'react'
import ComponentSpacing from '../../../containers/ComponentSpacing'
import { H4HeadlineFontType, ProductListSmallFontType } from '../../Typo'
import v from '../../../../helpers/vocabulary'
import bladeHelper from './../../../../helpers/blade'
import { Link } from 'react-router-dom'

export default function ProductTile({
  name,
  img,
  family_code,
  machineId,
  cutTypeId,
  bladeId
}) {
  const cutType = bladeHelper.getCutTypeById(cutTypeId)

  const saveScrollPosition = () => {
    localStorage.setItem("machineDetailsScrollValue", window.pageYOffset)

  }

  return (
    <div className="et-product-tile">
      <div className="img-wrapper">
        <Link to={`../blades/${machineId}/${bladeId}`} onClick={saveScrollPosition}>
          <img src={img} />
          <img src={cutType?.blade_image.src} alt={name} className="blade" />
        </Link>
      </div>
      <ComponentSpacing mt="sm">
        <ProductListSmallFontType noSpace centered>
          {`${v.translate(name)} (${family_code})`}
        </ProductListSmallFontType>
      </ComponentSpacing>
    </div>
  )
}
