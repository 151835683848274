import { useEffect, useState } from 'react'
import generateId from '../../helpers/idHelper'

function useAriaId(id) {
  const [ariaId, setAriaId] = useState(id || null)
  useEffect(() => {
    generateId()
    setAriaId(`aria-tag-${generateId()}`)
  }, [])
  return ariaId
}

export default useAriaId
