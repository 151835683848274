import { appLanguage, token, access_token } from './../constants/authentication'
import jwt_decode from 'jwt-decode'
import { metadataKey, userKey } from '../constants/localStorageKeys'

export default {
  getAppLang: () => {
    const defaultAppLangId = 'en'
    const userLocalesId = localStorage.getItem(appLanguage)
    return userLocalesId ? userLocalesId : defaultAppLangId
  },
  setAppLang: lang => {
    localStorage.setItem(appLanguage, lang)
  },
  /**
   * Base on app language from local storage
   * finds user global region.
   * If there is no applicalbe global region returns null.
   */
  getUserGlobalRegion: () => {
    let metadata = localStorage.getItem(metadataKey)
    if (metadata) {
      metadata = JSON.parse(metadata)
      const regions = metadata.data.attributes.regions
      let userCountry = localStorage.getItem(userKey)
      if (!userCountry) return null
      userCountry = JSON.parse(userCountry).attributes.country
      for (let globalRegion of Object.keys(regions)) {
        if (regions[globalRegion].includes(userCountry))
          return globalRegion
      }
    } else return null
  },
  getToken: () => {
    const tokenData = localStorage.getItem(token)
    return tokenData ? JSON.parse(tokenData)[access_token] : null
  },
  isTokenExpired: () => {
    const tokenData = localStorage.getItem(token)
    if (!tokenData) {
      return true
    }
    const expiration = jwt_decode(tokenData).exp
    return expiration < Date.now() / 1000
  }
}
