import React, { useEffect, useRef, useState } from 'react'
import { IconLabelLink } from './Icon'
import { ReactComponent as Send } from '../../assets/icons/send.svg'
import { ReactComponent as Help } from '../../assets/icons/help-outline.svg'
import { ReactComponent as Settings } from '../../assets/icons/settings.svg'
import { ReactComponent as Logout } from '../../assets/icons/logout.svg'
import v from '../../helpers/vocabulary'
import ComponentSpacing from '../containers/ComponentSpacing'
import Box from './../containers/Box'
import Divider from './divider'
import classNames from 'classnames'
import animationHelper from '../../helpers/animation'
import NotificationNumber from './NotificationNumber'

function HeaderOptionMenu({
  options,
  dividedOptions,
  show,
  className,
  ...props
}) {
  const elementRef = useRef(null)
  const [hide, setHide] = useState(true)
  let backgroundElement
  useEffect(() => {
    if (elementRef.current) {
      if (show) {
        setHide(false)
        animationHelper.animateCSS(elementRef, 'fadeIn', null)
      } else {
        animationHelper.animateCSS(elementRef, 'fadeOut', () => setHide(true))
      }
    }
  }, [show])

  useEffect(() => {
    if (!backgroundElement)
      backgroundElement = document.querySelector('#header-background')
    hide && !show
      ? backgroundElement?.classList.remove('--header-menu-opened')
      : backgroundElement?.classList.add('--header-menu-opened')
  }, [hide])

  return (
    <div className="faster et-header-option-menu-container" ref={elementRef}>
      {!hide && (
        <>
          <div className="arrow-up"></div>
          <Box
            className={classNames('et-header-option-menu', className)}
            pt="sm"
            pr="sm"
            pl="sm"
          >
            {options?.map((option, index) => (
              <ComponentSpacing
                className={classNames(
                  'option',
                  index + 1 === options.length && 'last'
                )}
                m="sm"
                key={index}
              >
                {option}
              </ComponentSpacing>
            ))}
            {dividedOptions && (
              <>
                <Divider />
                {dividedOptions.map((option, index) => (
                  <ComponentSpacing className="option" m="sm" key={index}>
                    {option}
                  </ComponentSpacing>
                ))}
              </>
            )}
          </Box>
        </>
      )}
    </div>
  )
}

export const UserOptionMenu = ({
  onSendClick,
  onHelpClick,
  onLanugageSettingsClick,
  onLogoutClick,
  userNotificationNumber,
  ...props
}) => {
  const options = [
    <IconLabelLink
      onClick={onSendClick}
      label={v.translate('dashboard.navigation.pendingSendouts')}
      iconElement={<Send />}
    >
      <NotificationNumber
        className="pending-notification-num"
        number={userNotificationNumber}
      />
    </IconLabelLink>,
    <IconLabelLink
      onClick={onHelpClick}
      label={v.translate('dashboard.navigation.help')}
      iconElement={<Help />}
    />,
    <IconLabelLink
      onClick={onLanugageSettingsClick}
      iconElement={<Settings />}
      label={v.translate('dashboard.navigation.settings')}
    />,
  ]
  const dividedOptions = [
    <IconLabelLink
      onClick={onLogoutClick}
      label={v.translate('dashboard.navigation.logout')}
      iconElement={<Logout />}
    />,
  ]
  return (
    <HeaderOptionMenu
      {...props}
      options={options}
      dividedOptions={dividedOptions}
    />
  )
}

export const BasketOptionMenu = ({ ...props }) => {
  return <HeaderOptionMenu {...props} />
}

export default HeaderOptionMenu
