import { vocabularyKey } from '../constants/localStorageKeys'
import ls from './localStorage'

const vocabulary = {
  translate: (propKey, replaceValues, userLocalesId = ls.getAppLang()) => {
    try {
      const data = JSON.parse(localStorage.getItem(vocabularyKey))
      let dictionary = {}
      Object.values(data.data).forEach(dictLocalized => {
        if (dictLocalized.id === userLocalesId) {
          dictionary = dictLocalized.attributes
        }
      })

      var result = dictionary[propKey]
      if (!result) return propKey

      if (replaceValues) {
        Object.entries(replaceValues).forEach(
          ([key, value]) => (result = result.replace(`{{${key}}}`, value))
        )
      }

      return result
    } catch (err) {
      console.error(`Translation for ${propKey} not found`)
      console.error(err)
      return propKey
    }
  }
}

export default vocabulary

export const translateInEnglish = (propKey, replaceValues) =>
  vocabulary.translate(propKey, replaceValues, 'en')

export const translateParts = (propKey, key) => {
  try {
    const data = JSON.parse(localStorage.getItem(vocabularyKey))
    const userLocalesId = ls.getAppLang()
    let dictionary = {}
    Object.values(data.data).forEach(dictLocalized => {
      if (dictLocalized.id === userLocalesId) {
        dictionary = dictLocalized.attributes
      }
    })

    var result = dictionary[propKey]
    if (!result) return propKey
    const splitted = result.split(`{{${key}}}`)
    return splitted
  } catch (err) {
    console.error(`Translation for ${propKey} not found`)
    console.error(err)
    return propKey
  }
}
