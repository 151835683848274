import React from 'react'
import Dashboard from './../dashboard/index'
import BladeSearchList from './List'
import BladeSearchSearchField from './SearchField'
import v from '../../helpers/vocabulary'
import ContentContainer from './../containers/ContentContainer'
import HierarchialTransitionContainer from '../animations/HieararchialTransition'
import SnackbarPage from '../UI/SnackbarPage'

export default function BladeSearchLayout({
  blades,
  machine,
  onDetailsClick,
  onAddItem,
  onGoBackClick,
  handleSearch,
  searchValue,
  onClearSearch,
  addedBladeLabel,
  onSnackbarClosed,
}) {
  return (
    <SnackbarPage
      snackbarProps={{
        duration: 3000,
        message:
          addedBladeLabel &&
          v.translate('snackbar.productAdded', {
            productName: addedBladeLabel,
          }),
        onClose: onSnackbarClosed,
      }}
    >
      <Dashboard 
      onGoBackClick={onGoBackClick}
      loggedIn={true}
      title={v.translate('discfinder.stepDiscSearch.title', {
        selectedMachine: v.translate(machine.attributes.title)
      })}
      />
      <HierarchialTransitionContainer>
        <BladeSearchSearchField
          handleSearch={handleSearch}
          placeholder={v.translate(
            'discfinder.stepDiscSearch.searchfield.hint'
          )}
          value={searchValue}
          onClear={onClearSearch}
        />
        <ContentContainer className="-blade-search basic-layout">
          <BladeSearchList
            onAddItem={onAddItem}
            onDetailsClick={onDetailsClick}
            blades={blades}
          />
        </ContentContainer>
      </HierarchialTransitionContainer>
    </SnackbarPage>
  )
}
