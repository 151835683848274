import { apiService } from './api'
import { userKey, currencyKey } from '../constants/localStorageKeys'
import { loginApi, passwordResetApi, logoutApi } from '../constants/apiRoutes'
import { appLanguage, token } from './../constants/authentication'

export default {
  async loginSubmit(data) {
    let result = {
      data: null,
      errors: {
        email: null,
        password: null,
        form: null
      }
    }

    await apiService
      .post(loginApi, data)
      .then(function(response) {
        localStorage.setItem(
          token,
          JSON.stringify(response.data.data.attributes)
        )
        const user = response.data.included[0]
        localStorage.setItem(userKey, JSON.stringify(user))
        const { attributes } = user
        localStorage.setItem(appLanguage, attributes.default_language)
        localStorage.setItem(currencyKey, attributes.currency)
        result.data = attributes
      })
      .catch(function(err) {
        const errData = err.response?.data?.errors

        for (let index in errData) {
          result.errors[[errData[index].meta?.key]] = errData[index].detail
        }
        if (result.errors.form === null) result.errors.form = errData[0].detail
      })
    return result
  },
  async passwordResetSubmit(email) {
    let result = {
      data: null,
      errors: null
    }

    await apiService
      .post(passwordResetApi, email)
      .then(function(response) {
        if (!(response.data.meta && response.data.meta.message)) {
          throw new Error('Missing meta message in response')
        }
        result.data = response.data.meta.message
      })
      .catch(function(err) {
        let errMessage = ''
        err.response.data.errors.forEach(error => {
          errMessage += error.detail + '\n'
        })
        result.errors = errMessage
      })
    return result
  },
  async logout() {
    let result = {
      errors: null
    }

    await apiService
      .post(logoutApi)
      .then(function(response) {
        localStorage.removeItem(appLanguage)
        localStorage.removeItem(token)
      })
      .catch(function(err) {
        result.errors = err
      })
    return result
  }
}
