import React, { useContext, useState, useEffect } from 'react'
import OfferLayout from './../../components/offer/Layout'
import routing from './../../helpers/routing'
import { BasketContext } from './../../providers/basket'
import { OfferContext } from './../../providers/offer'
import { OfferSuccess } from '../../components/offer/success'

export default function Offer({ ...props }) {
  const basketContext = useContext(BasketContext)
  const offerContext = useContext(OfferContext)
  const { offer, snackbarMessageLabel, errors, successfullySend } = offerContext

  const [comment, setComment] = useState(offerContext.offer.comment)

  const handleCommentChanged = (element) => {
    setComment(element.target.value)
  }

  const handleSendOffer = (values) => {
    offerContext.handleSendOffer({ ...values, comment })
  }

  const basketItems = basketContext.basketItems.filter(
    (bi) => bi.itemQuantity !== 0
  )

  const handleSnackbarClosed = () => {
    offerContext.setSnackbarMessageLabel(null)
  }

  const handleGoBack = () => routing.goBack(props)
  const handleResetSuccessState = () => offerContext.setSuccessfullySend(false)

  return successfullySend ? (
    <OfferSuccess
      resetSuccessState={handleResetSuccessState}
      onGoBackClick={handleGoBack}
      leadTitle={'basket.form.success.lead'}
    />
  ) : (
    <OfferLayout
      onGoBack={handleGoBack}
      sendOffer={handleSendOffer}
      formFields={offer}
      onSnackbarClose={handleSnackbarClosed}
      basketItems={basketItems}
      comment={comment}
      snackbarMessageLabel={snackbarMessageLabel}
      commentChanged={handleCommentChanged}
      errors={errors}
    />
  )
}
