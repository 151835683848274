import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import Home from './sites/home/Home'
import Machines from './sites/machines/Machines'
import MachineDetails from './sites/machines/details/MachineDetails'
import Blades from './sites/blades/Blades'
import BladeSearch from './sites/bladeSearch/BladeSearch'
import { BladeDetails } from './sites/bladeDetails/BladeDetails'
import { PaybackCalculator } from './components/paybackCalculator'
import { Basket } from './sites/basket/Basket'
import Offer from './sites/offer/Offer'
import OnBoarding from './sites/onBoarding/OnBoarding'
import About from './sites/about/About'
import Help from './sites/help/Help'
import Offline from './components/errors/Offline'
import PrivateRoute from './components/routes/Private'
import OfferPendings from './sites/offer/Pendings'
import Language from './sites/language/Language'
import { CalculatorProvider } from './providers/calculator'
import { LogPath } from './analytics/LogPath'
import vocabularyApi from './services/vocabulary'
import metadataApi from './services/metadata'
import productApi from './services/products'
import Error_404 from './components/errors/404error'

export default function PrivateApp() {

  useEffect(() => {
    vocabularyApi.getVocabulary()
    metadataApi.getMetadata()
    productApi.getBlades()
    productApi.getAccessories()
    productApi.getMachines()
  }, [])

  return (
      <Switch>
        <PrivateRoute
          exact
          path="/machines/:id/calculator"
          component={LogPath(() => (
            <CalculatorProvider>
              <PaybackCalculator />
            </CalculatorProvider>
          ))}
        />
        <PrivateRoute
          exact
          path="/blades/:machineId/:id"
          component={LogPath(BladeDetails)}
        />
        <PrivateRoute exact path="/basket" component={LogPath(Basket)} />
        <PrivateRoute exact path="/offer" component={LogPath(Offer)} />
        <PrivateRoute
          exact
          path="/blades-search/:machineId"
          component={LogPath(BladeSearch)}
        />
        <PrivateRoute exact path="/onboarding" component={LogPath(OnBoarding)} />

        <PrivateRoute exact path="/help" component={LogPath(Help)} />
        <PrivateRoute exact path="/offline" component={LogPath(Offline)} />
        <PrivateRoute
          exact
          path="/pending-sendouts"
          component={LogPath(OfferPendings)}
        />
        <PrivateRoute exact path="/language" component={LogPath(Language)} />
        <PrivateRoute
          exact
          path="/machines/:id"
          component={LogPath(MachineDetails)}
        />
        <PrivateRoute exact path="/" component={LogPath(Home)} />
        <PrivateRoute exact path="/machines" component={LogPath(Machines)} />
        <PrivateRoute exact path="/blades" component={LogPath(Blades)} />
        <PrivateRoute
          exact
          path="/roi"
          component={LogPath((props) => (
            <CalculatorProvider>
              <PaybackCalculator {...props} />
            </CalculatorProvider>
          ))}
        />
        <PrivateRoute exact path="/about" component={LogPath(About)} />
        <PrivateRoute component={LogPath(Error_404)} />
      </Switch>
  )}