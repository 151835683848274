import React from 'react'
import Flexbox from '../containers/Flexbox'
import ComponentSpacing from '../containers/ComponentSpacing'
import { H2HeadlineFontType } from '../UI/Typo'
import v from '../../helpers/vocabulary'
import Textarea from '../UI/Textarea'

export default function OfferSectionComments({ value, handleChange, errors }) {

  const errorForKey = key => {
    if (!errors) return
    const error = errors.find(({ source }) => {
      if(source) 
       return source.pointer.includes(key)
      }
    )

    if (!(error && error.detail)) return
    return { messages: [error.detail] }
  }

  return (
    <Flexbox column className="et-offer-section-comments">
      <ComponentSpacing mt="3xl" mb="xs">
        <H2HeadlineFontType>
          {v.translate('basket.form.sectionComments.title')}
        </H2HeadlineFontType>
      </ComponentSpacing>
      <Textarea
        labelClassName="--body"
        name="comment"
        label={v.translate('basket.form.sectionComments.field.comment')}
        value={value}
        onChange={handleChange}
        error={errorForKey('comment')}
      />
    </Flexbox>
  )
}
