const animationHelper = {
  animateCSS: (elementRef, animationName, callback) => {
    const node = elementRef.current
    if (!node) return

    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
      node.classList.remove('animated', animationName)
      node.removeEventListener('animationend', handleAnimationEnd)

      if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
  },
}
export default animationHelper
