import React, { createContext, useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import v from '../helpers/vocabulary'
import { ProductContext } from './products'

const loadingLabel = 'Loading...'
export const CalculatorContext = createContext(null)

export const CalculatorProvider = ({ children }) => {
  const { id } = useParams()
  const { machine, machines, getMachine, getMachinesFromCache } = useContext(
    ProductContext
  )
  const [selectedMachine, setSelectedMachine] = useState(machine)

  useEffect(() => {
    if (!machine) {
      getMachine(1 || id)
    }
  }, [])

  useEffect(() => {
    if (machines.length === 0) {
      getMachinesFromCache()
    }
  }, [])

  useEffect(() => {
    setSelectedMachine(machine)
  }, [machine])

  const selectedMachineTitle = selectedMachine
    ? v.translate(selectedMachine.attributes.title)
    : loadingLabel

  const selectedMachineShortDescription = selectedMachine
    ? v.translate(selectedMachine.attributes.short_description)
    : loadingLabel

  const [amountPerCharge, setAmountPerCharge] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setAmountPerCharge(
      parseFloat(selectedMachine.attributes.roi_data.amount_per_charge.default)
    )
  }, [selectedMachine])

  const [convenienceCostsPerKg, setConvenienceCostsPerKg] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setConvenienceCostsPerKg(
      parseFloat(
        selectedMachine.attributes.roi_data.convenience_costs_per_kg.default
      )
    )
  }, [selectedMachine])

  const [productWorkPreparation, setProductWorkPreparation] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setProductWorkPreparation(
      parseFloat(
        selectedMachine.attributes.roi_data.product_work_preparation.default
      )
    )
  }, [selectedMachine])

  const [productWorkFillup, setProductWorkFillup] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setProductWorkFillup(
      parseFloat(
        selectedMachine.attributes.roi_data.product_work_fillup.default
      )
    )
  }, [selectedMachine])

  const [productWorkTotal, setProductWorkTotal] = useState()

  useEffect(() => {
    setProductWorkTotal(
      (productWorkPreparation + productWorkFillup) * amountPerCharge
    )
  }, [productWorkPreparation, productWorkFillup, amountPerCharge])

  const [productWorkCleaning, setProductWorkCleaning] = useState()
  useEffect(() => {
    if (!selectedMachine) return
    setProductWorkCleaning(
      parseFloat(
        selectedMachine.attributes.roi_data.product_work_cleaning.default
      )
    )
  }, [selectedMachine])

  const [
    productWorkTotalWithCleaning,
    setProductWorkTotalWithCleaning
  ] = useState()

  useEffect(() => {
    setProductWorkTotalWithCleaning(productWorkTotal + productWorkCleaning)
  }, [productWorkTotal, productWorkCleaning])

  const [wagePerHour, setWagePerHour] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setWagePerHour(
      parseFloat(selectedMachine.attributes.roi_data.wage_per_hour.default)
    )
  }, [selectedMachine])

  const [productWageTotal, setProductWageTotal] = useState()

  useEffect(() => {
    setProductWageTotal((wagePerHour / 60) * productWorkTotalWithCleaning)
  }, [wagePerHour, productWorkTotalWithCleaning])

  const [productMaterialRawPrice, setProductMaterialRawPrice] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setProductMaterialRawPrice(
      parseFloat(
        selectedMachine.attributes.roi_data.product_material_raw_price.default
      )
    )
  }, [selectedMachine])

  const [materialPeelLoss, setMaterialPeelLoss] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setMaterialPeelLoss(
      parseFloat(
        selectedMachine.attributes.roi_data.product_material_peel_loss.default
      )
    )
  }, [selectedMachine])

  const [
    productMaterialNetPricePerKg,
    setProductMaterialNetPricePerKg
  ] = useState()

  useEffect(() => {
    setProductMaterialNetPricePerKg(
      productMaterialRawPrice * (1 + materialPeelLoss / 100)
    )
  }, [productMaterialRawPrice, materialPeelLoss])

  const [productMaterialTotalCosts, setProductMaterialTotalCosts] = useState()

  useEffect(() => {
    setProductMaterialTotalCosts(productMaterialNetPricePerKg * amountPerCharge)
  }, [productMaterialNetPricePerKg, amountPerCharge])

  const [productTotal, setProductTotal] = useState()

  useEffect(() => {
    setProductTotal(productWageTotal + productMaterialTotalCosts)
  }, [productWageTotal, productMaterialTotalCosts])

  const [
    convenienceTotalProcessingCosts,
    setConvenienceTotalProcessingCosts
  ] = useState()

  useEffect(() => {
    setConvenienceTotalProcessingCosts(amountPerCharge * convenienceCostsPerKg)
  }, [amountPerCharge, convenienceCostsPerKg])

  const [convenienceSavings, setConvenienceSavings] = useState()

  useEffect(() => {
    setConvenienceSavings(convenienceTotalProcessingCosts - productTotal)
  }, [convenienceTotalProcessingCosts, productTotal])

  // PAYBACK

  const [machinePrice, setMachinePrice] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setMachinePrice(
      parseFloat(selectedMachine.attributes.roi_data.machine_price.default)
    )
  }, [selectedMachine])

  const [chargePerDay, setChargePerDay] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setChargePerDay(
      parseFloat(selectedMachine.attributes.roi_data.charge_per_day.default)
    )
  }, [selectedMachine])

  const [calculatedYearlyTotalKg, setCalculatedYearlyTotalKg] = useState()

  useEffect(() => {
    setCalculatedYearlyTotalKg(amountPerCharge * chargePerDay * 220)
  }, [amountPerCharge, chargePerDay])

  const [
    convenienceAmortizationMachinePriceYears,
    setConvenienceAmortizationMachinePriceYears
  ] = useState()

  useEffect(() => {
    setConvenienceAmortizationMachinePriceYears(
      machinePrice /
        ((convenienceSavings / amountPerCharge) * calculatedYearlyTotalKg)
    )
  }, [
    machinePrice,
    convenienceSavings,
    amountPerCharge,
    calculatedYearlyTotalKg
  ])


  // HANDCUT
  const [handcutWorkPerKg, setHandcutWorkPerKg] = useState()

  useEffect(() => {
    if (!selectedMachine) return
    setHandcutWorkPerKg(
      parseFloat(
        selectedMachine.attributes.roi_data.handcut_work_per_kg.default
      )
    )
  }, [selectedMachine])

  const [handcutWorkTotal, setHandcutWorkTotal] = useState()

  useEffect(() => {
    setHandcutWorkTotal(handcutWorkPerKg * amountPerCharge)
  }, [handcutWorkPerKg, amountPerCharge])

  const [handcutWageTotal, setHandcutWageTotal] = useState()

  useEffect(() => {
    setHandcutWageTotal((wagePerHour / 60) * handcutWorkTotal)
  }, [wagePerHour, handcutWorkTotal])

  const [handcutTotal, setHandcutTotal] = useState()

  useEffect(() => {
    setHandcutTotal(productMaterialTotalCosts + handcutWageTotal)
  }, [productMaterialTotalCosts, handcutWageTotal])

  const [handcutSavings, setHandcutSavings] = useState()

  useEffect(() => {
    setHandcutSavings(handcutTotal - productTotal)
  }, [handcutTotal, productTotal])

  const [
    handcutAmortizationMachinePriceYears,
    setHandcutAmortizationMachinePriceYears,
  ] = useState()

  useEffect(() => {
    setHandcutAmortizationMachinePriceYears(
      machinePrice /
        ((handcutSavings / amountPerCharge) * calculatedYearlyTotalKg)
    )
  }, [machinePrice, handcutSavings, amountPerCharge, calculatedYearlyTotalKg])

  return (
    <CalculatorContext.Provider
      value={{
        machines,
        selectedMachine,
        setSelectedMachine,
        selectedMachineTitle,
        selectedMachineShortDescription,
        loadingLabel,
        amountPerCharge,
        setAmountPerCharge,
        convenienceCostsPerKg,
        setConvenienceCostsPerKg,
        convenienceTotalProcessingCosts,
        productMaterialRawPrice,
        setProductMaterialRawPrice,
        materialPeelLoss,
        setMaterialPeelLoss,
        machinePrice,
        setMachinePrice,
        chargePerDay,
        setChargePerDay,
        calculatedYearlyTotalKg,
        convenienceAmortizationMachinePriceYears,
        productTotal,
        convenienceSavings,
        productMaterialNetPricePerKg,
        productMaterialTotalCosts,
        productWageTotal,
        productWorkPreparation,
        setProductWorkPreparation,
        productWorkFillup,
        setProductWorkFillup,
        productWorkTotal,
        productWorkCleaning,
        setProductWorkCleaning,
        productWorkTotalWithCleaning,
        wagePerHour,
        setWagePerHour,
        handcutAmortizationMachinePriceYears,
        handcutSavings,
        handcutTotal,
        handcutWorkPerKg,
        setHandcutWorkPerKg,
        handcutWorkTotal,
        handcutWageTotal
      }}
    >
      {children}
    </CalculatorContext.Provider>
  )
}
