import axios from 'axios'
import { token } from './../constants/authentication'
import lsh from '../helpers/localStorage'
import authenticationService from './authentication'
import { logoutApi } from '../constants/apiRoutes'

const baseUrl = APP_URL

export const apiService = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
})

apiService.interceptors.request.use((config) => {
  if (lsh.getToken()) {
    config.headers.common['Authorization'] = 'Bearer ' + lsh.getToken()
    if (lsh.isTokenExpired()) {
      // token expired -> logging out -> logging out is intercepted also -> token expired -> logging out
      if (!config.url.includes(logoutApi)) {
        authenticationService.logout()
      }
      window.history.pushState({}, 'Login', '/login')
    }
  }

  // add language param to query string
  if (!config.url.includes('?')) config.url += '?'
  config.url += `language=${lsh.getAppLang()}`

  return config
})

apiService.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error && error.response && error.response.status === '404')
      window.history.pushState(error, error.message, '/error_404')
    return Promise.reject(error)
  }
)

export default {
  async getDataService(apiUrl, localStorageKey) {
    if (navigator.onLine) {
      let response = await apiService.get(apiUrl)
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify(response.data)
      )
      return response.data
    } else {
      return JSON.parse(window.localStorage.getItem(localStorageKey))
    }
  },
}
