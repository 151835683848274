import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import authenticationApi from '../services/authentication'
import { basketHelper } from '../helpers/basket'
import { offerHelper } from './../helpers/offer'

export const DashboardContext = React.createContext()

export const DashboardProvider = (props) => {
  const [basketItemsNum, setBasketItemsNum] = useState(
    basketHelper.getTotalItems()
  )
  const [userNotificationNumber, setUserNotificationNumber] = useState(
    offerHelper.getPendings().length
  )
  const history = useHistory()

  const userMenuOptionClickHandlers = {
    onHelpClick: () => {
      history.push('/help')
    },
    onLanugageSettingsClick: () => {
      history.push('/language')
    },
    onSendClick: () => {
      history.push('/pending-sendouts')
    },
    onLogoutClick: async () => {
      await authenticationApi.logout()
      history.push('/login')
    },
  }

  const onBasketClick = () => {
    history.push('/basket')
  }

  return (
    <DashboardContext.Provider
      value={{
        userMenuOptionClickHandlers,
        onBasketClick,
        setBasketItemsNum,
        basketItemsNum,
        userNotificationNumber,
        setUserNotificationNumber,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  )
}
