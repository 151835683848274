import React from 'react'
import classNames from 'classnames'

export function Title({ type = 'h1', className, ...props }) {
  return (
    <Typo type={type} className={classNames('--title', className)} {...props} />
  )
}

export function TextSmall({ className, ...props }) {
  return <Typo className={classNames('--text-small', className)} {...props} />
}

export function FontType1({ className, ...props }) {
  return <Typo className={classNames('--font-type-1', className)} {...props} />
}

export function FontType2({ className, ...props }) {
  return <Typo className={classNames('--font-type-2', className)} {...props} />
}

export function FontType3({ className, ...props }) {
  return <Typo className={classNames('--font-type-3', className)} {...props} />
}

export function FontType4({ className, ...props }) {
  return <Typo className={classNames('--font-type-4', className)} {...props} />
}

export function FontType5({ className, ...props }) {
  return <Typo className={classNames('--font-type-5', className)} {...props} />
}

export function FontType6({ className, ...props }) {
  return <Typo className={classNames('--font-type-6', className)} {...props} />
}

export function FontType7({ className, ...props }) {
  return <Typo className={classNames('--font-type-7', className)} {...props} />
}

export function FontType8({ className, ...props }) {
  return <Typo className={classNames('--font-type-8', className)} {...props} />
}

export function FontType9({ className, ...props }) {
  return <Typo className={classNames('--font-type-9', className)} {...props} />
}

export function FontType10({ className, ...props }) {
  return <Typo className={classNames('--font-type-10', className)} {...props} />
}

export function FontType11({ className, ...props }) {
  return <Typo className={classNames('--font-type-11', className)} {...props} />
}

export function HeadlineFontType1({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-1', className)}
      {...props}
    />
  )
}

export function HeadlineFontType2({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-2', className)}
      {...props}
    />
  )
}

export function HeadlineFontType3({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-3', className)}
      {...props}
    />
  )
}

export function HeadlineFontType4({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-4', className)}
      {...props}
    />
  )
}

export function HeadlineFontType5({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-5', className)}
      {...props}
    />
  )
}

export function HeadlineFontType6({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-6', className)}
      {...props}
    />
  )
}

export function HeadlineFontType7({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-7', className)}
      {...props}
    />
  )
}

export function HeadlineFontType8({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-8', className)}
      {...props}
    />
  )
}

export function HeadlineFontType9({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-9', className)}
      {...props}
    />
  )
}

export function HeadlineFontType10({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-10', className)}
      {...props}
    />
  )
}

export function HeadlineFontType11({ className, ...props }) {
  return (
    <Typo
      className={classNames('--headline-font-type-11', className)}
      {...props}
    />
  )
}

/* headings */
export function D1DisplayFontType({ className, ...props }) {
  return <Typo className={classNames('--d1-display', className)} {...props} />
}

export function H1HeadlineFontType({ className, ...props }) {
  return <Typo className={classNames('--h1-headline', className)} {...props} />
}

export function H2HeadlineFontType({ className, ...props }) {
  return <Typo className={classNames('--h2-headline', className)} {...props} />
}

export function H3HeadlineFontType({ className, ...props }) {
  return <Typo className={classNames('--h3-headline', className)} {...props} />
}

export function H4HeadlineFontType({ className, ...props }) {
  return <Typo className={classNames('--h4-headline', className)} {...props} />
}
export function HeaderPageTitleFontType({ className, ...props }) {
  return (
    <Typo className={classNames('--header-page-title', className)} {...props} />
  )
}
export function HeaderBackToLabelFontType({ className, ...props }) {
  return (
    <Typo
      className={classNames('--header-back-to-label', className)}
      {...props}
    />
  )
}

/* headings end */

/* textlink types */

export function ButtonSmallFontType({ className, ...props }) {
  return <Typo className={classNames('--button-small', className)} {...props} />
}

export function TextLinkFontType({ className, ...props }) {
  return <Typo className={classNames('--textlink', className)} {...props} />
}

export function TextLinkSmallFontType({ className, ...props }) {
  return (
    <Typo className={classNames('--textlink-small', className)} {...props} />
  )
}
/* textlink types end */

/* product font types */
export function ProductTilesFontType({ className, ...props }) {
  return (
    <Typo className={classNames('--product-tiles', className)} {...props} />
  )
}

export function ProductListLargeFontType({ className, ...props }) {
  return (
    <Typo
      className={classNames('--product-list-large', className)}
      {...props}
    />
  )
}

export function ProductListSmallFontType({ className, ...props }) {
  return (
    <Typo
      className={classNames('--product-list-small', className)}
      {...props}
    />
  )
}

export function ProductGridSmallFontType({ className, ...props }) {
  return (
    <Typo
      className={classNames('--product-grid-small', className)}
      {...props}
    />
  )
}

export function ProductDescriptionFontType({ className, ...props }) {
  return (
    <Typo
      className={classNames('--product-description', className)}
      {...props}
    />
  )
}
/* product font types end */

/* body font types */

export function BodyLargeFontType({ className, ...props }) {
  return <Typo className={classNames('--body-large', className)} {...props} />
}

export function BodyLargeExtraBoldFontType({ className, ...props }) {
  return (
    <Typo
      className={classNames('--body-large-extra-bold', className)}
      {...props}
    />
  )
}

export function BodyFontType({ className, ...props }) {
  return <Typo className={classNames('--body', className)} {...props} />
}

export function BodyExtraBoldFontType({ className, ...props }) {
  return (
    <Typo className={classNames('--body-extra-bold', className)} {...props} />
  )
}

export function BodySmallFontType({ className, ...props }) {
  return <Typo className={classNames('--body-small', className)} {...props} />
}

export function BodySmallExtraBoldFontType({ className, ...props }) {
  return (
    <Typo
      className={classNames('--body-small-extra-bold', className)}
      {...props}
    />
  )
}
/* end body */
/* usp */

export function ProductUSPFontType({ className, ...props }) {
  return <Typo className={classNames('--product-usp', className)} {...props} />
}
/* end usp */

function Typo({
  type = 'p',
  children,
  className,
  noSpace = false,
  ellipsis = false,
  black = false,
  grey = false,
  red = false,
  redError = false,
  blue = false,
  white = false,
  darkBlue = false,
  green = false,
  mediumBlue = false,
  lightBlue = false,
  centered = false,
  tar = false,
  noWrap = false,
  preLine = false,
  underlineOnHover = false,
  withoutUnderline = false,
  ultraDarkGrey = false,
  ...props
}) {
  return React.createElement(
    type,
    {
      className: classNames(
        'et-typo',
        { '-no-space': noSpace },
        { '-ellipsis': ellipsis },
        { '-black': black },
        { '-grey': grey },
        { '-red': red },
        { '-red-error': redError },
        { '-blue': blue },
        { '-white': white },
        { '-dark-blue': darkBlue },
        { '-light-blue': lightBlue },
        { '-green': green },
        { '-medium-blue': mediumBlue },
        { '-centered': centered },
        { '-tar': tar },
        { '-no-wrap': noWrap },
        { '-pre-line': preLine },
        { '-underline-on-hover': underlineOnHover },
        { '-without-underline': withoutUnderline },
        { '-ultra-dark-grey': ultraDarkGrey },
        { '-cursor-pointer': type === 'a' },
        className
      ),
      ...props,
    },
    children
  )
}

export default Typo
