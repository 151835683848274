import React from 'react'
import ComponentSpacing from '../../containers/ComponentSpacing'
import { H2HeadlineFontType } from '../Typo'
import v from '../../../helpers/vocabulary'
import { accessoriesKey } from '../../../constants/localStorageKeys'
import Accessorie from './details/Accessorie'

export default function Accessories({ machine, addToBasketAccessorie }) {
  const accessories = machine?.attributes.accessories
  const allAccessories = JSON.parse(localStorage.getItem(accessoriesKey)).data

  return (
    <ComponentSpacing pl="md" pr="md" className="basic-layout">
      {accessories &&
        accessories.map((accessorieId, index) => (
          <Accessorie
            accessoire={allAccessories[accessorieId - 1]}
            addToBasketAccessorie={addToBasketAccessorie}
            key={index}
          />
        ))}
    </ComponentSpacing>
  )
}
