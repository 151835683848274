import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from './../../providers/products'
import routing from './../../helpers/routing'
import BladeSearchLayout from './../../components/bladeSearch/Layout'
import { BasketContext } from './../../providers/basket'
import v from '../../helpers/vocabulary'
import machineHelper from '../../helpers/machine'
import ReactGA from 'react-ga'
import { DiscSearchCategory } from '../../analytics/categories'

export default function BladeSearch({ ...props }) {
  const productContext = useContext(ProductContext)
  const basketContext = useContext(BasketContext)
  const [addedBlade, setAddedBlade] = useState(null)

  const [searchedBlades, setSearchedBlades] = useState(productContext.blades)

  const [searchValue, setSearchValue] = useState('')
  useEffect(() => {
    const machineId = props.location.pathname.split('/').pop()
    productContext.getBladesByMachineId(machineId)

    
    // retrieve last search
    var bladesSearchValue = localStorage.getItem("bladeSearchValue");
    if(bladesSearchValue) {
      setSearchValue(bladesSearchValue)
    }

    // retrieve last scroll position
    var scrollPosition = localStorage.getItem("scrollPositionValue");
    if(scrollPosition) {
      window.scrollTo(0, scrollPosition);
    }
  }, [])

  const machine = machineHelper.getMachineById(props.location.pathname.split('/').pop())


  useEffect(() => {
    setSearchedBlades(productContext.blades)
    var bladesSearchValue = localStorage.getItem("bladeSearchValue");
    if(bladesSearchValue)
      handleSearch(bladesSearchValue)

  }, [productContext.blades])

  const handleSearch = (inputValue) => {
    setSearchValue(inputValue)
    localStorage.setItem("bladeSearchValue", inputValue);
    const matchedBlades = productContext.blades.filter(({ attributes }) => {
      const { title, blade_code } = attributes
      const bladeName = `${v.translate(title)} ${blade_code}`

      return bladeName.toLowerCase().includes(inputValue.toLowerCase())
    })
    setSearchedBlades(matchedBlades)
  }

  const handleOnDetailsClick = (bladeId) => {
    ReactGA.event({
      category: DiscSearchCategory,
      action: 'Detail vs Add-to-Basket',
      label: 'Disc Detail button'
    })
    localStorage.setItem("scrollPositionValue", window.pageYOffset)

    const machineId = props.location.pathname.split('/').pop()
    routing.goToPage(props, '/blades/' + machineId + '/' + bladeId)
  }
  const handleAddBlade = (blade) => {
    ReactGA.event({
      category: DiscSearchCategory,
      action: 'Detail vs Add-to-Basket',
      label: 'Disc Add-to-Basket button'
    })
    basketContext.handleAddBlade(blade)
    setAddedBlade(blade.title)
  }
  return (
    <BladeSearchLayout
      machine={machine}
      onAddItem={handleAddBlade}
      onGoBackClick={() => routing.goBack(props)}
      handleSearch={handleSearch}
      onDetailsClick={handleOnDetailsClick}
      basketNotificationNum={basketContext.basketItemsNum}
      blades={searchedBlades}
      searchValue={searchValue}
      onClearSearch={() => handleSearch('')}
      addedBladeLabel={addedBlade}
      onSnackbarClosed={() => setAddedBlade(addedBlade)}
    />
  )
}
